import React, { useState, useEffect, useRef } from 'react';
import { TextField, IconButton, InputAdornment, Box, Typography, Grid, Button, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import CityList from './components/5best/CityList';
import CityDataList from './components/5best/CityDataList';
import axios from 'axios';
import FooterComponent from './FooterComponent';
import { ENDPOINTS, getApiUrl, SEARCH_ENGINE_IMAGE_BASE_URL } from './config/apiConfig'; // Import API configuration

const CityPage = () => {
  const [cities, setCities] = useState([]);
  const [countryName, setCountryName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCities, setFilteredCities] = useState([]);
  const [cityDataAll, setCityDataAll] = useState([]);
  const [searchEngines, setSearchEngines] = useState([]);
  const [showSearchEngines, setShowSearchEngines] = useState(false);
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSticky, setIsSticky] = useState(false);

  const searchRef = useRef(null);
  const topCitiesRef = useRef(null);

  useEffect(() => {
    const fetchCities = async () => {
      try {
       // setIsLoading(true);
        const url = getApiUrl(ENDPOINTS.CITY, 'cid=1'); // Use the CITY endpoint
        const response = await axios.get(url);
        const data = response.data;

        setCountryName(data.country_name);
        setCities(data.topcities);
        setFilteredCities(data.citydataall);
        setCityDataAll(data.citydataall);

        const initializedEngines = data.searchengine.searchenginedata.map(engine => ({
          ...engine,
          selected: engine.selected === "true"
        }));
        setSearchEngines(initializedEngines);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCities();

    const handleScroll = () => {
      if (searchRef.current && topCitiesRef.current && !isSearchClicked) {
        const searchRect = searchRef.current.getBoundingClientRect();
        const topCitiesRect = topCitiesRef.current.getBoundingClientRect();
        
        if (topCitiesRect.bottom <= 0) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isSearchClicked]);

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredCities(cityDataAll);
      setShowSearchEngines(false);
    } else {
      const filtered = cityDataAll.filter(city =>
        city.city_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (city.related_keywords && city.related_keywords.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      setFilteredCities(filtered);
      setShowSearchEngines(filtered.length === 0);
	  
	    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    }
  }, [searchTerm, cityDataAll]);

		 const handleClear = () => {
		  setSearchTerm('');
		  setFilteredCities(cityDataAll);
		  setIsSearchClicked(false);
		  setIsSticky(false);
		  
		  // Scroll back to the top of the page
		  window.scrollTo({
			top: 0,
			behavior: 'smooth'
		  });
		};

  const handleSearch = () => {
    let searchUrls = '';
    searchEngines.forEach(engine => {
      if (engine.selected) {
        searchUrls += `${engine.searchengineurl}${searchTerm}~`;
      }
    });
    if (searchUrls) {
      const finalUrls = searchUrls.slice(0, -1);
      window.open(finalUrls, '_blank');
    }
  };

  const toggleEngineSelection = (clickedEngine) => {
    setSearchEngines(
      searchEngines.map(engine =>
        engine.searchenginename === clickedEngine
          ? { ...engine, selected: !engine.selected }
          : engine
      )
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch();
    }
  };

  return (
    <Box 
      sx={{ 
        transition: 'margin-top 0.3s ease', 
        padding: 3,
        ...(isSearchClicked && searchTerm && {
          marginTop: '50px',
          position: 'sticky'
        })
      }} 
      className={`
        ${isSearchClicked && !searchTerm ? 'city-search-sticky' : ''}
        ${isSearchClicked && searchTerm ? 'city-input-sticky' : ''}
      `.trim()}
    >
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {!isSearchClicked && (
            <Box ref={topCitiesRef}>
              <Typography variant="h4" sx={{
                fontSize:'20px', marginBottom:'20px'}} component="div" textAlign="center" gutterBottom>
                Top cities in {countryName}
              </Typography>
              <CityList cities={cities} />
            </Box>
          )}

          <Box
            ref={searchRef}
            sx={{
              position: isSticky || isSearchClicked ? 'fixed' : 'relative',
              top: isSticky || isSearchClicked ? 0 : 'auto',
              left: isSticky || isSearchClicked ? 0 : 'auto',
              right: isSticky || isSearchClicked ? 0 : 'auto',
              zIndex: 1000,
              backgroundColor: 'white',
              padding: (isSticky || isSearchClicked) ? '10px 15px' : '0',
              transition: 'all 0.3s ease',
              boxShadow: (isSticky || isSearchClicked) ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none',
            }}
          >
            <TextField
              fullWidth
              placeholder="Search your city" 
              value={searchTerm} 
              onFocus={() => setIsSearchClicked(true)}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: '#000' }} />
                  </InputAdornment>
                ),
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClear}>
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                borderRadius: '12px',
                padding: '0px',
                color: '#333',
                margin:'0px',
                backdropFilter: 'blur(15px)',
                
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                  },
                  '& input': {
                    color: '#333',
                  }
                },
              }}
            />
          </Box>

          {filteredCities.length === 0 && searchTerm && (
            <Box textAlign="center" mt={4}>
              <Typography color="error" variant="h6">No cities to show</Typography>
              <Grid container justifyContent="center" spacing={2} mt={2}>
                {searchEngines.map((engine) => (
                  <Grid item key={engine.searchenginename}>
                    <IconButton
                      onClick={() => toggleEngineSelection(engine.searchenginename)}
                      sx={{
                        backgroundColor: engine.selected ? 'red' : 'gray',
                        borderRadius: '50%',
                        '&:hover': {
                          backgroundColor: engine.selected ? 'red' : 'gray',
                        },
                      }}
                    >
                      <img
                        src={`${SEARCH_ENGINE_IMAGE_BASE_URL}${engine.searchengineimage.replace('../', '')}`}
                        alt={engine.searchenginename}
                        style={{ width: '40px', height: '40px' }}
                      />
                    </IconButton>
                  </Grid>
                ))}
              </Grid>
              <Button variant="outlined" onClick={handleSearch} sx={{ marginTop: 2 }}>
                Search
              </Button>
            </Box>
          )}

          <CityDataList cityDataAll={filteredCities} />
        </>
      )}
	  <FooterComponent />
    </Box>
  );
};

export default CityPage;