import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Box, Grid, TextField, Avatar, Typography, InputAdornment, 
  CircularProgress, Button, IconButton, Paper
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { throttle } from 'lodash';
import Carousel from 'react-material-ui-carousel'; 
import FooterComponent from '../../FooterComponent';
import { 
  getApiUrl, IMAGE_BASE_URL, DEFAULT_IMAGE_BASE_URL, ENDPOINTS 
} from '../../config/apiConfigext';

import {
  // Banner Components
  CategoryPageContainer,
  carouselSettings,
  CategoryBannerContainer,
  CarouselContainer,
  CarouselImage,
  CarouselImageItem,
  
  // Header Components
  CategoryHeader,
  SearchTextField,
  
  // Navigation Components
  CategoryTabsContainer,
  CategoryTab,
  
  // Search Components
  SearchEngineContainer,
  SearchEngineBox,
  SearchEngineAvatar,
  SearchButtonContainer,
  
  // Content Components
  CategoryContent,
  LinkPaper,
  LinkAvatar,
  LinkTitle,
  
  // Loading and Error Components
  LoadingWrapper,
  ErrorWrapper,
  colors
} from '../../theme-styles';
import styled from 'styled-components';




const CategoryPage = () => {
  const { categoryId } = useParams();
  const [data, setData] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [activeCategory, setActiveCategory] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedEngines, setSelectedEngines] = useState([]);
  
  const categoryRefs = useRef({});
  const tabRefs = useRef({});
  const tabsContainerRef = useRef(null);
  const headerRef = useRef(null);
  const scrollTimeout = useRef(null);
  const isManualScrolling = useRef(false);
  const lastClickTime = useRef(0);
  
  
  
 const SearchBoxContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  padding: ${(props) => (props.isSticky ? '12px 16px 8px' : '24px 16px')};
  background-color: ${(props) => (props.isSticky ? 'rgba(255, 255, 255, 0.98)' : 'white')};
  box-shadow: ${(props) => (props.isSticky ? '0 2px 4px rgba(0, 0, 0, 0.1)' : 'none')};
  transition: all 0.3s ease-in-out;
  width: 100%;
`;

const SearchEngineButton = styled(Button)`
  margin: 4px;
  border-radius: 50%;
  background-color: ${(props) => (props.selected ? colors.accent2 : '#ccc')};
  color: white;
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;
  padding: 0;
  &:hover {
    background-color: ${(props) => (props.selected ? colors.accent2 : '#ccc')};
  }
`; 
  
const StyledCategoryTab = styled.div`
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 20px;
  cursor: pointer;
  white-space: nowrap;
  background-color: ${(props) => (props.active ? colors.categoryActive : colors.accent2Light)};
  color: ${(props) => (props.active ? colors.categoryActiveText : colors.commonbtntextcolor)};
  &:hover {
    background-color: ${(props) => (props.active ? colors.categoryActive : colors.accent2Light)};
  }
  transition: all 0.3s ease-in-out;
`;

const HighlightedPaper = styled(Paper)`
  margin-bottom: 16px;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
  background-color: ${(props) => (props.active ? colors.sectionActive : colors.linkSectionBackground)};
  color: ${(props) => (props.active ? colors.accent4 : '#000')};
  &:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
  }
`;



 const scrollTabsToCategory = useCallback((categoryName) => {
    const tabElement = tabRefs.current[categoryName];
    const container = tabsContainerRef.current;

    if (tabElement && container) {
      const containerRect = container.getBoundingClientRect();
      const tabRect = tabElement.getBoundingClientRect();

      const offset = tabRect.left - containerRect.left - (containerRect.width - tabRect.width) / 2;

      // Use immediate scroll when handling click events
      if (isManualScrolling.current) {
        container.scrollLeft += offset;
      } else {
        container.scrollTo({
          left: container.scrollLeft + offset,
          behavior: 'smooth',
        });
      }
    }
  }, []);


useEffect(() => {
  const observerOptions = {
    root: null,
    rootMargin: '-10% 0px', // Reduced margin to be less aggressive
    threshold: [0.5], // Using a single threshold to reduce sensitivity
  };

  const observer = new IntersectionObserver((entries) => {
    if (isManualScrolling.current || Date.now() - lastClickTime.current < 1000) {
      return;
    }

    // Find the first intersecting entry with the highest visibility ratio
    const mostVisible = entries.find(entry => entry.isIntersecting);

    if (mostVisible) {
      const categoryName = mostVisible.target.getAttribute('data-category');

      // Delay setting the active category to minimize quick layout shifts
      setTimeout(() => {
        setActiveCategory(categoryName);

        // Update tab scroll position without affecting page scroll
        const tabElement = tabRefs.current[categoryName];
        const tabContainer = tabsContainerRef.current;

        if (tabElement && tabContainer) {
          const containerRect = tabContainer.getBoundingClientRect();
          const tabRect = tabElement.getBoundingClientRect();
          const offset = tabRect.left - containerRect.left - (containerRect.width - tabRect.width) / 2;

          tabContainer.scrollTo({
            left: tabContainer.scrollLeft + offset,
            behavior: 'smooth'
          });
        }
      }, 200); // Adding a slight delay to smooth out rapid state changes
    }
  }, observerOptions);

  // Observe all the category sections
  Object.keys(categoryRefs.current).forEach((category) => {
    if (categoryRefs.current[category]) {
      observer.observe(categoryRefs.current[category]);
    }
  });

  return () => observer.disconnect();
}, []);


  // Remove or modify the scroll event listener
  useEffect(() => {
    const handleScroll = throttle(() => {
      if (isManualScrolling.current || Date.now() - lastClickTime.current < 1000) {
        return;
      }
      // Your existing scroll logic if needed
    }, 100);

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  
  useEffect(() => {
    fetch(getApiUrl(ENDPOINTS.CATEGORY_PAGE, `catid=${categoryId}`))
      .then(response => response.json())
      .then(jsonData => {
        if (jsonData) {
          const filteredLinkData = jsonData.linkdata.filter(category => category.links && category.links.length > 0);
          setData({...jsonData, linkdata: filteredLinkData});
          setFilteredItems(filteredLinkData);
          setSelectedEngines(
            jsonData.searchengine.searchenginedata
              .filter(engine => engine.selected === "true")
              .map(engine => engine.searchenginename)
          );
        } else {
          setData({ linkdata: [], searchengine: { searchenginedata: [] } });
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setData({ linkdata: [], searchengine: { searchenginedata: [] } });
        setLoading(false);
      });
  }, [categoryId]);

  const scrollToSection = useCallback((categoryName) => {
    if (categoryName === 'All') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }

    const section = categoryRefs.current[categoryName];
    if (section) {
      const headerHeight = headerRef.current ? headerRef.current.offsetHeight : 0;
      const sectionTop = section.getBoundingClientRect().top;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const targetScrollPosition = sectionTop + scrollTop - headerHeight - 20;

      window.scrollTo({
        top: targetScrollPosition,
        behavior: 'smooth',
      });
    }
  }, []);




  const filterItems = useCallback((query, category) => {
    if (!data) return;

    const searchInCategory = (items, searchQuery, categoryName) => {
      const lowerQuery = searchQuery.toLowerCase();
      return items.filter(item =>
        item.link_name.toLowerCase().includes(lowerQuery) ||
        categoryName.toLowerCase().includes(lowerQuery)
      );
    };

    let filtered;
    if (category === 'All') {
      if (query === '') {
        filtered = data.linkdata;
      } else {
        filtered = data.linkdata.map(subcat => ({
          ...subcat,
          links: searchInCategory(subcat.links || [], query, subcat.sub_category_name)
        })).filter(subcat => subcat.links.length > 0 || subcat.sub_category_name.toLowerCase().includes(query.toLowerCase()));
      }
    } else {
      filtered = data.linkdata
        .filter(subcat => subcat.sub_category_name === category)
        .map(subcat => ({
          ...subcat,
          links: query === '' ? subcat.links : searchInCategory(subcat.links || [], query, subcat.sub_category_name)
        }))
        .filter(subcat => subcat.links.length > 0);
    }
    setFilteredItems(filtered);
  }, [data]);

const handleCategoryChange = useCallback((newCategory) => {
    isManualScrolling.current = true;
    lastClickTime.current = Date.now();
    
    // Immediately update active category and scroll tabs
    setActiveCategory(newCategory);
    scrollTabsToCategory(newCategory);
    
    // Scroll to section
    scrollToSection(newCategory);

    // Reset manual scrolling flag after animation completes
    setTimeout(() => {
      isManualScrolling.current = false;
    }, 1000); // Adjust based on your scroll animation duration
  }, [scrollToSection, scrollTabsToCategory]);



const handleSearchChange = (event) => {
  const query = event.target.value.trim().toLowerCase();
  setSearchQuery(query);

  if (!data) return;

  if (query === '') {
    // Restore all categories and set "All" as active when query is cleared
    setFilteredItems([{ sub_category_name: 'All' }, ...data.linkdata]);
    setActiveCategory('All');
    return;
  }

  // Filter links based on the search query
  const filteredLinks = data.linkdata.filter((category) =>
    category.links.some((link) =>
      link.link_name.toLowerCase().includes(query)
    )
  );

  setFilteredItems(filteredLinks); // Exclude "All" from the list when searching
  setActiveCategory(filteredLinks[0]?.sub_category_name || ''); // Default to the first match or empty
};








  const handleEngineSelect = (engineName) => {
    setSelectedEngines(prevSelected =>
      prevSelected.includes(engineName)
        ? prevSelected.filter(name => name !== engineName)
        : [...prevSelected, engineName]
    );
  };
  
  const handleSearchbtn = (event) => {
   // if (event.key === 'Enter' && searchQuery.trim()) {
      const searchUrls = selectedEngines.map(engineName => {
        const engine = data.searchengine.searchenginedata.find(se => se.searchenginename === engineName);
        return engine.searchengineurl + encodeURIComponent(searchQuery);
      }).join('/~');

      if (searchUrls) {
        window.open(`collections:${searchUrls}`, '_blank');
      }
    //}
  };

  const handleSearch = (event) => {
    if (event.key === 'Enter' && searchQuery.trim()) {
      const searchUrls = selectedEngines.map(engineName => {
        const engine = data.searchengine.searchenginedata.find(se => se.searchenginename === engineName);
        return engine.searchengineurl + encodeURIComponent(searchQuery);
      }).join('/~');

      if (searchUrls) {
        window.open(`collections:${searchUrls}`, '_blank');
      }
    }
  };
  
const clearSearch = () => {
  setSearchQuery(''); // Clear the search query
  setFilteredItems(['All', ...data.linkdata]); // Restore all categories, including "All"
  setActiveCategory('All'); // Set "All" as the active category
};




  const updateActiveCategory = useCallback(() => {
    const headerHeight = headerRef.current ? headerRef.current.offsetHeight : 0;
    const scrollPosition = window.scrollY + headerHeight;

    let newActiveCategory = 'All';
    let smallestDistance = Infinity;

    Object.entries(categoryRefs.current).forEach(([categoryName, ref]) => {
      if (ref) {
        const distance = Math.abs(ref.offsetTop - scrollPosition);
        if (distance < smallestDistance) {
          smallestDistance = distance;
          newActiveCategory = categoryName;
        }
      }
    });

    if (newActiveCategory !== activeCategory) {
      setActiveCategory(newActiveCategory);
      scrollTabsToCategory(newActiveCategory);
    }
  }, [activeCategory, scrollTabsToCategory]);

const throttledUpdateActiveCategory = useCallback(
    throttle(() => {
        if (isManualScrolling.current) return;
        updateActiveCategory();
    }, 100, { leading: true, trailing: true }),
    [updateActiveCategory]
);


useEffect(() => {
  window.addEventListener('scroll', throttledUpdateActiveCategory);
  return () => {
    window.removeEventListener('scroll', throttledUpdateActiveCategory);
  };
}, [throttledUpdateActiveCategory]);


  useEffect(() => {
    if (data) {
      filterItems(searchQuery, selectedCategory);
    }
  }, [data, filterItems, searchQuery, selectedCategory]);

    if (loading) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh' 
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!data || data.linkdata.length === 0) {
    return <Typography>No data available.</Typography>;
  }

  const nonEmptyCategories = ['All', ...data.linkdata.map(subcat => subcat.sub_category_name)];

 return (
    <CategoryPageContainer>
	
    {/* Banner Slider */}
{data.banner && data.banner.length > 0 && (
  <CategoryBannerContainer>
    <CarouselContainer>
      <Carousel {...carouselSettings}>
        {data.banner.map((bannerItem) => (
          <CarouselImage key={bannerItem.gallery_id}>
            <CarouselImageItem
              src={bannerItem.gallery_image}
              alt={bannerItem.gallery_name}
            />
          </CarouselImage>
        ))}
      </Carousel>
    </CarouselContainer>
  </CategoryBannerContainer>
)}

	
   <Box
  ref={headerRef}
  className="sticky-web-apps"
  sx={{
    position: 'sticky',
    top: 0,
    zIndex: 2,
    bgcolor: 'background.paper',
    p: 2,
    boxShadow: 1,
  }}
>
  <TextField
    fullWidth
    variant="outlined"
    placeholder="Search..."
    value={searchQuery}
    onChange={handleSearchChange}
    onKeyDown={handleSearch}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
      endAdornment: searchQuery && (
        <InputAdornment position="end">
          <IconButton onClick={clearSearch} edge="end" size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </InputAdornment>
      ),
    }}
    sx={{
      backgroundColor: '#e9d9d9',
      borderRadius: '50px',
      maxWidth: 500,
      mb: 2,
      '& .MuiOutlinedInput-root': {
        borderRadius: '50px',
        padding: '5px 15px',
        transition: 'all 0.3s ease-in-out',
        height: '40px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ccc',
        borderWidth: '1px',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#888',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#3f51b5',
        boxShadow: '0 0 0 2px rgba(63, 81, 181, 0.2)',
      },
    }}
  />
        
{filteredItems.length > 0 && (
  <Box
    ref={tabsContainerRef}
    sx={{
      position: 'sticky',
      top: 0,
      zIndex: 2,
      bgcolor: 'background.paper',
      p: 2,
      display: 'flex',
      overflowX: 'auto',
      scrollbarWidth: 'none',
    }}
  >
    {(searchQuery ? filteredItems : [{ sub_category_name: 'All' }, ...filteredItems]).map((category) =>
      category.sub_category_name === 'All' ? (
        <StyledCategoryTab
          key="All"
          active={activeCategory === 'All'}
          onClick={() => handleCategoryChange('All')}
          ref={(el) => (tabRefs.current['All'] = el)}
        >
          All
        </StyledCategoryTab>
      ) : (
        <StyledCategoryTab
           key={category.sub_category_name}
           active={activeCategory === category.sub_category_name}
           onClick={() => handleCategoryChange(category.sub_category_name)}
           ref={(el) => (tabRefs.current[category.sub_category_name] = el)}
        >
          {category.sub_category_name}
        </StyledCategoryTab>
      )
    )}
  </Box>
)}


		
		 
		
      </Box>

      <Box sx={{ flexGrow: 1, p: 2 }}>
 {filteredItems.length === 0 && searchQuery.trim() !== '' && (
    <Box display="flex" justifyContent="center" flexWrap="wrap" mb={2}>
      {data.searchengine.searchenginedata.map((engine) => {
        const isSelected = selectedEngines.includes(engine.searchenginename);
        return (
          <Button
            key={engine.searchenginename}
            onClick={() => handleEngineSelect(engine.searchenginename)}
            sx={{
              margin: '4px',
              borderRadius: '50%',
              backgroundColor: isSelected ? colors.accent2 : '#ccc',
              color: 'white',
              minWidth: '50px',
              minHeight: '50px',
              width: '50px',
              height: '50px',
              padding: '0',
              '&:hover': {
                backgroundColor: isSelected ? colors.accent2 : '#bbb',
              },
            }}
          >
            <img
              src={engine.searchengineimage.replace("../", IMAGE_BASE_URL)}
              alt={engine.searchenginename}
              style={{ width: '60%', height: '60%' }}
            />
          </Button>
        );
      })}
    </Box>
  )}

   <Box sx={{ flexGrow: 1, p: 2 }}>
  {filteredItems.length === 0 && searchQuery.trim() !== '' && (
    <Box textAlign="center" mt={2}>
      <Button
        variant="contained"
        onClick={handleSearchbtn}
        sx={{
          backgroundColor: colors.accent2,
          color: '#FFFFFF',
          borderRadius: '20px',
          '&:hover': {
            backgroundColor: colors.accent2,
            opacity: 0.9,
          },
        }}
      >
        Search
      </Button>
    </Box>
  )}

 {filteredItems.map((subcat) => (
  <HighlightedPaper
    key={subcat.subcat_id}
    elevation={4}
    ref={(el) => {
      categoryRefs.current[subcat.sub_category_name] = el;
    }}
    data-category={subcat.sub_category_name}
    active={activeCategory === subcat.sub_category_name}
    sx={{
      scrollMarginTop: '100px', // Adds space above the card when scrolling
      transition: 'all 0.3s ease-in-out'
    }}
  >
    <Typography variant="h6" sx={{ mb: 2, color: colors.secondary }}>
      {subcat.sub_category_name}
    </Typography>

    <Grid container spacing={2}>
      {subcat.links?.map((link) => (
        <Grid item xs={3} sm={4} md={3} key={link.link_id}>
          <a
            href={link.link_URL}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <Avatar
              src={link.link_image}
              alt={link.link_name}
              sx={{
                width: 60,
                height: 60,
                mb: 2,
                backgroundColor: '#f5f5f5',
              }}
            />
            <Typography
              variant="body1"
              align="center"
              sx={{
                fontWeight: '500',
                color: activeCategory === subcat.sub_category_name ? colors.accent4 : '#000',
                fontSize: '14px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: '100%',
              }}
            >
              {link.link_name}
            </Typography>
          </a>
        </Grid>
      ))}
    </Grid>
  </HighlightedPaper>
))}

</Box>
      </Box>
	  <FooterComponent />
     </CategoryPageContainer>
  );
};

export default CategoryPage;