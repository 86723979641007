import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { 
  Typography, Box, TextField, InputAdornment, 
  CircularProgress, Paper, ThemeProvider, createTheme, 
  Avatar, Grid, Container, Button, IconButton
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { red } from '@mui/material/colors';
import FooterComponent from './FooterComponent';
import styled from 'styled-components';
import { getApiUrl, DEFAULT_IMAGE_BASE_URL, ENDPOINTS } from './config/apiConfigext';


import {
  PopularLinksWrapper,
  PopularLinkItem,
  PopularLinkAvatar,
  PopularLinkText,
  ShowMoreButton,
  CategoryItemText
} from './theme-styles';

import {
  // Colors and Theme
  colors,
  
  // Container Components
  WebAppsContainer,
  WebAppsTitle,
  
  // Search Components
  SearchTextField,
  SearchEngineAvatar,
  
  // Category Components
  CategoryListBox,
  CategoryItem,
  CategoryItemAvatar,
 
  
  // Links Components
  LinksByCategoryPaper,
  LinkItem,
  LinkItemAvatar,
  LinkItemText,
  MoreButton,
  
  // Banner Components
  WebAppsBannerContainer,
  BannerImage,
  
  // Popular Links Components
  PopularLinksContainer,
  LoadingWrapper,
  SearchResultsContainer,
  GridContainer,
  SearchResultItem,
  SearchEngineContainer,
  SearchEngineItem,
  SearchEngineName,
  ErrorWrapper,
  ErrorText
} from './theme-styles';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    background: {
      default: 'transparent',
    },
  },
});

const SearchEngineButton = styled(Button)`
  margin: 4px;
  border-radius: 50%;
  background-color: ${(props) => (props.selected ? colors.accent2 : '#ccc')};
  color: white;
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;
  padding: 0;
  &:hover {
    background-color: ${(props) => (props.selected ? colors.accent2 : '#bbb')};
  }
`;

const WebAppsPage = () => {
  const [data, setData] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
   const [showSearchButton, setShowSearchButton] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [selectedEngines, setSelectedEngines] = useState([]);
  const [isSticky, setIsSticky] = useState(false);
  const categoryListRef = useRef(null);
  const contentRef = useRef(null);
  const [visibleCategory, setVisibleCategory] = useState(null);
  const isUserScrolling = useRef(true); // Track if the user is scrolling
   const [isClickScrolling, setIsClickScrolling] = useState(false);
   const [links, setLinks] = useState([]);
   const [filteredItems, setFilteredItems] = useState([]);
 

useEffect(() => {
  fetch(getApiUrl(ENDPOINTS.WEB_APPS))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      return response.json();
    })
    .then((jsonData) => {
      setData(jsonData);

      // Set the default selected engines where "selected" is true
      const defaultEngines = (jsonData.searchengine.searchenginedata || [])
        .filter(engine => engine.selected === "true")
        .map(engine => engine.searchenginename);

      setSelectedEngines(defaultEngines); // Set selected engines state

      setFilteredItems(jsonData.categories); // Set the initial category list
      setIsLoading(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setError(error.message);
      setIsLoading(false);
    });
}, []);
 
   
   useEffect(() => {
  if (!searchQuery.trim()) return;

  setIsLoading(false);
  setError(null);

  fetch(getApiUrl(ENDPOINTS.WEB_APPS, `search=${encodeURIComponent(searchQuery)}`))
     .then(response => {
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      return response.json();
    })
    .then(jsonData => {
      setData(jsonData);
      if (jsonData.linkdata && jsonData.linkdata.length > 0) {
        const combinedLinks = jsonData.linkdata.flatMap(category => category.links || []);
        setLinks(combinedLinks);
      } else {
        setLinks([]);
      }
      setIsLoading(false);
    })
    .catch(error => {
      console.error('Error:', error);
      setError(error.message);
      setIsLoading(false);
    });
}, [searchQuery]);


useEffect(() => {
  // Initially hide the search button on page load
  setShowSearchButton(false);
}, []);


useEffect(() => {
  fetch(getApiUrl(ENDPOINTS.WEB_APPS))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      return response.json();
    })
    .then((jsonData) => {
      setData(jsonData);
      setFilteredItems(jsonData.categories); // Set the initial category list
      setIsLoading(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setError(error.message);
      setIsLoading(false);
    });
}, []);

  
  
  
useEffect(() => {
  const handleScroll = () => {
    if (isClickScrolling) return; // Ignore scroll if it's from a click

    // Monitor category list and determine if the right-side content needs adjustment
    if (categoryListRef.current) {
      const { top } = categoryListRef.current.getBoundingClientRect();
      const newIsSticky = top <= 20;
      setIsSticky(newIsSticky);

      const categoryElements = document.querySelectorAll('[id^="category-"]');
      let currentVisibleCategory = null;

      for (let i = 0; i < categoryElements.length; i++) {
        const element = categoryElements[i];
        const rect = element.getBoundingClientRect();
        if (rect.top <= 100 && rect.bottom >= 100) {
          currentVisibleCategory = element.id.split('-')[1];
          break;
        }
      }

      // If visible category changes, snap the list item to the top
      if (currentVisibleCategory && currentVisibleCategory !== visibleCategory) {
        setVisibleCategory(currentVisibleCategory);
        setActiveCategory(currentVisibleCategory);

        // Snap the highlighted section on the right to the top
        const listItem = document.getElementById(`list-item-${currentVisibleCategory}`);
        if (listItem) {
          // Check if it's moving out of view, force it back to the top
          const listItemRect = listItem.getBoundingClientRect();
          if (listItemRect.top < 0 || listItemRect.top > 100) {
            listItem.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }
      }
    }
  };

  window.addEventListener('scroll', handleScroll);
  return () => window.removeEventListener('scroll', handleScroll);
}, [visibleCategory, isClickScrolling]);


const clearSearch = useCallback(() => {
  setSearchQuery(''); // Clear the search query
  setShowSearchButton(false); // Hide the search button
  setLinks([]); // Clear filtered links
  setFilteredItems(data.categories); // Reset categories to the original list
  setIsLoading(true); // Show loading while resetting

  fetch(getApiUrl(ENDPOINTS.WEB_APPS))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      return response.json();
    })
    .then((jsonData) => {
      setData(jsonData); // Reset data to the initial fetched data
      setLinks([]); // Clear search results
      setFilteredItems(jsonData.categories); // Reset the category list
      setIsLoading(false); // Stop loading
    })
    .catch((error) => {
      console.error('Error:', error);
      setError(error.message);
      setIsLoading(false);
    });
}, [data]);



const handleCategoryClick = useCallback((categoryId) => {
  // Disable scroll updates during user click
  isUserScrolling.current = false;

  // Set the active category
  setIsClickScrolling(true);
  setActiveCategory(categoryId);
  setVisibleCategory(categoryId);

  // Reset background color for all categories first
  const categoryElements = document.querySelectorAll('[id^="category-"]');
  categoryElements.forEach((element) => {
    element.style.backgroundColor = "";  // Reset background color for all categories
  });

  // Apply background color directly to the clicked category
   const clickedElement = document.getElementById(`category-${categoryId}`);
    if (clickedElement) {
      const header = document.querySelector('header');
      const headerHeight = header ? header.offsetHeight : 0;
      const stickyCategories = document.querySelector('.sticky-category');
      const stickyOffset = stickyCategories && stickyCategories.offsetHeight > 0 ? 20 : 0;

      const y = clickedElement.getBoundingClientRect().top + window.pageYOffset - headerHeight - stickyOffset;

      window.scrollTo({
        top: y,
        behavior: 'smooth'
      });
    // Apply background color to the clicked category
    clickedElement.style.backgroundColor = "rgb(221 200 200)";  // Set background color to red
  }

  // Re-enable scroll updates after a delay
  setTimeout(() => {
   setIsClickScrolling(false);
  }, 1000); // Adjust timing as necessary
}, []);

  useEffect(() => {
    // Simulating data fetching
    setData({
      categories: [
        { category_id: 1, category_name: 'Category 1' },
        { category_id: 2, category_name: 'Category 2' },
        { category_id: 3, category_name: 'Category 3' },
      ],
      linkdata: [
        { category_id: 1, sub_category_name: 'Sub-category 1', links: [{ link_id: 1, link_name: 'Link 1-1' }] },
        { category_id: 2, sub_category_name: 'Sub-category 2', links: [{ link_id: 2, link_name: 'Link 2-1' }] },
        { category_id: 3, sub_category_name: 'Sub-category 3', links: [{ link_id: 3, link_name: 'Link 3-1' }] },
      ],
    });
  }, []);



  useEffect(() => {
    const resetRedBackgrounds = () => {
      // Get all elements whose ID starts with "category-"
      const elements = document.querySelectorAll('[id^="category-"]');
      elements.forEach((element) => {
        const bgColor = window.getComputedStyle(element).backgroundColor;
        // If background color is red, reset it
       
          element.style.backgroundColor = '';  // Reset to default background
        
      });
    };

    const handleClickOutside = (event) => {
      // Check if the click is outside both the left (category list) and right (content) sections
      if (
        categoryListRef.current && !categoryListRef.current.contains(event.target) && // Click outside left section
        contentRef.current && !contentRef.current.contains(event.target) // Click outside right section
      ) {
        setActiveCategory(null); // Reset activeCategory to null
        resetRedBackgrounds();   // Reset all red backgrounds
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);




  const handleSelectEngine = useCallback((engineName) => {
    setSelectedEngines(prevState =>
      prevState.includes(engineName)
        ? prevState.filter(name => name !== engineName)
        : [...prevState, engineName]
    );
  }, []);

const handleSearch = useCallback((e) => {
  const query = searchQuery.trim().toLowerCase();

  if (query !== '') {
    setShowSearchButton(true); // Show the search button when there's a query

    // Handle "Enter" or click events for search
    if (e.key === 'Enter' || e.type === 'click') {
      const searchUrls = selectedEngines.map(engineName => {
        const engine = data.searchengine.searchenginedata.find(se => se.searchenginename === engineName);
        return engine.searchengineurl + encodeURIComponent(query);
      }).join('/~');

      if (searchUrls) {
        window.open(`collections:${searchUrls}`, '_blank');
      }
    } else {
      // Filter the data for search results
      const filteredPopularLinks = (data.popular_links || []).filter(link =>
        link.link_name.toLowerCase().includes(query)
      );

      const filteredLinkData = data.linkdata.reduce((acc, category) => {
        const filteredLinks = (category.links || []).filter(link =>
          link.link_name.toLowerCase().includes(query)
        );
        if (filteredLinks.length > 0) {
          acc.push({ ...category, links: filteredLinks });
        }
        return acc;
      }, []);

      // Update both links and categories dynamically
      setLinks(filteredLinkData.flatMap(category => category.links)); // Update filtered links
      setFilteredItems(data.categories.filter(category =>
        filteredLinkData.some(filteredCategory => filteredCategory.category_id === category.category_id)
      )); // Update categories for the sticky menu

      setIsLoading(false);
    }
  } else {
    // Reset everything to the initial state
    clearSearch();
  }
}, [searchQuery, selectedEngines, data, clearSearch]);



const handleSearchChange = (event) => {
  const query = event.target.value; // Do not trim here
  setSearchQuery(query); // Set the raw query with spaces
};


const handleSearchKeyUp = (event) => {
  if (event.key === 'Enter') {
    const query = searchQuery.trim();
    if (query !== '') {
      // Ensure this function is called correctly
      const searchUrls = selectedEngines.map((engineName) => {
        const engine = data.searchengine.searchenginedata.find(
          (se) => se.searchenginename === engineName
        );
        return engine?.searchengineurl + encodeURIComponent(query);
      });

      if (searchUrls.length > 0) {
        // Open all URLs in new tabs
        searchUrls.forEach((url) => {
          if (url) window.open(url, '_blank', 'noopener,noreferrer');
        });
      }
    }
  }
};





  
    const handleSearchBtn = useCallback(() => {
    handleSearch({ type: 'click' });
  }, [handleSearch]);




  const filteredData = useMemo(() => {
    if (!data) return null;
    if (!searchQuery) return data;

    const lowercasedQuery = searchQuery.toLowerCase();

    // Helper function to safely check if a string includes the query
    const safeIncludes = (str, query) => 
      (str && typeof str === 'string') ? str.toLowerCase().includes(query) : false;

    // Filter categories and their corresponding linkdata
     const filteredCategories = data.categories.filter(category => 
      safeIncludes(category.category_name, lowercasedQuery)
    );

   const filteredLinkData = data.linkdata.reduce((acc, category) => {
      const categoryMatch = filteredCategories.some(c => c.category_id === category.category_id);
      const subCategoryMatch = safeIncludes(category.sub_category_name, lowercasedQuery);
      

      const filteredLinks = (category.links || []).filter(link =>
        safeIncludes(link.link_name, lowercasedQuery) ||
        categoryMatch ||
        subCategoryMatch
      );

      if (filteredLinks.length > 0) {
        acc.push({ ...category, links: filteredLinks });
      }

      return acc;
    }, []);
	
	const matchedLinks = data.linkdata.reduce((acc, category) => {
    const filteredLinks = (category.links || []).filter(link =>
      safeIncludes(link.link_name, lowercasedQuery) ||
      safeIncludes(category.sub_category_name, lowercasedQuery)
    );
    return [...acc, ...filteredLinks];
  }, []);
	
	
	
 const noResults = matchedLinks.length === 0;
    setShowSearchButton(noResults);

   return {
    ...data,
    categories: [],
    linkdata: matchedLinks.length > 0 ? [{
      category_id: 'search-results',
      sub_category_name: 'Search Results',
      links: matchedLinks
    }] : []
  };
}, [data, searchQuery]);

return (
  <ThemeProvider theme={theme}>
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}>
      <WebAppsContainer maxWidth="lg">
         <WebAppsTitle variant="h4">
            Web-Apps
          </WebAppsTitle>

        {isLoading && (
          <LoadingWrapper>
            <CircularProgress />
          </LoadingWrapper>
        )}
        {error && <ErrorDisplay message={error} />}

        {!isLoading && !error && filteredData && (
          <Box>
            {!searchQuery && filteredData.header_banner && filteredData.header_banner.length > 0 && (
              <WebAppsBannerContainer>
                <BannerSlider banners={filteredData.header_banner} />
              </WebAppsBannerContainer>
            )}

            {/* Search box */}
<Box sx={{ mb: 4 }}>
  {/* Updated Search Box */}
<SearchTextField
  fullWidth
  variant="outlined"
  placeholder="Search for apps..."
  value={searchQuery}
  onChange={handleSearchChange}
  onKeyUp={handleSearchKeyUp}
  InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <SearchIcon />
      </InputAdornment>
    ),
    endAdornment: searchQuery && (
      <InputAdornment position="end">
        <IconButton onClick={clearSearch} edge="end" size="small">
          <CloseIcon fontSize="small" />
        </IconButton>
      </InputAdornment>
    ),
  }}
  sx={{
    backgroundColor: '#f7f7f7',
    borderRadius: '30px',
    mb: 2,
    '& .MuiOutlinedInput-root': {
      borderRadius: '30px',
      padding: '8px 16px',
      transition: 'border-color 0.2s, box-shadow 0.2s',
      height: '45px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ddd',
      borderWidth: '1px',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#aaa',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#007bff',
      boxShadow: '0 0 5px rgba(0, 123, 255, 0.5)',
    },
  }}
/>



  {/* Updated Search Engine Buttons */}
{filteredData.searchengine && filteredData.searchengine.searchenginedata && (
  <Box display="flex" justifyContent="center" flexWrap="wrap" mb={2}>
    {filteredData.searchengine.searchenginedata.map((engine) => {
      const isSelected = selectedEngines.includes(engine.searchenginename);
      return (
        <Button
          key={engine.searchenginename}
          onClick={() => handleSelectEngine(engine.searchenginename)}
          sx={{
            margin: '4px',
            borderRadius: '50%',
            backgroundColor: isSelected ? colors.accent2 : '#ccc',
            color: 'white',
            minWidth: '50px',
            minHeight: '50px',
            width: '50px',
            height: '50px',
            padding: '0',
            '&:hover': {
              backgroundColor: isSelected ? colors.accent2 : '#bbb',
            },
            boxShadow: isSelected ? '0 0 10px rgba(0, 123, 255, 0.5)' : 'none', // Highlight selected
          }}
        >
          <img
            src={engine.searchengineimage.replace("../", "https://apps.5bestincity.com/")}
            alt={engine.searchenginename}
            style={{ width: '60%', height: '60%' }}
          />
        </Button>
      );
    })}
  </Box>
)}


  {/* Search Button */}
  {searchQuery && (
    <Box textAlign="center" mt={2}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSearchBtn}
        sx={{
          backgroundColor: colors.accent2,
          color: '#FFFFFF',
          borderRadius: '20px',
          '&:hover': {
            backgroundColor: colors.accent2,
            opacity: 0.9,
          },
        }}
      >
        Search
      </Button>
    </Box>
  )}
</Box>


            {/* Search Results */}
  {searchQuery && links.length > 0 && (
    <Box mt={4}>
     <Grid container spacing={2}>
  {links.map((link) => {
    // Define the default fallback image URL
   const defaultImage = DEFAULT_IMAGE_BASE_URL;


    return (
      <Grid item xs={3} key={link.link_id}>
        <Box
          component="a"
          href={link.link_URL}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '1px solid #e0e0e0',
            borderRadius: '8px',
            padding: '8px',
            transition: 'box-shadow 0.3s',
            '&:hover': {
              boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
            },
          }}
        >
          <Avatar
            src={link.link_image || defaultImage} // Use link_image or fallback default image
            alt={link.link_name}
            onError={(e) => {
              // Handle image load error by replacing with default image
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = defaultImage;
            }}
            sx={{
              width: 50,
              height: 50,
              mb: 1,
              padding: '0px',
              border: '2px solid #b3b3b3',
            }}
          />
          <Typography
            variant="caption"
            align="center"
            sx={{
              textDecoration: 'none',
              color: 'rgba(0, 0, 0, 0.87)',
              fontWeight: 'bold',
              lineHeight: '12px',
              fontSize: '11px',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              width: '100%',
              overflow: 'hidden',
              WebkitLineClamp: 2, // Limit to 2 lines
              textOverflow: 'ellipsis', // Add ellipsis for overflow
            }}
          >
            {link.link_name}
          </Typography>
        </Box>
      </Grid>
    );
  })}
</Grid>

    </Box>
  )}

            {!searchQuery && filteredData.popular_links && filteredData.popular_links.length > 0 && (
              <PopularLinks popularLinks={filteredData.popular_links} />
            )}

            <Box
              ref={categoryListRef}
              sx={{
                display: 'flex',
                flexDirection: isSticky ? 'row' : 'column',
                width: '100%',
              }}
            >
              {filteredData.categories.length > 0 && (
              <CategoryList
  categories={filteredItems.length > 0 ? filteredItems : data.categories} 
  onCategoryClick={handleCategoryClick}
  activeCategory={activeCategory}
  isSticky={isSticky}
/>

              )}

              <Box
                ref={contentRef}
                sx={{ flex: 1, pl: isSticky ? 2 : 0, pt: isSticky ? 0 : 2 }}
              >
                {!searchQuery && filteredData.linkdata.length > 0 && (
                  <LinksByCategory 
                    linkData={filteredData.linkdata} 
                    activeCategory={activeCategory}
                    isSticky={isSticky}
                  />
                )}
              </Box>
            </Box>

            {!searchQuery && filteredData.footer_banner && filteredData.footer_banner.length > 0 && (
              <Box mt={4}>
                <BannerSlider banners={filteredData.footer_banner} />
              </Box>
            )}
          </Box>
        )}
      </WebAppsContainer>

      {!isLoading && !error && !filteredData && (
        <Typography>No data available. Please try again later.</Typography>
      )}
    </Box>
	  <FooterComponent />
  </ThemeProvider>
);

};

const SearchEngines = ({ searchEngines, selectedEngines, onSelectEngine }) => (
  <Box display="flex" justifyContent="center" mb={2}>
    {searchEngines.map((engine) => {
      const isSelected = selectedEngines.includes(engine.searchenginename);
      return (
        <Box 
          key={engine.searchenginename} 
          mx={1} 
          textAlign="center"
          onClick={() => onSelectEngine(engine.searchenginename)}
          sx={{
            cursor: 'pointer',
          }}
        >
          <SearchEngineAvatar
            src={engine.searchengineimage}
            alt={engine.searchenginename}
            sx={{ 
              width: 40, 
              height: 40, padding:'8px',
              bgcolor: isSelected ? 'primary.main' : 'gray' 
            }}
          >
            {engine.searchenginename.charAt(0).toUpperCase()}
          </SearchEngineAvatar>
          <CategoryItemText>
          {engine.searchenginename}
           </CategoryItemText>
        </Box>
      );
    })}
  </Box>
);

const PopularLinks = ({ popularLinks }) => {
  const [showAll, setShowAll] = useState(false);
  const displayedLinks = showAll ? popularLinks : popularLinks.slice(0, 3);

  const handleLinkClick = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <PopularLinksWrapper elevation={1}>
      <CategoryItemText 
  variant="h3" // Increase the size
  gutterBottom
  sx={{
    fontWeight: 'bold', // Highlight the title
    color: '#333', // Darker color for better visibility
    textAlign: 'center',
	fontSize: '20px',
    mb: 2, // Add spacing below the title
  }}
>
  Popular
</CategoryItemText>

      <Grid container spacing={2}>
        {displayedLinks.map((link) => (
          <Grid item xs={3} key={link.link_id}>
            <PopularLinkItem onClick={() => handleLinkClick(link.link_URL)}>
              <PopularLinkAvatar
                src={link.link_image}
                alt={link.link_name}
              />
              <PopularLinkText>
                {link.link_name}
              </PopularLinkText>
            </PopularLinkItem>
          </Grid>
        ))}
      </Grid>

      {/* Show More/Less buttons */}
      {!showAll && popularLinks.length > 3 && (
        <Box textAlign="center">
          <ShowMoreButton 
            variant="outlined" 
            onClick={() => setShowAll(true)}
          >
            More
          </ShowMoreButton>
        </Box>
      )}

      {showAll && (
        <Box textAlign="center">
          <ShowMoreButton 
            variant="outlined" 
            onClick={() => setShowAll(false)}
          >
            Less
          </ShowMoreButton>
        </Box>
      )}
    </PopularLinksWrapper>
  );
};


const CategoryList = ({ categories, onCategoryClick, activeCategory, isSticky }) => {
  const categoryListRef = useRef(null); // Reference to the category list container

  useEffect(() => {
    if (activeCategory && categoryListRef.current) {
      // Find the active category element
      const activeCategoryElement = document.querySelector(`[data-category-id="${activeCategory}"]`);
      
      if (activeCategoryElement) {
        // Calculate the scroll position to bring the active category a bit below the top
        const categoryListTop = categoryListRef.current.getBoundingClientRect().top;
        const activeCategoryTop = activeCategoryElement.getBoundingClientRect().top;
        const scrollOffset = activeCategoryTop - categoryListTop - 40; // Scroll so that it's 40px below the top

        // Scroll the category list smoothly
        categoryListRef.current.scrollTo({
          top: categoryListRef.current.scrollTop + scrollOffset,
          behavior: 'smooth',
        });
      }
    }
  }, [activeCategory]); // Effect runs when activeCategory changes

  return (
    <CategoryListBox 
    ref={categoryListRef} // Attach ref to the category list container
  className={isSticky ? 'sticky-category' : ''}  // Add a class when sticky
    sx={{
      display:isSticky ? 'flex' : '-webkit-inline-box;',
      flexDirection: isSticky ? 'column' : 'row',
      overflowX: isSticky ? 'visible' : 'auto',
      overflowY: isSticky ? 'auto' : 'visible',
      position: isSticky ? 'sticky' : 'static',
      top: isSticky ? '0px' : 'auto', 
      maxHeight: isSticky ? 'calc(100vh - 40px)' : 'auto',
      width: isSticky ? '60px' : '100%',
      py: 2,
      bgcolor: isSticky ?'#fff' : 'transparent' ,
      zIndex: 1,
      '&::-webkit-scrollbar': {
    display: 'none', // Hides the scrollbar
       },
        '-ms-overflow-style': 'none', // For Internet Explorer and Edge
       'scrollbar-width': 'none', // For Firefox
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.2)',
        borderRadius: '3px',
      },
    }}
  >
      {categories.map((category) => {
        const isActive = String(activeCategory) === String(category.category_id);

        return (
          <CategoryItem
            key={category.category_id}
			 data-category-id={category.category_id}
            onClick={() => onCategoryClick(category.category_id)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingleft: '0px',
              paddingTop: '4px !important',
              paddingBottom: '4px !important',
              width: isSticky ? 'auto' : '70px',
              marginRight: '4px !important',
              marginLeft: '4px !important',
              border: '1px solid #d7cdcd',
              marginTop: '0px !important',
              marginBottom: isSticky ? '6px !important' : '0px',
              border: isSticky ? '0px solid #d9c3c3' :'1px solid #d9c3c3',
              borderRadius: isSticky ? '8px' : '8px',
              height: isSticky ? '100px' : 'auto', 
              fontSize: '9px',
              m: 1, 
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              transform: isActive ? 'scale(1.05)' : 'scale(1)',
              backgroundColor:isActive? '#C3512F' : 'transparent',
              '&:hover': {
                transform: 'scale(1.05)',
              },
              
            }}
          >
            <Box
              sx={{
            
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: isActive ? 'transparent' : 'background.paper',
                border: isActive ? '2px solid' : 'none',
                borderColor: 'primary.main',
                transition: 'all 0.3s ease',
              }}
            >
               <CategoryItemAvatar
                        src={category.category_image}
                        alt={category.category_name}
                        isActive={String(activeCategory) === String(category.category_id)}
                      />
            </Box>
          <CategoryItemText isActive={String(activeCategory) === String(category.category_id)}>
           {category.category_name.substring(0, 6)}
          </CategoryItemText>
          </CategoryItem>
        );
      })}
    </CategoryListBox>
  );
};

const LinksByCategory = ({ linkData, activeCategory, isSticky, isSearchResult }) => (
  <Box>
{linkData.map((category) => {
  const isActive = category.category_id === activeCategory;
  const showMoreButton = category.links.length > 14 && !isSearchResult;

  return (
    <Paper
      key={category.category_id}
      elevation={isActive ? 8 : 1}
      sx={{
        p: 2,
        mb: 2,
        bgcolor: isActive
          ? '#000000'
          : activeCategory == category.category_id
          ? '#eddddd'
          : 'transparent',
        transition: 'background-color 0.3s ease, transform 0.3s ease',
        transform: isActive ? 'scale(1.05)' : 'scale(1)',
        borderRadius: '10px',
        border: isActive ? '2px solid #ddd' : 'none',
      }}
      id={`category-${category.category_id}`}
    >
      <Typography variant="subtitle1" gutterBottom>
        {category.sub_category_name}
      </Typography>
      <Grid container spacing={2}>
        {(isSearchResult ? category.links : category.links.slice(0, 14)).map((link) => {
          // Define the default fallback image
          const defaultImage = DEFAULT_IMAGE_BASE_URL;


          return (
            <Grid item xs={4} md={2} sm={4} key={link.link_id}>
              <Box
                component="a"
                href={link.link_URL}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  textDecoration: 'none',
                  color: 'inherit',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  border: '0px solid #e0e0e0',
                  borderRadius: '8px',
                  padding: '0px',
                  backgroundColor: isActive ? 'transparent' : 'transparent',
                  transition: 'box-shadow 0.3s',
                }}
              >
                <Avatar
                  src={link.link_image || defaultImage} // Use link_image or default image
                  alt={link.link_name}
                  onError={(e) => {
                    e.target.onerror = null; // Prevent infinite loop
                    e.target.src = defaultImage; // Fallback to default image
                  }}
                  sx={{
                    width: 50,
                    height: 50,
                    mb: 1,
                    padding: '0px',
                    border: '2px solid #b3b3b3',
                  }}
                />
                <Typography
                  variant="caption"
                  align="center"
                  sx={{
                    textDecoration: 'none',
                    color: 'rgba(0, 0, 0, 0.87)',
                    fontWeight: 'bold',
                    lineHeight: '12px',
                    fontSize: '11px',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    width: '100%',
                    overflow: 'hidden',
                    WebkitLineClamp: 2,
                    textOverflow: 'ellipsis',
                  }}
                >
                  {link.link_name}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>

      {showMoreButton && (
        <Box mt={2} textAlign="center">
          <Typography
            component="a"
            href={`/category/${category.category_id}`}
            rel="noopener noreferrer"
            sx={{
              textDecoration: 'none',
              color: '#000',
              fontWeight: 'normal',
              border: '2px solid #c3a7a7',
              borderRadius: '50%',
              fontSize: '11px',
              width: '50px',
              display: 'block',
              height: '50px',
              margin: '0 auto',
              paddingTop: '14px',
              background: '#c3a7a7',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            More
          </Typography>
        </Box>
      )}
    </Paper>
  );
})}



  </Box>
);



const BannerSlider = ({ banners }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const timeoutRef = useRef(null);

  // Clear timeout to prevent memory leaks
  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  // Set a new timeout for banner transitions
  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setCurrentIndex((prevIndex) =>
          prevIndex === banners.length - 1 ? 0 : prevIndex + 1
        ),
      3000
    );

    return () => {
      resetTimeout();
    };
  }, [currentIndex, banners.length]);

  if (banners.length === 0) return null;

  return (
    <Box
      sx={{
        position: 'relative',
        height: '200px',
        overflow: 'hidden',
        marginTop: '-20px',
        borderRadius: '15px',
        backgroundColor: '#ffffff',
      }}
    >
      {/* Render banner images */}
      {banners.map((banner, index) => (
        <Box
          key={banner.gallery_id}
          component="img"
          src={banner.gallery_image}
          alt={banner.gallery_name}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover', // Ensures the image covers the container completely
            borderRadius: '15px',
            opacity: index === currentIndex ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
          }}
        />
      ))}

      {/* Navigation Dots */}
      {banners.length > 1 && (
        <Box
          sx={{
            position: 'absolute',
            bottom: '10px',
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'flex',
            gap: '8px',
          }}
        >
          {banners.map((_, idx) => (
            <Box
              key={idx}
              sx={{
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                backgroundColor: idx === currentIndex ? '#007bff' : '#ccc',
                transition: 'background-color 0.3s',
              }}
            />
          ))}
        </Box>
      )}

      {/* Footer Component */}
    

      {/* Spacer below the footer banner */}
      <Box sx={{ height: '200px', backgroundColor: 'transparent' }} />
    </Box>
  );
};

const ErrorDisplay = ({ message }) => (
  <Box textAlign="center" mt={4}>
    <Typography variant="h6" color="error">
      Error: {message}
    </Typography>
  </Box>
);

export default WebAppsPage;
