import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  Card, CardActions, CardContent, CardMedia, Typography, Grid, Container,
  IconButton, Box, InputBase, Button, AppBar, Toolbar, Modal, Checkbox,
  FormGroup, FormControlLabel, CardActionArea, CircularProgress, Slide, Grow, GlobalStyles 
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import SortIcon from '@mui/icons-material/Sort';
import FilterListIcon from '@mui/icons-material/FilterList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { styled, alpha } from '@mui/material/styles';
import Lottie from 'react-lottie';
import Carousel from 'react-material-ui-carousel';
import loaderAnimation from './loader-animation.json';
import loadMoreAnimation from './loader-animation.json';
import he from 'he';
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';
import {
  colors
} from '../../theme-styles';




import FilterModal from './FilterModal'; // Import the new FilterModal component

import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import UpdateIcon from '@mui/icons-material/Update';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import FooterComponent from '../../FooterComponent';

import {
  API_BASE_URL,
  ENDPOINTS,
  IMAGE_BASE_URL,
  getApiUrl,
  getEngineImageUrl,
  COLORS,
} from '../../config/apiConfigext';


const HeaderWrapper = styled('div')(({ theme }) => ({
  height: '80px',
  width: '100%',
  marginBottom: theme.spacing(2),
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: 20,
  textTransform: 'none',
  padding: theme.spacing(0.75, 2),
  minWidth: 'auto',
  fontWeight: 500,
  fontSize: '0.875rem',
}));

const SearchEngineIcon = styled('div')(({ theme }) => ({
  width: 50,
  height: 50,
  borderRadius: '50%',
  cursor: 'pointer',
  margin: '0 10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.3s ease',
  backgroundColor: 'gray', // Unselected state is gray
  '&.selected': {
    backgroundColor: 'red', // Selected state is red
  },
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '50%',
  },
}));

const SearchButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#007bff',
  color: 'white',
  '&:hover': {
    backgroundColor: '#0056b3',
  },
}));

const SortButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#ffd814',
  color: '#000',
  borderRadius: 20,
  padding: theme.spacing(0.75, 2),
  [theme.breakpoints.down('sm')]: {  // Maintain consistent button size on mobile
    minWidth: 'auto',
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
  '.sticky &': {
    flexGrow: 1,
    marginLeft: 0,
    marginRight: theme.spacing(2),
    width: '100%',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

const SearchButtonStyled = styled(Button)(({ theme }) => ({
  backgroundColor: colors.accent2, // Use the purple color similar to the image
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#5e35b1', // Darker shade for hover
  },
  borderRadius: '20px',
  padding: theme.spacing(1, 3),
  textTransform: 'uppercase',
}));


const ProductGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flexBasis: '50%',
    maxWidth: '50%',
  },
  [theme.breakpoints.up('sm')]: {
    flexBasis: '50%',
    maxWidth: '50%',
  },
  [theme.breakpoints.up('md')]: {
    flexBasis: '33.333333%',
    maxWidth: '33.333333%',
  },
}));


const ClearButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  right: 0,
  top: 0,
  color: alpha(theme.palette.common.black, 0.54),
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#ffffff',
  color: theme.palette.text.primary,
  boxShadow: 'none',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1100,
  width: '100%',
  height: '80px',
  padding: 0,
  margin: 0,
  transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
  transform: 'translateZ(0)',
  backfaceVisibility: 'hidden',
  willChange: 'transform',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#ffffff',
    zIndex: -1,
  },
  
  '&.sticky-header': {
    backgroundColor: '#ffffff',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
  }
}));



const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  width: '100%',
  maxWidth: 'none', // Allow full width, no constraints
  padding: 0, // Remove padding if any exists
  margin: 0, // Remove margin if any exists
}));



// Spacer Element



const SearchContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 1000,
  backgroundColor: '#fff',
  padding: theme.spacing(1.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
  transition: 'all 0.3s ease-in-out',
}));

const HeaderSpacer = styled('div')({
  display: 'none', // We don't need the spacer anymore
});

// Update StickySearchContainer
const StickySearchContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: '#ffffff',
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxShadow: 'none',
  transition: 'all 0.3s ease',
  height: '60px',
  margin: '0 16px',
  zIndex: 2, // Increased z-index
  
  '.sticky-header &': {
    backgroundColor: '#ffffff',
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0 8px',
  }
}));


const ContentContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  backgroundColor: '#f8f8f8',
  minHeight: '100vh',
  marginTop: '80px', // Fixed margin to account for fixed header
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));


const SearchWrapper = styled(Box)(({ theme, expanded }) => ({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #e0e0e0',
  borderRadius: 20,
  padding: theme.spacing(0.5, 1.5),
  flex: expanded ? 1 : 'none',
  width: expanded ? '100%' : 'auto',
  marginRight: expanded ? 0 : theme.spacing(1),
  backgroundColor: '#fff',
  transition: 'all 0.3s ease-in-out',
}));

const SearchBarWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #e0e0e0',
  borderRadius: 20,
  padding: theme.spacing(0.5, 1.5),
  backgroundColor: '#f0f0f0',
  flex: 1,
  height: '40px',
  marginRight: theme.spacing(1),
  position: 'relative', // Ensure it stays above content
  zIndex: 2,
}));

const ProductGridContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  backgroundColor: '#f8f8f8',
  padding: theme.spacing(1),
  width: '100%'
}));


const SearchInput = styled(InputBase)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  flex: 1,
  fontSize: '0.875rem',
}));

const StyledSearchInput = styled(InputBase)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  flex: 1,
  fontSize: '0.875rem',
}));





const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',
  backgroundColor: '#ffffff',
  boxShadow: '0px 3px 15px rgba(0,0,0,0.1)',
  borderRadius: '12px',
  padding: theme.spacing(2),
  transition: '0.3s',
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: '0px 5px 20px rgba(0,0,0,0.2)',
  },
}));


const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: '150px',
  width: '100%',
  objectFit: 'contain',
  marginBottom: theme.spacing(1),
}));


const DiscountBadge = styled(Box)(({ theme }) => ({
  backgroundColor: '#f8f8f8',
  color: '#ff7043',
  padding: theme.spacing(0.5, 2),
  borderRadius: '24px', // Rounded corners to match the desired design
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: '0.85rem',
  marginTop: theme.spacing(1),
  alignSelf: 'center',
}));

const TruncatedTypography = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2, // Limit to 2 lines
  WebkitBoxOrient: 'vertical',
});




const PageBackground = styled(Container)(({ theme }) => ({
  backgroundColor: '#f8f8f8',
  minHeight: '100vh',
  padding: 0,
  margin: 0,
  maxWidth: '100%',
  position: 'relative',
  overflowX: 'hidden',
  display: 'flex',
  flexDirection: 'column'
}));


const ExpandIconButton = styled(IconButton)(({ expanded }) => ({
  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: 'transform 0.6s ease-in-out',
}));

const LoaderContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  zIndex: 9999,
}));

const BannerImage = styled('img')({
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
  padding: '10px',  // Add padding around the banner
  borderRadius: '20px',  // Add a border radius to round the corners
});



const NoResultsMessage = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: '500px', // Wider modal for better visibility
  backgroundColor: theme.palette.background.paper,
  borderRadius: '12px', // Rounded corners for a modern look
  boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.15)', // Softer, more prominent shadow
  padding: theme.spacing(3), // More padding for better spacing
  transition: 'all 0.3s ease', // Smooth transition for a modern feel
}));

const FilterButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#ffd814',
  color: '#000',
  borderRadius: 20,
  padding: theme.spacing(0.75, 2),
  [theme.breakpoints.down('sm')]: {  // Maintain consistent button size on mobile
    minWidth: 'auto',
  },
}));

const FilterCount = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '24px',
  height: '24px',
  borderRadius: '12px',
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  fontSize: '0.85rem',
  fontWeight: 'bold',
  marginLeft: theme.spacing(1),
  padding: '0 8px',
}));




const loadMoreOptions = {
  loop: true,
  autoplay: true,
  animationData: loadMoreAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};


function ProductsPage() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
 
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchExpanded, setSearchExpanded] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const appBarRef = useRef(null);
  const searchInputRef = useRef(null);
  const [filterOptions, setFilterOptions] = useState({
  Price: [
    'Rs. 100 and Below',
    'Rs. 101 - Rs. 300',
    'Rs. 301 - Rs. 500',
    'Rs. 501 - Rs. 700',
    'Rs. 700 and Above'
  ],
  'F-Assured': ['F-Assured'],
  Brand: [],
  'Customer Ratings': ['4★ & above', '3★ & above'],
  Discount: ['50% or more', '40% or more', '30% or more', '20% or more', '10% or more'],
  Availability: ['Include Out of Stock'],
  'Corded/Cordless': ['Corded', 'Corded & Cordless', 'Cordless'],
  'Ideal for': ['Men', 'Women'],
  'GST Invoice Available': ['GST Invoice Available'],
  Offers: ['Buy More, Save More', 'Special Price'],
  Category: [] // This will be populated from the API
});
  const [selectedFilters, setSelectedFilters] = useState({});
  const [filterOpen, setFilterOpen] = useState(false);
  const loaderRef = useRef(null);
  const searchBarRef = useRef(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [expandedProducts, setExpandedProducts] = useState({});
  const [isFiltering, setIsFiltering] = useState(false);
  const [banners, setBanners] = useState([]);
  const [noMoreProducts, setNoMoreProducts] = useState(false);
  const [totalProducts, setTotalProducts] = useState(0);
   const [searchEngines, setSearchEngines] = useState([]);
  const [selectedEngines, setSelectedEngines] = useState([]);
  const [hasReachedEndOfFirstPage, setHasReachedEndOfFirstPage] = useState(false);
  const [displayedProductCount, setDisplayedProductCount] = useState(0);
    const [isExpanded, setIsExpanded] = useState(false);
	const [isSearchFocused, setIsSearchFocused] = useState(false);
  
      const [maxChars, setMaxChars] = useState(50);
	  
	  const handleSearchFocus = () => setIsSearchFocused(true);
     // const handleSearchBlur = () => setIsSearchFocused(false);
	 const [sortOpen, setSortOpen] = useState(false);
	 
	 const handleSortOpen = () => {
        setSortOpen(true); // Open the sort modal
     };

     const handleSortClose = () => {
       setSortOpen(false); // Close the sort modal
     };
	const [advancedFilterOpen, setAdvancedFilterOpen] = useState(false);
    const [advancedSelectedFilters, setAdvancedSelectedFilters] = useState({});
	
	const handleAdvancedFilterOpen = () => setAdvancedFilterOpen(true);
    const handleAdvancedFilterClose = () => setAdvancedFilterOpen(false);
	const [sortOption, setSortOption] = useState('');
	const [newFilterCategories, setNewFilterCategories] = useState([]); // State to store fetched categories for new filter
    const [newSelectedFilters, setNewSelectedFilters] = useState({});   // State to store selected filters
    const [newFilterOpen, setNewFilterOpen] = useState(false);          // State for modal visibility
	
	const [newFilteredProducts, setNewFilteredProducts] = useState([]);
	const [noProductsMessage, setNoProductsMessage] = useState('');
	const [tempSelectedFilters, setTempSelectedFilters] = useState({});
	const [allProducts, setAllProducts] = useState([]);
	
	 const productGridRef = useRef(null);
	 const [isLoading, setIsLoading] = useState(false);
     
	



		useEffect(() => {
		  if (products.length > 0) {
			setNewFilteredProducts(products);
		  }
		}, [products]);

  useEffect(() => {
    const updateMaxChars = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 480) {
        setMaxChars(30); // Set shorter max length for smaller screens
      } else {
        setMaxChars(50); // Set longer max length for larger screens
      }
    };

    // Set initial maxChars
    updateMaxChars();

    // Add event listener for resizing window
    window.addEventListener('resize', updateMaxChars);
    return () => {
      window.removeEventListener('resize', updateMaxChars);
    };
  }, []);

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + '...';
  };


  
  const handleProductClick = (url) => {
  window.open(url, '_blank');
};

useEffect(() => {
    if (products.length > 0) {
      applyFilters();
    }
  }, [selectedFilters, products]);
  
   const isFiltersApplied = () => {
    return Object.values(selectedFilters).some(category => 
      Object.values(category).some(isSelected => isSelected)
    );
  };

  
  
 // Add this useEffect to fetch categories from the filter API
useEffect(() => {
  const fetchCategories = async () => {
    try {
      const response = await fetch(getApiUrl(ENDPOINTS.AMAZON_FILTER));
      const categoryData = await response.json();
      const categories = categoryData.amazondata.map(category => ({
        id: category.store_id.toString(),  // Convert to string if necessary
        name: category.store_name,
      }));
      setFilterOptions(prev => ({
        ...prev,
        Category: categories,
      }));
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  fetchCategories();
}, []);



useEffect(() => {
  const fetchNewCategories = async () => {
    try {
      const response = await fetch(getApiUrl(ENDPOINTS.AMAZON_FILTER));
      const data = await response.json();
      
      setNewFilterCategories(data.amazondata);  // Store categories data
    } catch (error) {
      console.error('Error fetching new filter categories:', error);
    }
  };

  fetchNewCategories();  // Call function when component mounts
}, []);





   useEffect(() => {
    fetchProducts();
    fetchSearchEngines();
  }, []);

  const fetchSearchEngines = async () => {
    try {
      const response = await fetch(getApiUrl(ENDPOINTS.AMAZON_API, '?page=1'));
      const data = await response.json();
      setSearchEngines(data.searchengine.searchenginedata);
      const defaultEngines = data.searchengine.searchenginedata.filter(engine => engine.selected === "true");
      setSelectedEngines(defaultEngines);
    } catch (error) {
      console.error('Error fetching search engines:', error);
    }
  };

  const handleEngineToggle = (engine) => {
    setSelectedEngines(prev => 
      prev.some(e => e.searchenginename === engine.searchenginename)
        ? prev.filter(e => e.searchenginename !== engine.searchenginename)
        : [...prev, engine]
    );
  };

const handleExternalSearch = useCallback((e) => {
  if (e.key === 'Enter' || e.type === 'click') {
	  //alert(e.key);
    if (selectedEngines.length > 0 && searchTerm) {
      const encodedQuery = encodeURIComponent(searchTerm);
      const searchUrls = selectedEngines.map(engine => 
        `${engine.searchengineurl}${encodedQuery}`
      ).join('/~');

      if (searchUrls) {
        window.open(`collections:${searchUrls}`, '_blank');
      }
    }
  }
}, [selectedEngines, searchTerm]);

  
const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  
const handleNewFilterReset = () => {
  console.log("Resetting all new filters and sort conditions");

  // Clear all selected filters (reset both filter states)
  setNewSelectedFilters({});
  setTempSelectedFilters({});

  // Reset the sort option to default (no sorting)
  setSortOption(''); // or whatever represents "no sorting" in your logic

  // Re-fetch products with no filters and no sort option
  const newPage = 1;
  fetchProducts('', newPage, {}, ''); // No filters and no sort option to reset everything
  setPage(newPage); // Reset page number to 1

  // Optionally close the filter modal
  setNewFilterOpen(false);
};






  useEffect(() => {
    const fetchInitialProducts = async () => {
      setIsFetchingMore(true);
      try {
        const response = await fetch(getApiUrl(ENDPOINTS.AMAZON_API, '?page=1'));
        const data = await response.json();
        if (data.amazondata && data.amazondata.length > 0) {
          setProducts(data.amazondata);
          setPage(1);
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      } catch (error) {
        console.error('Failed to load initial data:', error);
        setHasMore(false);
      } finally {
        setIsFetchingMore(false);
      }
    };

    fetchInitialProducts();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasMore && !isFetchingMore && !isFiltering && hasReachedEndOfFirstPage && displayedProductCount >= 39) {
          fetchMoreProducts();
        }
      },
      { threshold: 0.1 }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [hasMore, isFetchingMore, isFiltering, searchTerm, page, hasReachedEndOfFirstPage, displayedProductCount]);
  
     useEffect(() => {
    const checkScrollPosition = () => {
      if (!hasReachedEndOfFirstPage && window.innerHeight + window.scrollY >= document.body.offsetHeight - 200) {
        setHasReachedEndOfFirstPage(true);
      }
    };

    window.addEventListener('scroll', checkScrollPosition);
    return () => window.removeEventListener('scroll', checkScrollPosition);
  }, [hasReachedEndOfFirstPage]);

// Update the initial useEffect to fetch products
useEffect(() => {
  fetchProducts();
}, []);

useEffect(() => {
  // Update the displayedProductCount based on the filtered products
  setDisplayedProductCount(filteredProducts.length);
}, [filteredProducts]);



/*const fetchMoreProducts = async () => {
	
  if (!hasMore || localStorage.getItem('flength')<39) return;
  setIsFetchingMore(true);
  const nextPage = page + 1;
  await fetchProducts(searchTerm, nextPage);
  setPage(nextPage);
};*/

const fetchMoreProducts = async () => {
    if (!hasMore || isFetchingMore || displayedProductCount < 39) return;

    const nextPage = page + 1;
    await fetchProducts(searchTerm, nextPage, newSelectedFilters, sortOption);
};


const debounce = (func, delay) => {
  let debounceTimer;
  return function(...args) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(this, args), delay);
  };
};

const debouncedFetchMoreProducts = debounce(fetchMoreProducts, 300);





useEffect(() => {
  if (searchTerm !== '') {
	 
     window.scrollTo({
      top: 0,
      behavior: 'smooth',
    }); // Scroll to the top only if there is a search term
  }
}, [searchTerm]);


useEffect(() => {
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (!isSticky && scrollPosition > 0) {
      setIsSticky(true);
    } else if (isSticky && scrollPosition === 0) {
      setIsSticky(false);
    }
  };

  window.addEventListener('scroll', handleScroll, { passive: true });
  return () => window.removeEventListener('scroll', handleScroll);
}, [isSticky]); // Add isSticky to dependencies




    useEffect(() => {
    if (!isInitialLoad) {
      const filtered = products.filter(product => 
        (searchTerm === '' || 
         product.product_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
         product.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
         product.offer_price.toString().includes(searchTerm)
        ) &&
        (Object.values(selectedFilters).every(v => v === false) || selectedFilters[product.store_id])
      );
	  //scrollToTop();
      setFilteredProducts(filtered);
    }
  }, [searchTerm, products, selectedFilters, isInitialLoad]);

const formatPrice = (price) => {
  if (price === null || price === undefined || price === '0') {
    return ''; // If offer price is missing or zero, return an empty string
  }
  return `₹${parseFloat(price).toFixed(2)}`;
};

const formatPriced = (price) => {
  if (price === 'N/A' || isNaN(parseFloat(price))) {
    return 'N/A';
  }
  return `₹${parseFloat(price)}`;
};

  const handleExpandClick = (productId) => {
    setExpandedProducts(prev => ({
      ...prev,
      [productId]: !prev[productId]
    }));
  };
  
 /*
const handleSearchFocus = () => {
  setIsSearchFocused(true);  // Expand the search bar
};
*/

/*
const handleSearchBlur = () => {
  if (!searchTerm) {
    setIsSearchFocused(false);  // Collapse the search bar if empty
  }
};
*/

const handleAdvancedFilterChange = (category, filter, isChecked) => {
  setAdvancedSelectedFilters((prev) => ({
    ...prev,
    [category]: {
      ...prev[category],
      [filter]: isChecked,
    },
  }));
};



const handleSearch = (event) => {
  const newSearchTerm = event.target.value.toLowerCase();
  setSearchTerm(newSearchTerm);
  setPage(1); // Reset page to 1 when searching

  // Fetch products with the updated search term, filters, and sorting options
  fetchProducts(newSearchTerm, 1, newSelectedFilters, sortOption);
  
   if (event.key === 'Enter') {
    handleExternalSearch(event);
  }
};







const handleClearSearch = () => {
  // Clear search term only, but keep filters and sort options intact
  setSearchTerm('');
  setPage(1); // Reset to page 1 for consistency

  console.log('Clearing search and fetching products with current filters and sort options');
  
  setIsSearchFocused(false);
  setSearchExpanded(false);
  
   if (searchInputRef.current) {
    searchInputRef.current.blur();
    searchInputRef.current.value = '';
  }

  // Re-fetch products with current filters and sort options
  fetchProducts('', 1, newSelectedFilters, sortOption);
};




const handleFilterChange = (category, filter, isChecked) => {
  console.log(`Filter changed: Category - ${category}, Filter - ${filter}, Checked - ${isChecked}`);
  setSelectedFilters(prev => {
    const updatedFilters = {
      ...prev,
      [category]: {
        ...prev[category],
        [filter]: isChecked
      }
    };
    
    // Re-fetch products with updated filters, search term, and sorting options
    const newPage = 1;
    fetchProducts(searchTerm, newPage, updatedFilters, sortOption);
    setPage(newPage); // Reset page number to 1 after filter change
    
    return updatedFilters;
  });
};


  const handleFilterReset = () => {
	  localStorage.setItem('isfilter','no');
    setSelectedFilters({});
    // Reset your product list here
  };

 const handleFilterApply = (appliedFilters) => {
    console.log("Applying filters:", appliedFilters);
    setSelectedFilters(appliedFilters);
    setFilterOpen(false);
    setIsFiltering(true);
	localStorage.setItem('isfilter','yes');
    //setPage(1);
    setHasMore(true);
    applyFilters(appliedFilters);
  };


 
const handleSearchExpand = () => {
  setIsExpanded(true);
  if (searchInputRef.current) {
    searchInputRef.current.focus();
  }
};
  
    const handleSearchCollapse = () => {
    if (!searchTerm) {
      setIsExpanded(false);
    }
  };

  
// New function to handle displaying the filtered products
const displayFilteredProducts = (filtered) => {
	
  if (filtered.length === 0) {
	  setHasMore(false);
    console.log("No products found matching your criteria.");
  } else {
	  localStorage.setItem('flength',filtered.length);
    console.log(`Displaying ${filtered.length} filtered products.`);
  }

  setFilteredProducts(filtered); // Still use this internally to update state
};

const applyFilters = (currentFilters = selectedFilters) => {
  let filtered = [...products];
  console.log("Total products before filtering:", filtered.length);

  // Iterate over each filter category
  Object.entries(currentFilters).forEach(([category, filters]) => {
    const activeFilters = Object.entries(filters).filter(([_, isActive]) => isActive).map(([filter]) => filter);
    
    if (activeFilters.length > 0) {
      console.log(`Applying ${category} filters:`, activeFilters);
      filtered = filtered.filter(product => {
        switch (category) {
          case 'Price':
            const price = parseFloat(product.offer_price || product.product_price) || 0;
            return activeFilters.some(filter => {
              switch (filter) {
                case 'Rs. 100 and Below':
                  return price <= 100;
                case 'Rs. 101 - Rs. 300':
                  return price > 100 && price <= 300;
                case 'Rs. 301 - Rs. 500':
                  return price > 300 && price <= 500;
                case 'Rs. 501 - Rs. 700':
                  return price > 500 && price <= 700;
                case 'Rs. 700 and Above':
                  return price > 700;
                default:
                  return false;
              }
            });

          case 'F-Assured':
            return product.is_f_assured === "1";

          case 'Brand':
            return activeFilters.includes(product.brand);

          case 'Customer Ratings':
            const rating = parseFloat(product.star_rating) || 0;
            return activeFilters.some(filter => {
              const minRating = parseFloat(filter.split('★')[0]);
              return rating >= minRating;
            });

          case 'Discount':
            const discount = parseFloat(product.discount_percentage) || 0;
            return activeFilters.some(filter => {
              const minDiscount = parseFloat(filter.replace(/[^0-9.-]+/g, ""));
              return discount >= minDiscount;
            });

          case 'Availability':
            return activeFilters.includes('Include Out of Stock') ? true : product.availability === "Now";

          case 'Corded/Cordless':
            return activeFilters.includes(product.corded_cordless);

          case 'Ideal for':
            return activeFilters.some(filter => 
              product.description.toLowerCase().includes(filter.toLowerCase())
            );

          case 'GST Invoice Available':
            return product.gst_invoice_available === "1";

          case 'Offers':
            return activeFilters.some(offer => 
              product.description.toLowerCase().includes(offer.toLowerCase())
            );

          case 'Category':
            // Filter products based on store_id that matches the active category filters
            return activeFilters.includes(product.store_id.toString());
			
          default:
            return true;
        }
      });
    }
  });

  console.log("Filtered products:", filtered.length);
  filtered.forEach(product => {
    console.log(`Product: ${product.product_name}, Price: ${product.offer_price || product.product_price}`);
  });
  
    setFilteredProducts(filtered);
	
    setIsFiltering(false);
  
    // Use the new function to display filtered products
    displayFilteredProducts(filtered);
};


const applyNewFilters = (filters = newSelectedFilters, sort = sortOption) => {
  let filtered = [...products];

  // Apply Filtering by Store ID
  const activeStoreFilters = Object.entries(filters)
    .filter(([_, isActive]) => isActive)
    .map(([storeId]) => storeId);

  if (activeStoreFilters.length > 0) {
    console.log(`Filtering products by store IDs: ${activeStoreFilters}`);
    filtered = filtered.filter((product) => activeStoreFilters.includes(product.storeid.toString()));
  }

  console.log("Products after filtering:", filtered.length);
  filtered.forEach((product) => {
    console.log(`Product ID: ${product.product_id}, Name: ${product.product_name}, Store ID: ${product.storeid}`);
  });

  // Apply Sorting
  if (sort) {
    console.log(`Sorting products based on option: ${sort}`);
    switch (sort) {
      case 'popularity':
        filtered.sort((a, b) => b.sales_rank - a.sales_rank); // Assuming `sales_rank` defines popularity
        break;
      case 'latest':
        filtered.sort((a, b) => new Date(b.creation_date) - new Date(a.creation_date));
        break;
      case 'priceHighToLow':
        filtered.sort((a, b) => parseFloat(b.offer_price || b.product_price) - parseFloat(a.offer_price || a.product_price));
        break;
      case 'priceLowToHigh':
        filtered.sort((a, b) => parseFloat(a.offer_price || a.product_price) - parseFloat(b.offer_price || b.product_price));
        break;
      default:
        break;
    }
  }

  console.log("Products after sorting:", filtered.length);
  filtered.forEach((product) => {
    console.log(`Product ID: ${product.product_id}, Name: ${product.product_name}, Price: ${product.offer_price || product.product_price}`);
  });

  setNewFilteredProducts(filtered);
};





const handleNewFilterChange = (storeId, isChecked) => {
    console.log(`Filter changed for store ID: ${storeId}, Checked: ${isChecked}`);
    
    setTempSelectedFilters((prev) => {
        return {
            ...prev,
            [storeId]: isChecked,
        };
    });
};


const applyFiltersFromModal = () => {
    console.log("Applying filters from modal:", tempSelectedFilters);

    // Set the selected filters to the temporary filters
    setNewSelectedFilters(tempSelectedFilters);

    // Apply the filters to update the filtered products
    fetchProducts(searchTerm, 1, tempSelectedFilters, sortOption);

    // Close the modal
    setNewFilterOpen(false);
};








const handleSortChange = (option) => {
    console.log(`Sort option selected: ${option}`);
    setSortOption(option);
    
    // Apply sort to all currently fetched products
    setFilteredProducts(applySortToProducts(allProducts, option));
    
    // Reset pagination and fetch sorted products
    const newPage = 1;
    fetchProducts(searchTerm, newPage, newSelectedFilters, option);
    setPage(newPage);
};




const getSelectedFiltersCount = () => {
  return Object.values(newSelectedFilters).filter(isSelected => isSelected).length;
};





  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: loaderAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  
const fetchProducts = async (searchTerm = '', currentPage = 1, filters = {}, sort = '') => {
    try {
        setIsFetchingMore(true);
        setIsInitialLoad(currentPage === 1);
        let queryString = `?search=${encodeURIComponent(searchTerm)}&page=${currentPage}`;

        // Include filters in the query string
        if (Object.keys(filters).length > 0) {
            const activeStoreFilters = Object.entries(filters)
                .filter(([_, isActive]) => isActive)
                .map(([storeId]) => storeId);
            if (activeStoreFilters.length > 0) {
                queryString += `&store_ids=${encodeURIComponent(activeStoreFilters.join(','))}`;
            }
        }

        // Include sort option in the query string
        if (sort) {
            queryString += `&sort_by=${encodeURIComponent(sort)}`;
        }

       const response = await fetch(getApiUrl(ENDPOINTS.AMAZON_API, queryString));

        const data = await response.json();

        if (data.message === "No products found" || !data.amazondata || data.amazondata.length === 0) {
            setNoMoreProducts(true);
            if (currentPage === 1) {
                setAllProducts([]); // Reset all products
                setProducts([]);
                setFilteredProducts([]);
            }
            setHasMore(false);
        } else {
            const newProducts = data.amazondata;

            // Update allProducts state
            if (currentPage === 1) {
                setAllProducts(newProducts);
                setProducts(newProducts);
                setFilteredProducts(applySortToProducts(newProducts, sort));
                setDisplayedProductCount(newProducts.length);
                if (data.bannerdata && Array.isArray(data.bannerdata)) {
                    setBanners(data.bannerdata);
                }
            } else {
                const updatedAllProducts = [...allProducts, ...newProducts];
                setAllProducts(updatedAllProducts);
                setProducts(prevProducts => [...prevProducts, ...newProducts]);
                setFilteredProducts(applySortToProducts(updatedAllProducts, sort));
                setDisplayedProductCount(prev => prev + newProducts.length);
            }

            setTotalProducts(prev => prev + newProducts.length);
            setHasMore(newProducts.length > 0);
            setPage(currentPage);
            setNoMoreProducts(false);
        }
    } catch (error) {
        console.error('Error fetching products:', error);
        setHasMore(false);
        setNoMoreProducts(true);
    } finally {
        setIsFetchingMore(false);
        setIsInitialLoad(false);
    }
};



const applySortToProducts = (products, sortOption) => {
    if (!sortOption || products.length === 0) return products;

    const sortedProducts = [...products];
    
    switch (sortOption) {
        case 'popularity':
            return sortedProducts.sort((a, b) => 
                (b.sales_rank || 0) - (a.sales_rank || 0)
            );
            
        case 'latest':
            return sortedProducts.sort((a, b) => 
                new Date(b.creation_date || 0) - new Date(a.creation_date || 0)
            );
            
        case 'priceHighToLow':
            return sortedProducts.sort((a, b) => {
                const priceA = parseFloat(b.offer_price || b.product_price) || 0;
                const priceB = parseFloat(a.offer_price || a.product_price) || 0;
                return priceA - priceB;
            });
            
        case 'priceLowToHigh':
            return sortedProducts.sort((a, b) => {
                const priceA = parseFloat(a.offer_price || a.product_price) || 0;
                const priceB = parseFloat(b.offer_price || b.product_price) || 0;
                return priceA - priceB;
            });
            
        default:
            return sortedProducts;
    }
};





const applyFiltersAndSort = (filters = newSelectedFilters, sort = sortOption) => {
    let filtered = [...products];

    // Apply Filtering by Categories
    const activeStoreFilters = Object.entries(filters)
        .filter(([_, isActive]) => isActive)
        .map(([storeId]) => storeId);

    if (activeStoreFilters.length > 0) {
        console.log(`Filtering products by store IDs: ${activeStoreFilters}`);
        filtered = filtered.filter((product) => activeStoreFilters.includes(product.store_id.toString()));
    }

    console.log("Products after filtering:", filtered.length);
    filtered.forEach((product) => {
        console.log(`Product ID: ${product.product_id}, Name: ${product.product_name}, Store ID: ${product.store_id}`);
    });

    // Apply Sorting
    if (sort) {
        console.log(`Sorting products based on option: ${sort}`);
        switch (sort) {
            case 'popularity':
                filtered.sort((a, b) => b.sales_rank - a.sales_rank); // Assuming `sales_rank` defines popularity
                break;
            case 'latest':
                filtered.sort((a, b) => new Date(b.creation_date) - new Date(a.creation_date));
                break;
            case 'priceHighToLow':
                filtered.sort((a, b) => parseFloat(b.offer_price || b.product_price) - parseFloat(a.offer_price || a.product_price));
                break;
            case 'priceLowToHigh':
                filtered.sort((a, b) => parseFloat(a.offer_price || a.product_price) - parseFloat(b.offer_price || b.product_price));
                break;
            default:
                break;
        }
    }

    console.log("Products after sorting:", filtered.length);
    filtered.forEach((product) => {
        console.log(`Product ID: ${product.product_id}, Name: ${product.product_name}, Price: ${product.offer_price || product.product_price}`);
    });

    // Update the filtered products state
    setNewFilteredProducts(filtered);
};





		const handleFilterOpen = () => {
		  setNewFilterOpen(true); // Open the filter modal
		};


  
 /* const handleFilterOpen = () => {
    setFilterOpen(true);
  }; */

		const handleFilterClose = () => {
		  setNewFilterOpen(false); // Close the filter modal
		};
		  

  
  
let debounceTimeout;
const observer = new IntersectionObserver(
    entries => {
        if (entries[0].isIntersecting && hasMore && !isFetchingMore) {
            clearTimeout(debounceTimeout);
            debounceTimeout = setTimeout(() => fetchMoreProducts(), 500); // Debounce by 500ms
        }
    },
    { threshold: 0.5 }
);


useEffect(() => {
  const handleScroll = () => {
    if (window.innerHeight + window.scrollY >= document.documentElement.offsetHeight - 100 && hasMore && !isLoading) {
      fetchMoreProducts();
    }
  };

  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, [hasMore, isLoading]);



  return (
    <>
   <GlobalStyles
        styles={{
          '.focused': {
            width: '100% !important',
            transition: 'all 0.3s ease-in-out',
          },
        }}
      />
  
 





 <PageBackground maxWidth="lg">
  <StyledAppBar ref={appBarRef} className={clsx({ 'sticky-header': isSticky })}>
    <StyledToolbar>
      <StickySearchContainer className={clsx(isSearchFocused && 'focused')}>
        <SearchBarWrapper expanded={isSearchFocused}>
  <IconButton
    onClick={handleSearchFocus}
    sx={{ padding: 0.5 }}
    aria-label="search"
  >
    <SearchIcon sx={{ color: 'action.active', fontSize: '1.25rem' }} />
  </IconButton>
  <StyledSearchInput
    placeholder="Search products..."
    inputProps={{ 'aria-label': 'search' }}
    value={searchTerm}
    onChange={handleSearch}
    onFocus={handleSearchFocus}
	onKeyPress={(e) => {
					if (e.key === 'Enter') {
					  handleExternalSearch(e);
					}
				  }}
    ref={searchInputRef}
  />
  {isSearchFocused && (
    <IconButton
      onClick={handleClearSearch}
      sx={{ padding: 0.5 }}
      aria-label="clear search"
    >
      <ClearIcon sx={{ fontSize: '1.25rem' }} />
    </IconButton>
  )}
</SearchBarWrapper>

      {/* Conditionally hide Sort and Filter buttons when search is focused */}
      {!isSearchFocused && (
        <Box display="flex" gap={1}>
      <SortButton variant="contained" startIcon={<SortIcon />} onClick={handleSortOpen} style={{ position: 'sticky', top: '10px', zIndex: 1000 }}>
        Sort
     </SortButton>


			<FilterButton variant="contained" startIcon={<FilterListIcon />} onClick={handleFilterOpen}>
			  Filter
			  {getSelectedFiltersCount() > 0 && (
				<FilterCount>
				  {getSelectedFiltersCount()}
				</FilterCount>
			  )}
			</FilterButton>



        </Box>
      )}
   </StickySearchContainer>
    </StyledToolbar>
  </StyledAppBar>


   
       <ContentContainer>
      {!searchTerm && (
        <Box mb={4}>
          <Carousel>
            {banners.map((banner) => (
              <BannerImage
                key={banner.gallery_id}
                src={`https://appbkend.5topincity.com/vela2/public/uploads/galleryimg/${banner.gallery_image}`}
                alt={banner.gallery_name}
                onClick={() => window.open(banner.gallery_URL, '_blank')}
                style={{ cursor: 'pointer' }}
              />
            ))}
          </Carousel>
        </Box>
      )}
	  
	  
	   <div ref={productGridRef}>
	  
<ProductGridContainer>
  <Grid container spacing={2}>
    {(filteredProducts.length === 0 && (searchTerm !== '' || isFiltersApplied()) && !isInitialLoad) ? (
      <Box textAlign="center" width="100%" mt={4}>
    <NoResultsMessage variant="h6">
      No products found matching your criteria.
    </NoResultsMessage>
    {searchTerm !== '' && (
      <>
        <Typography variant="body1" mt={2}>
          Try searching on these platforms:
        </Typography>
        <Box display="flex" justifyContent="center" mt={2} mb={2}>
          {searchEngines.map((engine) => (
            <SearchEngineIcon
              key={engine.searchenginename}
              className={selectedEngines.some(e => e.searchenginename === engine.searchenginename) ? 'selected' : ''}
              onClick={() => handleEngineToggle(engine)}
            >
              <img src={`${IMAGE_BASE_URL}${engine.searchengineimage.replace('../', '')}`} 

                alt={engine.searchenginename}
              />
            </SearchEngineIcon>
          ))}
        </Box>
        <SearchButtonStyled
          variant="contained"
          onClick={(e) => handleExternalSearch({ type: 'click' })}
          disabled={selectedEngines.length === 0}
        >
          SEARCH
        </SearchButtonStyled>
      </>
    )}
  </Box>
    ) : (
      filteredProducts.map((product) => (
        <Grid item key={product.product_id} xs={6} sm={6} md={4}>
          <StyledCard>
            <CardActionArea onClick={() => window.open(product.product_url, '_blank')}>
              <StyledCardMedia
                component="img"
                image={product.product_image_url}
                alt={product.product_name}
              />
              <CardContent>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    fontWeight: 'bold',
                    color: '#333',
                    fontSize: '14px',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2, // Truncate to 2 lines
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {he.decode(product.product_name)}
                </Typography>
                {product.product_price && product.product_price !== product.offer_price && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ textDecoration: 'line-through', mt: 1 }}
                  >
                    ₹{product.product_price}
                  </Typography>
                )}
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 'bold', mt: 1, color: '#ff7043' }}
                >
                  ₹{product.offer_price}
                </Typography>
              </CardContent>
            </CardActionArea>
            {product.discount_percentage && (
              <DiscountBadge>
                {Math.round(product.discount_percentage)}% off
              </DiscountBadge>
            )}
          </StyledCard>
        </Grid>
      ))
    )}
  </Grid>
  {loading && hasMore && (
    <LoaderContainer>
      <Lottie 
        options={defaultOptions}
        height={100}
        width={100}
      />
    </LoaderContainer>
  )}

  {isFetchingMore && (
    <LoaderContainer>
      <Lottie options={loadMoreOptions} height={100} width={100} />
    </LoaderContainer>
  )}

  {hasMore && !isFetchingMore && hasReachedEndOfFirstPage && displayedProductCount >= 39 && (
    <div ref={loaderRef} style={{ height: '20px', margin: '20px 0' }} />
  )}

  {noMoreProducts && !isFetchingMore && searchTerm === '' && filteredProducts.length !== 0 && (
    <Typography 
      variant="body1" 
      align="center" 
      sx={{ my: 3, color: '#666' }}
    >
      No more products found
    </Typography>
  )}
</ProductGridContainer>

	  </div>
     </ContentContainer>



<Modal open={sortOpen} onClose={handleSortClose} closeAfterTransition BackdropProps={{ invisible: true }}>
  <Slide in={sortOpen} direction="up">
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: 'background.paper',
        p: 2,
        borderRadius: '12px 12px 0 0',
        boxShadow: 3,
        width: '100%',
        mb: 2, // Add 16px margin bottom
        '& .MuiButton-root': {
          mb: 1, // Add margin bottom to buttons
        },
        '& .MuiButton-root:last-child': {
          mb: 2, // Add extra margin to last button
        }
      }}
    >
      {/* Sort modal content remains the same */}
      <Typography variant="h6" gutterBottom>
        Sort By
      </Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        <Button
          startIcon={<TrendingUpIcon />}
          onClick={() => {
            handleSortChange('popularity');
            handleSortClose();
          }}
          sx={{
            justifyContent: 'flex-start',
            color: sortOption === 'popularity' ? '#ff7043' : '#000',
            textTransform: 'none',
            fontSize: '1rem',
          }}
        >
          Popularity
        </Button>
        <Button
          startIcon={<UpdateIcon />}
          onClick={() => {
            handleSortChange('latest');
            handleSortClose();
          }}
          sx={{
            justifyContent: 'flex-start',
            color: sortOption === 'latest' ? '#ff7043' : '#000',
            textTransform: 'none',
            fontSize: '1rem',
          }}
        >
          Latest
        </Button>
        <Button
          startIcon={<ArrowDownwardIcon />}
          onClick={() => {
            handleSortChange('priceHighToLow');
            handleSortClose();
          }}
          sx={{
            justifyContent: 'flex-start',
            color: sortOption === 'priceHighToLow' ? '#ff7043' : '#000',
            textTransform: 'none',
            fontSize: '1rem',
          }}
        >
          Price: High to Low
        </Button>
        <Button
          startIcon={<ArrowUpwardIcon />}
          onClick={() => {
            handleSortChange('priceLowToHigh');
            handleSortClose();
          }}
          sx={{
            justifyContent: 'flex-start',
            color: sortOption === 'priceLowToHigh' ? '#ff7043' : '#000',
            textTransform: 'none',
            fontSize: '1rem',
            marginBottom: '16px', // Add bottom margin to last button
          }}
        >
          Price: Low to High
        </Button>
      </Box>
    </Box>
  </Slide>
</Modal>


<Modal
  open={newFilterOpen}
  onClose={() => setNewFilterOpen(false)}
  closeAfterTransition
  BackdropProps={{ invisible: true }}
>
  <Slide in={newFilterOpen} direction="up">
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: 'background.paper',
        p: 2,
        borderRadius: '12px 12px 0 0',
        boxShadow: 3,
        width: '100%',
        mb: 2, // Add 16px margin bottom
        '& .MuiFormGroup-root': {
          mb: 2, // Add margin bottom to form group
        },
        '& .MuiFormControlLabel-root': {
          mb: 1, // Add margin bottom to form controls
        }
      }}
    >
      {/* Close Icon */}
      <IconButton
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: 'black',
        }}
        onClick={() => setNewFilterOpen(false)}
      >
        <CloseIcon />
      </IconButton>

      <Typography variant="h6" gutterBottom>
        Filters
      </Typography>
      <FormGroup>
        {newFilterCategories.length > 0 ? (
          newFilterCategories.map((category) => (
            <FormControlLabel
              key={category.store_id}
              control={
                <Checkbox
                  checked={tempSelectedFilters[category.store_id] || false}
                  onChange={(e) =>
                    handleNewFilterChange(category.store_id.toString(), e.target.checked)
                  }
                />
              }
              label={
                <Box display="flex" alignItems="center">
                  {category.store_icon_url && (
                    <img
                      src={category.store_icon_url}
                      alt={category.store_name}
                      style={{ width: 24, height: 24, marginRight: 8 }}
                    />
                  )}
                  {category.store_name}
                </Box>
              }
            />
          ))
        ) : (
          <Typography variant="body2">Loading filters...</Typography>
        )}
      </FormGroup>
      <Box display="flex" justifyContent="space-between" mt={2} mb={2}> {/* Added margin bottom */}
        <Button onClick={handleNewFilterReset}>CLEAR ALL</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={applyFiltersFromModal}
        >
          APPLY
        </Button>
      </Box>
    </Box>
  </Slide>
</Modal>






<FooterComponent />   
</PageBackground>
    </>
  );
}

export default ProductsPage;