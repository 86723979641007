import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Container, Grid, Typography, Card, CardContent, CardMedia, CircularProgress, Box, TextField, InputAdornment, IconButton, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { openDB } from 'idb'; // Import idb library for IndexedDB interaction
import { Tooltip } from '@mui/material';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import PushPinIcon from '@mui/icons-material/PushPin';
import ShareIcon from '@mui/icons-material/Share';
import { CardActionArea } from '@mui/material';
import { styled } from '@mui/material/styles';
import he from 'he';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Rating } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Snackbar, Alert } from '@mui/material';
import { Chip } from '@mui/material';
import { PushPin as PushPinFilledIcon } from '@mui/icons-material'; // Updated Icons
import FooterComponent from './FooterComponent';
import { v4 as uuidv4 } from 'uuid';
import {
  colors
} from './theme-styles';
import { API_ENDPOINTS } from './config/apiConfigext';




// Component for HomePage
const HomePage = () => {
  const [webappProducts, setWebappProducts] = useState([]);
  const [amazonProducts, setAmazonProducts] = useState([]);
  const [collectionProducts, setCollectionProducts] = useState([]);
  const [popularLinks, setPopularLinks] = useState([]);
  const [categoryLinks, setCategoryLinks] = useState([]);
  const [searchEngines, setSearchEngines] = useState([]);
  const [pinnedCollections, setPinnedCollections] = useState([]); // State for pinned collections
  const [pinnedBusinesses, setPinnedBusinesses] = useState([]); // State for pinned businesses
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [showAllPopularLinks, setShowAllPopularLinks] = useState(false);
  const [headerBanner, setHeaderBanner] = useState([]);
  const [footerBanner, setFooterBanner] = useState([]);
  const [likedCollections, setLikedCollections] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [featuredBusinesses, setFeaturedBusinesses] = useState([]);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [likedItems, setLikedItems] = useState({});
  const [businessSearchResults, setBusinessSearchResults] = useState([]);
  const [collectionsData, setCollectionsData] = useState([]); // Assuming this data is fetched from collectionsapi.php
  const [isSearching, setIsSearching] = useState(false);
  const [showClearButton, setShowClearButton] = useState(false);
  const [searchEngineIcon, setSearchEngineIcon] = useState(null);
  const [selectedEngines, setSelectedEngines] = useState([]);
  const [showEngines, setShowEngines] = useState(false);
  const [showBanner, setShowBanner] = useState(true);




  // Define state for like counts (stores the count of likes for each collection)
  const [likeCounts, setLikeCounts] = useState({});
  
  const [searchResults, setSearchResults] = useState({
  businesses: [],
  collections: [],
  amazonProducts: [],
  categoryLinks: [],
  popularLinks: []
});


const generateUserId = () => {
  return 'user_' + Math.random().toString(36).substr(2, 9);
};

  // Handler to toggle "View More" and show all popular links
  
const initializeDatabase = async () => {
  try {
    const db = await openDB('collectionsDB', 3, {
      upgrade(db) {
        // Likes store for local user state
        if (!db.objectStoreNames.contains('likes')) {
          const likeStore = db.createObjectStore('likes', { keyPath: 'id' });
          likeStore.createIndex('byCollection', 'collectionId');
          likeStore.createIndex('byUser', 'userId');
        }

        // Store for cached global counts
        if (!db.objectStoreNames.contains('globalCounts')) {
          db.createObjectStore('globalCounts', { keyPath: 'collectionId' });
        }

        // Users store
        if (!db.objectStoreNames.contains('users')) {
          db.createObjectStore('users', { keyPath: 'userId' });
        }
		
		if (!db.objectStoreNames.contains('pins')) {
          console.log('Creating the pins object store...');
          db.createObjectStore('pins', { keyPath: 'dataId' });
        }
      }
    });

    // Initialize user
    const userId = await ensureUserExists(db);
    
    // Fetch initial global counts
    await refreshGlobalCounts();
    
    return db;
  } catch (error) {
    console.error('Database initialization error:', error);
    throw error;
  }
};

const fetchGlobalCounts = async () => {
  try {
    const response = await axios.get(API_ENDPOINTS.GET_COUNTS);
    return response.data.counts || {};
  } catch (error) {
    console.error('Error fetching global counts:', error);
    return {};
  }
};

const updateGlobalCount = async (collectionId, increment) => {
  try {
     const response = await axios.post(API_ENDPOINTS.UPDATE_COUNT, {
      collectionId,
      increment: increment ? 1 : -1,
    });
    return response.data.newCount;
  } catch (error) {
    console.error('Error updating global count:', error);
    throw error;
  }
};

const refreshGlobalCounts = async () => {
  try {
    const counts = await fetchGlobalCounts();
    const db = await openDB('collectionsDB', 3);
    
    // Update IndexedDB cache
    const tx = db.transaction('globalCounts', 'readwrite');
    const store = tx.objectStore('globalCounts');
    
    for (const [collectionId, count] of Object.entries(counts)) {
      await store.put({
        collectionId,
        count,
        lastUpdated: new Date().toISOString()
      });
    }
    
    return counts;
  } catch (error) {
    console.error('Error refreshing global counts:', error);
    return {};
  }
};

const handleSearchButtonClick = () => {
  if (searchQuery.trim()) {
    const selectedEngineUrls = searchEngines
      .filter(engine => engine.selected === 'true')
      .map(engine => `${engine.searchengineurl}${encodeURIComponent(searchQuery)}`)
      .join('/~');
      
    if (selectedEngineUrls) {
      window.open('collections:'+selectedEngineUrls, '_blank');
    }
  }
};


// 2. User Management
const ensureUserExists = async (db) => {
  const userStore = db.transaction('users', 'readwrite').objectStore('users');
  const existingUser = await userStore.get('current_user');
  
  if (!existingUser) {
    const userId = generateUserId();
    await userStore.put({
      userId: 'current_user',
      value: userId,
      createdAt: new Date().toISOString()
    });
    return userId;
  }
  
  return existingUser.value;
};

useEffect(() => {
  initializeDatabase();
}, []);


const initDB = async () => {
  try {
    const db = await openDB('collectionsDB', 3, {
      upgrade(db) {
        if (!db.objectStoreNames.contains('likes')) {
          db.createObjectStore('likes', { keyPath: 'collectionId' });
        }
      }
    });
    return db;
  } catch (error) {
    console.error('Error initializing IndexedDB:', error);
    return null;
  }
};



const fetchPinnedItems = async () => {
    try {
      const db = await openDB('collectionsDB', 3);
      const allPins = await db.getAll('pins');
      const collections = allPins.filter(pin => pin.type === 'collection');
      const businesses = allPins.filter(pin => pin.type === 'business');

      setPinnedCollections(collections);
      setPinnedBusinesses(businesses);
    } catch (error) {
      console.error('Error fetching pinned items:', error);
    }
  };
  
const handleBusinessPinClick = async (businessId) => {
  try {
    console.log(`Attempting to pin/unpin business with ID: ${businessId}`);

    // Open the database and create an object store if needed
    const db = await openDB('PinnedBusinesses', 3, {
      upgrade(db) {
        if (!db.objectStoreNames.contains('pins')) {
          db.createObjectStore('pins', { keyPath: 'id' });
        }
      },
    });

    const pinnedBusiness = await db.get('pins', businessId);

    if (pinnedBusiness) {
      // Remove from IndexedDB
      await db.delete('pins', businessId);

      // Update state by filtering out unpinned business
      setPinnedBusinesses((prevPinned) => prevPinned.filter((business) => business.listdata.listing_id !== businessId));

      setSnackbarMessage('Business unpinned successfully');
      setSnackbarSeverity('success');
    } else {
      // Add to IndexedDB
      await db.put('pins', {
        id: businessId,
      });

      // Fetch business details and add to pinned list
      try {
		   const url = `${API_ENDPOINTS.BUSINESS_DETAILS}?proid=${businessId}`;
        const response = await axios.get(url);
        if (response.data) {
          setPinnedBusinesses((prevPinned) => [...prevPinned, response.data]);
          setSnackbarMessage('Business pinned successfully');
          setSnackbarSeverity('success');
        }
      } catch (fetchError) {
        await db.delete('pins', businessId);
        throw new Error('Failed to fetch business details');
      }
    }

    setSnackbarOpen(true);
  } catch (error) {
    console.error('Error handling business pin click:', error);
    setSnackbarMessage('Error updating pin status');
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
  }
};


  
//console.log(featuredBusinesses);
  
useEffect(() => {
  const initializeAndFetchLikedCollections = async () => {
    try {
      // Initialize the IndexedDB
      const db = await openDB('collectionsDB', 3, {
        upgrade(db) {
          if (!db.objectStoreNames.contains('likes')) {
            db.createObjectStore('likes', { keyPath: 'id' }); // Ensure consistent keyPath name
          }
          if (!db.objectStoreNames.contains('globalCounts')) {
            db.createObjectStore('globalCounts', { keyPath: 'collectionId' });
          }
        },
      });

      // Fetch liked collections from IndexedDB
      const allLiked = await db.getAll('likes');
      const likedIds = allLiked.map((item) => item.id);
      setLikedItems(likedIds.reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, {}));

      console.log('Fetched liked collections on load:', likedIds);
      
      // Fetch like counts from IndexedDB
      const globalCounts = await db.getAll('globalCounts');
      setLikeCounts(globalCounts.reduce((acc, item) => {
        acc[item.collectionId] = item.count || 0;
        return acc;
      }, {}));
      
    } catch (error) {
      console.error('Error initializing database or fetching liked collections:', error);
    }
  };

  initializeAndFetchLikedCollections();
}, []);




useEffect(() => {
  const fetchLikedItemsFromDB = async () => {
    try {
      const db = await openDB('collectionsDB', 3);
      const likes = await db.getAll('likes');
      const newLikedItems = likes.reduce((acc, { dataId }) => {
        acc[dataId] = true;
        return acc;
      }, {});
      setLikedItems(newLikedItems);

      // Set initial like counts if stored
      const newLikeCounts = likes.reduce((acc, { dataId, count }) => {
        acc[dataId] = count || 0;
        return acc;
      }, {});
      setLikeCounts(newLikeCounts);
    } catch (error) {
      console.error('Error fetching liked items from IndexedDB:', error);
    }
  };

  fetchLikedItemsFromDB();
}, []);

useEffect(() => {
  const fetchLikedCollections = async () => {
    try {
      const db = await openDB('collectionsDB', 3);
      const allLikes = await db.getAll('likes');
      
      // Convert all liked items into a map of collectionId -> true
      const likedMap = allLikes.reduce((acc, like) => {
        acc[like.collectionId] = true; // Use 'collectionId' here as key to maintain consistency
        return acc;
      }, {});

      setLikedItems(likedMap); // Update liked items state in HomePage
      console.log('Loaded liked items from IndexedDB:', likedMap);
    } catch (error) {
      console.error('Error fetching liked items from IndexedDB:', error);
    }
  };

  fetchLikedCollections();
}, []);


useEffect(() => {
  const fetchFeaturedBusinesses = async () => {
    try {
      const listingIds = [15584, 37233, 35919, 37778, 3563, 37099];
      const featuredBusinessDetails = await Promise.all(
        listingIds.map(async (listingId) => {
          const response = await axios.get(`${API_ENDPOINTS.BUSINESS_DETAILS}?proid=${listingId}`);
          return response.data;
        })
      );
      setFeaturedBusinesses(featuredBusinessDetails);
    } catch (error) {
      console.error('Error fetching featured businesses:', error);
    }
  };
  fetchFeaturedBusinesses();
}, []);




const checkDatabaseState = async () => {
  try {
    const db = await openDB('collectionsDB', 3);
    const transaction = db.transaction('likes', 'readonly');
    const store = transaction.objectStore('likes');
    const allLikes = await store.getAll();
    console.log('Current items in likes store:', allLikes);
  } catch (error) {
    console.error('Error checking database state:', error);
  }
};

useEffect(() => {
  checkDatabaseState();
}, [likedCollections]); // Trigger the check when likes change



  
 
  useEffect(() => {
    // Fetch products from Webapp, Amazon, and Collections
  

	
    const fetchProducts = async () => {
      try {
        // Fetch Amazon products from multiple pages
        let amazonProducts = [];
        let page = 1;
        let hasMoreData = true;

        while (hasMoreData && amazonProducts.length < 10) {
          const amazonResponse = await axios.get(`${API_ENDPOINTS.AMAZON}?page=${page}`);
          if (amazonResponse.data.amazondata && amazonResponse.data.amazondata.length > 0) {
            amazonProducts = [...amazonProducts, ...amazonResponse.data.amazondata];
            page++;
          } else {
            hasMoreData = false;
          }
        }
        setAmazonProducts(amazonProducts.slice(0, 10));
		
		   const response = await axios.get(API_ENDPOINTS.WEB_APPS);

		  // Set banner data
		  setHeaderBanner(response.data.header_banner);
		  setFooterBanner(response.data.footer_banner);

        const collectionResponse = await axios.get(API_ENDPOINTS.COLLECTIONS);
        const popularLinksResponse = await axios.get(API_ENDPOINTS.WEB_APPS);
        
        setCollectionProducts(Array.isArray(collectionResponse.data.collectionsdata) ? collectionResponse.data.collectionsdata : []);
        setPopularLinks(Array.isArray(popularLinksResponse.data.popular_links) ? popularLinksResponse.data.popular_links : []);

        // Extract search engine data
        if (popularLinksResponse.data.searchengine && popularLinksResponse.data.searchengine.searchenginedata) {
          setSearchEngines(popularLinksResponse.data.searchengine.searchenginedata);
        }

        // Extract category links and show three items from each unique category_id
        if (popularLinksResponse.data.linkdata) {
          const formattedCategoryLinks = popularLinksResponse.data.linkdata.map((category) => {
            return {
			  category_id: category.category_id,
              category_name: category.category_name,
              category_image: category.category_image,
              links: category.links.slice(0, 3),
            };
          });
          setCategoryLinks(formattedCategoryLinks);
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false);
      }
    };

  const fetchPinnedCollections = async () => {
    try {
      const db = await openDB('collectionsDB', 3); // Use version 3
      const allPinned = await db.getAll('pins');
      setPinnedCollections(allPinned);
    } catch (error) {
      console.error('Error fetching pinned collections:', error);
    }
  };

const fetchPinnedBusinesses = async () => {
  try {
    const db = await openDB('PinnedBusinesses', 3, {
      upgrade(db) {
        if (!db.objectStoreNames.contains('pins')) {
          db.createObjectStore('pins', { keyPath: 'id' });
        }
      },
    });
    
    const allPinnedBusinesses = await db.getAll('pins');
    const businessDetails = await Promise.all(
      allPinnedBusinesses.map(async (business) => {
       const response = await axios.get(`${API_ENDPOINTS.BUSINESS_DETAILS}?proid=${business.id}`);
        return response.data;
      })
    );
    setPinnedBusinesses(businessDetails);
  } catch (error) {
    console.error('Error fetching pinned businesses:', error);
  }
};

    fetchProducts();
    fetchPinnedCollections();
    fetchPinnedBusinesses();
  }, []);
  
  useEffect(() => {
 const fetchLikedCollections = async () => {
    try {
      const db = await openDB('collectionsDB', 3); // Use version 3
      const allLiked = await db.getAll('likes');
      const likedIds = allLiked.map((item) => item.dataId);
      setLikedCollections(likedIds);
    } catch (error) {
      console.error('Error fetching liked collections:', error);
    }
  };
  fetchLikedCollections();
}, []);


  const handleSearchEngineClick = (index) => {
    setSearchEngines((prevEngines) =>
      prevEngines.map((engine, i) => {
        if (i === index) {
          return { ...engine, selected: engine.selected === 'true' ? 'false' : 'true' };
        }
        return engine;
      })
    );
  };

const handleSearchSubmit = (e) => {
  if (e.key === 'Enter' && searchQuery.trim() !== "") {
    const selectedEngines = searchEngines.filter((engine) => engine.selected === 'true');
    if (selectedEngines.length > 0) {
      const combinedUrls = selectedEngines.map((engine) => {
        return `${engine.searchengineurl}${encodeURIComponent(searchQuery)}`;
      }).join('/~');

      const finalUrl = `collections:${combinedUrls}`;
      console.log(finalUrl); // Debugging: check the final URL in console

      // Open the combined URL in a new tab
      window.open(finalUrl, '_blank');
    }
  }
};


const handleLike = async (collectionId, event) => {
  event.stopPropagation();
  
  try {
    const db = await openDB('collectionsDB', 3);
    
    // First, ensure we have a userId
    const userStore = db.transaction('users', 'readwrite').objectStore('users');
    let userRecord = await userStore.get('current_user');
    
    // If no user exists, create one
    if (!userRecord) {
      const newUserId = generateUserId();
      await userStore.put({
        userId: 'current_user',
        value: newUserId,
        createdAt: new Date().toISOString()
      });
      userRecord = { value: newUserId };
    }

    const userId = userRecord.value;
    const likeId = `${userId}_${collectionId}`;

    // Optimistic UI update
    const isCurrentlyLiked = likedItems[collectionId];
    setLikedItems(prev => ({
      ...prev,
      [collectionId]: !isCurrentlyLiked
    }));
    
    setLikeCounts(prev => ({
      ...prev,
      [collectionId]: Math.max(0, (prev[collectionId] || 0) + (!isCurrentlyLiked ? 1 : -1))
    }));

    // Backend update
    const response = await axios.post(`${API_ENDPOINTS.UPDATE_COUNT}`, {
      collectionId,
      increment: !isCurrentlyLiked
    });

    if (!response.data.success) {
      throw new Error(response.data.error || 'Update failed');
    }

    // Update local storage
    const tx = db.transaction('likes', 'readwrite');
    const store = tx.objectStore('likes');

    if (!isCurrentlyLiked) {
      await store.put({
        id: likeId,
        userId,
        collectionId,
        timestamp: new Date().toISOString()
      });
    } else {
      await store.delete(likeId);
    }

    setSnackbarMessage(!isCurrentlyLiked ? 'Collection liked' : 'Collection unliked');
    setSnackbarSeverity('success');

  } catch (error) {
    console.error('Like error:', error);
    
    // Revert optimistic update
    setLikedItems(prev => ({
      ...prev,
      [collectionId]: likedItems[collectionId]
    }));
    
    setLikeCounts(prev => ({
      ...prev,
      [collectionId]: Math.max(0, prev[collectionId])
    }));
    
    setSnackbarMessage('Error updating like');
    setSnackbarSeverity('error');
  } finally {
    setSnackbarOpen(true);
  }
};


useEffect(() => {
  const loadInitialState = async () => {
    try {
      const db = await openDB('collectionsDB', 3);
      const userId = await ensureUserExists(db);
      
      // Load user's liked items
      const likeStore = db.transaction('likes', 'readonly').objectStore('likes');
      const userLikes = await likeStore.index('byUser').getAll(userId);
      
      const likedState = userLikes.reduce((acc, like) => {
        acc[like.collectionId] = true;
        return acc;
      }, {});
      
      setLikedItems(likedState);
      
      // Load global counts
      const counts = await refreshGlobalCounts();
      setLikeCounts(counts);
    } catch (error) {
      console.error('Error loading initial state:', error);
    }
  };

  // Load initial state
  loadInitialState();

  // Set up periodic refresh
  const refreshInterval = setInterval(async () => {
    const counts = await refreshGlobalCounts();
    setLikeCounts(counts);
  }, 30000); // Refresh every 30 seconds

  return () => clearInterval(refreshInterval);
}, []);



useEffect(() => {
  const loadLikesData = async () => {
    try {
      const db = await openDB('collectionsDB', 3);
      
      // Get current user
      const userStore = db.transaction('users', 'readonly').objectStore('users');
      const userRecord = await userStore.get('current_user');
      const userId = userRecord?.value;

      if (!userId) return;

      // Get all likes
      const likeStore = db.transaction('likes', 'readonly').objectStore('likes');
      const allLikes = await likeStore.getAll();

      // Calculate liked status for current user
      const userLikes = {};
      const likeCounts = {};

      allLikes.forEach(like => {
        // Track user's likes
        if (like.userId === userId) {
          userLikes[like.collectionId] = true;
        }
        
        // Count total likes per collection
        if (!likeCounts[like.collectionId]) {
          likeCounts[like.collectionId] = 0;
        }
        likeCounts[like.collectionId]++;
      });

      setLikedItems(userLikes);
      setLikeCounts(likeCounts);

    } catch (error) {
      console.error('Error loading likes data:', error);
    }
  };

  loadLikesData();
}, []);

useEffect(() => {
  const loadLikeCounts = async () => {
    try {
      // Open the database
      const db = await openDB('collectionsDB', 3);

      // Retrieve current user
      const userStore = db.transaction('users', 'readonly').objectStore('users');
      const userRecord = await userStore.get('current_user');
      const userId = userRecord?.value;

      // Exit if no user ID is found
      if (!userId) return;

      // Retrieve all likes by the current user
      const likeStore = db.transaction('likes', 'readonly').objectStore('likes');
      const userLikes = await likeStore.index('byUser').getAll(userId);

      // Map user's liked items for quick lookup
      const userLikedItems = userLikes.reduce((acc, like) => {
        acc[like.collectionId] = true;
        return acc;
      }, {});

      // Retrieve all like counts
      const countStore = db.transaction('globalCounts', 'readonly').objectStore('globalCounts');
      const allCounts = await countStore.getAll();

      // Map all like counts
      const counts = allCounts.reduce((acc, item) => {
        acc[item.collectionId] = item.count;
        return acc;
      }, {});

      // Update React state with the fetched data
      setLikedItems(userLikedItems);
      setLikeCounts(counts);

    } catch (error) {
      console.error('Error loading like counts:', error);
    }
  };

  loadLikeCounts();
}, []);



useEffect(() => {
  const handleLikeUpdate = (event) => {
    const { collectionId, isLiked } = event.detail;

    setLikedItems((prev) => ({
      ...prev,
      [collectionId]: isLiked,
    }));
  };

  window.addEventListener('likeUpdated', handleLikeUpdate);

  return () => {
    window.removeEventListener('likeUpdated', handleLikeUpdate);
  };
}, []);

  
// Helper function to broadcast like events
const broadcastLikeUpdate = (collectionId, isLiked) => {
  const event = new CustomEvent('likeUpdated', {
    detail: { collectionId, isLiked },
  });
  window.dispatchEvent(event);
};

const handleLikeClick = async (collectionId) => {
  try {
    const db = await openDB('collectionsDB', 3);
    
    // First, ensure we have a userId
    const userId = 'current_user'; // Replace with actual user ID if available
    const likeId = `${userId}_${collectionId}`; // Use this as the unique identifier
    
    const isCurrentlyLiked = likedItems[collectionId];
    
    // Optimistic UI update
    setLikedItems((prev) => ({
      ...prev,
      [collectionId]: !isCurrentlyLiked,
    }));
    setLikeCounts((prev) => ({
      ...prev,
      [collectionId]: (prev[collectionId] || 0) + (!isCurrentlyLiked ? 1 : -1),
    }));
    
    if (!isCurrentlyLiked) {
      // If not liked, add to IndexedDB
      await db.put('likes', { dataId: collectionId, userId, timestamp: new Date().toISOString() });
      console.log(`Liked collection with ID: ${collectionId}`);
    } else {
      // If liked, remove from IndexedDB
      await db.delete('likes', likeId);
      console.log(`Unliked collection with ID: ${collectionId}`);
    }

    // Broadcast the like update to ensure all components are synced
    broadcastLikeUpdate(collectionId, !isCurrentlyLiked);

  } catch (error) {
    console.error('Error updating like in IndexedDB:', error);
    
    // Revert UI change in case of error
    setLikedItems((prev) => ({
      ...prev,
      [collectionId]: likedItems[collectionId],
    }));
    setLikeCounts((prev) => ({
      ...prev,
      [collectionId]: Math.max(0, prev[collectionId]),
    }));
  }
};










		// Conditional Rendering for Like Icon
		const isLiked = (collectionId) => likedCollections.includes(collectionId);
		const isPinned = (collectionId) => pinnedCollections.some((item) => item.dataId === collectionId);
		const isBusinessPinned = (businessId) => 
			  pinnedBusinesses.some((business) => 
				business.listdata.listing_id === businessId
			  );





		const handleShareClick = (collectionId) => {
		  if (navigator.share) {
			navigator.share({
			  title: 'Check out this collection!',
			  text: 'Take a look at this amazing collection!',
			  url: `https://yourapp.com/collections/${collectionId}`,
			})
			.then(() => console.log('Collection shared successfully'))
			.catch((error) => console.error('Error sharing collection:', error));
		  } else {
			console.log(`Shared collection with ID: ${collectionId}`);
			alert('Your browser does not support the Web Share API. Please share manually.');
		  }
		};

const handlePinClick = async (collectionId) => {
  try {
    console.log(`Attempting to pin/unpin collection with ID: ${collectionId}`);

    const db = await openDB('collectionsDB', 3, {
      upgrade(db) {
        if (!db.objectStoreNames.contains('pins')) {
          console.log('Creating the pins object store...');
          db.createObjectStore('pins', { keyPath: 'dataId' });
        }
      },
    });

    if (!db.objectStoreNames.contains('pins')) {
      throw new Error("'pins' object store not found even after initialization");
    }

    const pinnedCollection = await db.get('pins', collectionId);
    if (pinnedCollection) {
      await db.delete('pins', collectionId);
      setPinnedCollections((prevPinned) => prevPinned.filter((item) => item.dataId !== collectionId));
      setSnackbarMessage('Item unpinned successfully');
    } else {
      await db.put('pins', { dataId: collectionId });
      setPinnedCollections((prevPinned) => [...prevPinned, { dataId: collectionId }]);
      setSnackbarMessage('Item pinned successfully');
    }

    setSnackbarOpen(true);
  } catch (error) {
    console.error('Error handling pin click:', error);
  }
};



const handleSearchChange = async (e) => {
   const value = e?.target?.value?.toLowerCase() || '';
  setSearchQuery(value);
  setShowBanner(!value); // Hide banner when searching
  setShowEngines(value.trim() !== '');
  setShowClearButton(value !== "");

  if (value === "") {
    setSearchResults({
      businesses: [],
      collections: [],
      amazonProducts: [],
      categoryLinks: [],
      popularLinks: [],
      favoriteCollections: []
    });
    setShowClearButton(false);
    return;
  }

  setIsSearching(true);

  try {
    // Fetch data from different endpoints
    const [amazonResponse, collectionsResponse, categoryLinksResponse, popularLinksResponse] = await Promise.all([
      axios.get(`${API_ENDPOINTS.AMAZON}?search=${encodeURIComponent(value)}&page=1`),
      axios.get(`${API_ENDPOINTS.COLLECTIONS}?filterCategory=&searchTerm=${encodeURIComponent(value)}`),
      axios.get(`${API_ENDPOINTS.WEB_APPS}?search=${encodeURIComponent(value)}`),
      axios.get(`${API_ENDPOINTS.WEB_APPS}`)
    ]);

    // Fetch favorite collections from IndexedDB
    const db = await openDB('collectionsDB', 3);
    const pinnedItems = await db.getAll('pins');
    const pinnedCollectionsIds = pinnedItems.map(item => item.dataId);

    // Map pinned collections using data from collections API
    const favoriteCollections = collectionsResponse?.data?.collectionsdata.filter(collection =>
      pinnedCollectionsIds.includes(collection.data_id)
    );

    // Apply search filter to favorite collections based on title or description
    const filteredFavoriteCollections = favoriteCollections.filter(collection =>
      collection?.title?.toLowerCase().includes(value) ||
      collection?.description?.toLowerCase().includes(value)
    );

    // Filter businesses using featured and pinned businesses
    const filteredBusinesses = [...featuredBusinesses, ...pinnedBusinesses].filter(business =>
      business?.listdata?.business_name?.toLowerCase()?.includes(value)
    );

    // Filter popular links
    const filteredPopularLinks = popularLinksResponse.data.popular_links.filter(link =>
      (link?.link_name?.toLowerCase()?.includes(value)) ||
      (link?.link_description?.toLowerCase()?.includes(value))
    );

    // Set results for the search
    setSearchResults({
      businesses: filteredBusinesses,
      collections: collectionsResponse?.data?.collectionsdata || [],
      amazonProducts: amazonResponse?.data?.amazondata || [],
      categoryLinks: categoryLinksResponse?.data?.linkdata || [],
      popularLinks: filteredPopularLinks,
      favoriteCollections: filteredFavoriteCollections
    });

  } catch (error) {
    console.error('Error fetching search results:', error);
    setSnackbarMessage('Error fetching search results');
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
    
    // Reset results on error
    setSearchResults({
      businesses: [],
      collections: [],
      amazonProducts: [],
      categoryLinks: [],
      popularLinks: [],
      favoriteCollections: []
    });
  } finally {
    setIsSearching(false);
  }
};




// Add this helper function for safe string searching
const safeStringIncludes = (text, searchTerm) => {
  try {
    return String(text).toLowerCase().includes(searchTerm.toLowerCase());
  } catch (error) {
    return false;
  }
};






const handleClearSearch = () => {
  setSearchQuery("");
  setBusinessSearchResults([]);
  setShowClearButton(false);
};








  // Filter top deals based on a specific property, e.g., discount or high rating
// Updated top deals logic to be more flexible
  const topDeals = amazonProducts
    .filter((product) => {
      const hasHighDiscount = product.is_discounted && parseFloat(product.discount_percentage) > 30;
      const hasHighRating = product.star_rating && parseFloat(product.star_rating) >= 4.0;
      const isGoodSalesRank = product.sales_rank && product.sales_rank < 100000;

      return hasHighDiscount || hasHighRating || isGoodSalesRank;
    })
    .sort((a, b) => {
      // Sort products with higher discount first, then by rating, then by sales rank
      if (parseFloat(b.discount_percentage) - parseFloat(a.discount_percentage) !== 0) {
        return parseFloat(b.discount_percentage) - parseFloat(a.discount_percentage);
      }
      if (parseFloat(b.star_rating) - parseFloat(a.star_rating) !== 0) {
        return parseFloat(b.star_rating) - parseFloat(a.star_rating);
      }
      return a.sales_rank - b.sales_rank;
    })
    .slice(0, 10);

// If no products match the criteria, use the first 10 Amazon products as fallback
const topDealsToShow = topDeals.length > 0 ? topDeals.slice(0, 10) : amazonProducts.slice(0, 10);
// Fallback to display top 10 products if none match the filter criteria

const handleViewMore = () => {
    setShowAllPopularLinks(!showAllPopularLinks);
   };
   
   const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',
  backgroundColor: '#ffffff',
  boxShadow: '0px 3px 15px rgba(0,0,0,0.1)',
  borderRadius: '12px',
  padding: theme.spacing(2),
  transition: '0.3s',
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: '0px 5px 20px rgba(0,0,0,0.2)',
  },
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: '150px',
  width: '100%',
  objectFit: 'contain',
  marginBottom: theme.spacing(1),
}));

const DiscountBadge = styled(Box)(({ theme }) => ({
  backgroundColor: '#f8f8f8',
  color: '#ff7043',
  padding: theme.spacing(0.5, 2),
  borderRadius: '24px',
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: '0.85rem',
  marginTop: theme.spacing(1),
  alignSelf: 'center',
}));

const TruncatedTypography = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2, // Limit to 2 lines
  WebkitBoxOrient: 'vertical',
});

const bannerSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};

const imageSliderSettings = {
 dots: false,
 infinite: false, // Changed from true
 speed: 500,
 slidesToShow: 1.05,
 slidesToScroll: 1,
 autoplay: false, // Changed from true
 autoplaySpeed: 3000,
 focusOnSelect: true,
 centerMode: true,
 centerPadding: '10px',
 arrows: true
};

const SearchEngineIcon = styled('div')(({ theme }) => ({
  width: 50,
  height: 50,
  borderRadius: '50%',
  cursor: 'pointer',
  margin: '0 10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.3s ease',
  backgroundColor: 'gray', // Unselected state
  '&.selected': {
    backgroundColor: 'red', // Selected state
  },
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '50%',
  },
}));

const handleEngineToggle = useCallback((engine) => {
  setSelectedEngines(prev => 
    prev.some(e => e.searchenginename === engine.searchenginename)
      ? prev.filter(e => e.searchenginename !== engine.searchenginename)
      : [...prev, engine]
  );
}, []);

const handleExternalSearch = useCallback((e) => {
  if (e.key === 'Enter' || e.type === 'click') {
    if (selectedEngines.length > 0 && searchQuery) {
      const encodedQuery = encodeURIComponent(searchQuery);
      const searchUrls = selectedEngines.map(engine => 
        `${engine.searchengineurl}${encodedQuery}`
      ).join('/~');

      if (searchUrls) {
        window.open(`collections:${searchUrls}`, '_blank');
      }
    }
  }
}, [selectedEngines, searchQuery]);

// Update your SearchButton styled component if not already defined
const SearchButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#007bff',
  color: 'white',
  '&:hover': {
    backgroundColor: '#0056b3',
  },
  '&:disabled': {
    backgroundColor: '#cccccc',
    cursor: 'not-allowed',
  },
}));

// Update the useEffect for fetching search engines
useEffect(() => {
  const fetchSearchEngines = async () => {
    try {
      const response = await fetch(`${API_ENDPOINTS.AMAZON}?page=1`);
      const data = await response.json();
      if (data.searchengine && data.searchengine.searchenginedata) {
        setSearchEngines(data.searchengine.searchenginedata);
        // Set default selected engines
        const defaultEngines = data.searchengine.searchenginedata.filter(engine => 
          engine.selected === "true"
        );
        setSelectedEngines(defaultEngines);
      }
    } catch (error) {
      console.error('Error fetching search engines:', error);
    }
  };

  fetchSearchEngines();
}, []);


const LikeButton = ({ collectionId }) => {
  	
	
  const isLiked = likedItems[collectionId]; // Updated to use the collectionId as key to check if liked
  const count = likeCounts[collectionId] || 0;
  //alert(collectionId+'--'+isLiked);
 // console.log(allLiked);

  return (
    <Box display="flex" alignItems="center" sx={{ marginLeft: '-10px' }}>
      <IconButton
        onClick={(e) => handleLike(collectionId, e)}
        style={{
          backgroundColor: isLiked ? '#ffcccb' : 'transparent',
          transition: 'background-color 0.3s ease',
        }}
      >
        {isLiked ? (
          <FavoriteIcon style={{ color: '#ff1744' }} />
        ) : (
          <FavoriteBorderIcon style={{ color: '#757575' }} />
        )}
      </IconButton>
      <Typography variant="caption" sx={{ marginLeft: 1 }}>
        {count} {count === 1 ? 'Like' : 'Likes'}
      </Typography>
    </Box>
  );
};



if (loading) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: 9999,
      }}
    >
      <CircularProgress />
    </Box>
  );
}


  return (
    <Container>
      {/* Search Bar */}
      <Box display="flex" justifyContent="center" alignItems="center" marginBottom={4}>
<TextField
  variant="outlined"
  placeholder="Search the web"
  value={searchQuery}
  onChange={handleSearchChange}
  onKeyDown={handleSearchSubmit} // Attach the search submit handler here
  InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        {isSearching ? (
          <CircularProgress size={20} />
        ) : showClearButton ? (
          <IconButton onClick={handleClearSearch}>
            <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>X</span>
          </IconButton>
        ) : (
          <IconButton>
            <SearchIcon />
          </IconButton>
        )}
      </InputAdornment>
    ),
  }}
  fullWidth
  style={{ maxWidth: '600px', marginBottom: '16px' }}
/>



      </Box>
	  








      {/* Display Search Engine Icons */}
		{/* Display Search Engine Icons */}
		<Box display="flex" flexDirection="column" alignItems="center" gap={2}>
  <Box display="flex" justifyContent="center" alignItems="center" marginBottom={2}>
    {searchEngines.map((engine, index) => (
      <Box
        key={index}
        textAlign="center"
        marginX={1}
        onClick={() => handleSearchEngineClick(index)}
        style={{
          backgroundColor: engine.selected === 'true' ? colors.accent2 : '#E8E8E8',
          borderRadius: '50%',
          padding: '5px',
          width: '50px',
          height: '50px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          transition: 'all 0.3s ease',
        }}
      >
        <img
          src={engine.searchengineimage}
          alt={engine.searchenginename}
          style={{
            width: 30,
            height: 30,
          }}
        />
      </Box>
    ))}
  </Box>

  {/* Search Button */}
    <Button
      variant="contained"
      onClick={handleSearchButtonClick}
      sx={{
        bgcolor: '#6B4EFF',
        color: 'white',
        textTransform: 'none',
        borderRadius: '20px',
        px: 4,
        py: 1,
        fontSize: '1rem',
        marginBottom: 2,
		
        '&:hover': {
          bgcolor: '#5B3FEF'
        }
      }}
    >
      SEARCH
    </Button>

</Box>

		
		{showBanner && headerBanner && headerBanner.length > 0 && (
  headerBanner.length === 1 ? (
    <div style={{ width: '100%' }}>
      <img
        src={headerBanner[0].gallery_image}
        alt={headerBanner[0].gallery_name}
        style={{ width: '100%', height: 'auto' }}
      />
    </div>
  ) : (
    <Slider {...bannerSettings}>
      {headerBanner.map((banner, index) => (
        <div key={index}>
          <img
            src={banner.gallery_image}
            alt={banner.gallery_name}
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
      ))}
    </Slider>
  )
)}

{searchQuery && (
  <Box sx={{ width: '100%', textAlign: 'center', my: 4 }}>
    {Object.values(searchResults).every(arr => arr.length === 0) ? (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, my: 8 }}>
        <Typography variant="h5" color="text.secondary">
          No results found for "{searchQuery}"
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Try searching with a different term
        </Typography>
      </Box>
    ) : (
      <Typography variant="h4" gutterBottom>
        Search Results
      </Typography>
    )}
  </Box>
)}

{/* Render Favorite Collections based on Search Query */}
{searchQuery && Array.isArray(searchResults.favoriteCollections) && searchResults.favoriteCollections.length > 0 && (
  <>
    <Typography variant="h5" gutterBottom style={{ marginTop: '2rem' }}>
      Favorite Collections 
    </Typography>
    <Grid container spacing={3}>
      {searchResults.favoriteCollections.map((collection, index) => (
        <Grid item xs={6} sm={6} md={4} key={index}>
          <Card
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 2,
              boxShadow: 3,
              transition: 'box-shadow 0.3s ease, transform 0.3s ease',
              '&:hover': {
                boxShadow: 6,
                transform: 'scale(1.02)',
              },
              cursor: 'pointer',
            }}
            onClick={() => {
              // Collect all URLs from item1 to item10
              const urls = [
                collection?.item1,
                collection?.item2,
                collection?.item3,
                collection?.item4,
                collection?.item5,
                collection?.item6,
                collection?.item7,
                collection?.item8,
                collection?.item9,
                collection?.item10,
              ];

              // Filter out null or undefined URLs
              const validUrls = urls.filter((url) => url !== null && url !== undefined);

              // Concatenate the valid URLs with the delimiter '~'
              const concatenatedUrl = validUrls.join('~');

              if (concatenatedUrl) {
                window.open(concatenatedUrl, '_blank');
              }
            }}
          >
            {/* User Information Section */}
            <Box display="flex" alignItems="center" padding="10px">
              <CardMedia
                component="img"
                image={collection?.image ? collection.image : 'https://via.placeholder.com/150'}
                alt={collection?.name || 'User'}
                sx={{
                  width: 40,
                  height: 40,
                  borderRadius: '50%',
                  marginRight: '20px',
                  border: '2px solid',
                  borderColor: 'primary.main',
                }}
              />
              <Typography variant="subtitle1" component="div">
                {collection?.name || 'User'}
              </Typography>
            </Box>

            {/* Featured Image */}
            <CardMedia
              component="img"
              image={collection?.featured_image || 'https://via.placeholder.com/300x140'}
              alt={collection?.title}
              sx={{
                height: { xs: 120, sm: 140 },
                transition: 'transform 0.3s ease',
                margin: 'auto',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            />

            {/* Card Content */}
            <CardContent
              sx={{
                padding: { xs: 1.5, sm: 2 },
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontSize: { xs: '1rem', sm: '1.1rem' },
                  fontWeight: 600,
                  lineHeight: 1.3,
                  color: 'text.primary',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  textOverflow: 'ellipsis',
                }}
              >
                {collection?.title || 'Pinned Collection'}
              </Typography>

              {/* Action Buttons */}
              <Box display="flex" alignItems="center" justifyContent="space-between" mt="auto">
                {/* Like Button */}
                <Tooltip title="Like">
                  <LikeButton collectionId={collection.data_id} />
                </Tooltip>

                {/* Share Button */}
                <Tooltip title="Share">
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation();
                      handleShareClick(collection?.data_id);
                    }}
                    sx={{
                      backgroundColor: 'rgba(0, 0, 0, 0.05)',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                        transform: 'scale(1.1)',
                      },
                      transition: 'all 0.3s ease-in-out',
                    }}
                  >
                    <ShareIcon sx={{ color: 'action.main' }} />
                  </IconButton>
                </Tooltip>

                {/* Pin Button */}
                <Tooltip title="Pin">
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation(); // Prevent Card click event
                      handlePinClick(collection?.data_id);
                    }}
                    style={{
                      backgroundColor: isPinned(collection?.data_id) ? 'rgba(255, 143, 0, 0.4)' : 'transparent',
                      transition: 'background-color 0.3s ease',
                    }}
                  >
                    {isPinned(collection?.data_id) ? (
                      <PushPinIcon sx={{ color: '#ff9100' }} />
                    ) : (
                      <PushPinOutlinedIcon sx={{ color: 'secondary.main' }} />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </>
)}

	  

{!searchQuery && pinnedCollections?.length > 0 && (
  <>
    <Typography variant="h5" gutterBottom>
      Favorite Collections
    </Typography>
    <Grid container spacing={3}>
      {pinnedCollections.map((collection, index) => {
        const matchingCollection = collectionProducts.find((prod) => prod.data_id === collection.dataId);
        return (
          <Grid item xs={6} sm={6} md={4} key={index}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 2,
                boxShadow: 3,
                transition: 'box-shadow 0.3s ease, transform 0.3s ease',
                '&:hover': {
                  boxShadow: 6,
                  transform: 'scale(1.02)',
                },
                cursor: 'pointer',
              }}
              onClick={() => {
                // Collect all URLs from item1 to item10
                const urls = [
                  matchingCollection?.item1,
                  matchingCollection?.item2,
                  matchingCollection?.item3,
                  matchingCollection?.item4,
                  matchingCollection?.item5,
                  matchingCollection?.item6,
                  matchingCollection?.item7,
                  matchingCollection?.item8,
                  matchingCollection?.item9,
                  matchingCollection?.item10,
                ];

                // Filter out null or undefined URLs
                const validUrls = urls.filter((url) => url !== null && url !== undefined);

                // Concatenate the valid URLs with the delimiter '~'
                const concatenatedUrl = validUrls.join('~');

                if (concatenatedUrl) {
                  window.open(concatenatedUrl, '_blank');
                }
              }}
            >
              {/* User Information Section */}
              <Box display="flex" alignItems="center" padding="10px">
                <CardMedia
                  component="img"
                  image={matchingCollection?.image ? matchingCollection.image : 'https://via.placeholder.com/150'}
                  alt={matchingCollection?.name || 'User'}
                  sx={{
                    width: 40,
                    height: 40,
                    borderRadius: '50%',
                    marginRight: '20px',
                    border: '2px solid',
                    borderColor: 'primary.main',
                  }}
                />
                <Typography variant="subtitle1" component="div">
                  {matchingCollection?.name || 'User'}
                </Typography>
              </Box>

              {/* Featured Image */}
              <CardMedia
                component="img"
                image={matchingCollection?.featured_image || 'https://via.placeholder.com/300x140'}
                alt={matchingCollection?.title}
                sx={{
                  height: { xs: 120, sm: 140 },
                  transition: 'transform 0.3s ease',
                  margin: 'auto',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              />

              {/* Card Content */}
              <CardContent
                sx={{
                  padding: { xs: 1.5, sm: 2 },
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontSize: { xs: '1rem', sm: '1.1rem' },
                    fontWeight: 600,
                    lineHeight: 1.3,
                    color: 'text.primary',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {matchingCollection?.title || 'Pinned Collection'}
                </Typography>

                {/* Action Buttons */}
                <Box display="flex" alignItems="center" justifyContent="space-between" mt="auto">
                  {/* Like Button */}
                  <Tooltip title="Like">
                    <LikeButton collectionId={matchingCollection.data_id} />
					  
                  </Tooltip>

                  {/* Share Button */}
                  <Tooltip title="Share">
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        handleShareClick(matchingCollection?.data_id);
                      }}
                      sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.05)',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.1)',
                          transform: 'scale(1.1)',
                        },
                        transition: 'all 0.3s ease-in-out',
                      }}
                    >
                      <ShareIcon sx={{ color: 'action.main' }} />
                    </IconButton>
                  </Tooltip>

                  {/* Pin Button */}
                  <Tooltip title="Pin">
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation(); // Prevent Card click event
                        handlePinClick(matchingCollection?.data_id);
                      }}
                      style={{
                        backgroundColor: isPinned(matchingCollection?.data_id) ? 'rgba(255, 143, 0, 0.4)' : 'transparent',
                        transition: 'background-color 0.3s ease',
                      }}
                    >
                      {isPinned(matchingCollection?.data_id) ? (
                        <PushPinIcon sx={{ color: '#ff9100' }} />
                      ) : (
                        <PushPinOutlinedIcon sx={{ color: 'secondary.main' }}
                      />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  </>
)}


{!searchQuery && (

<Box
  sx={{
    backgroundColor: '#f5e8e8', // Set background color for the container
    borderRadius: '10px',
    padding: 2,
    position: 'relative',
    marginBottom: 4,
    marginTop: 4,
  }}
>
  <Typography variant="h6" component="div" sx={{ fontWeight: 600, marginBottom: 1 }}>
    Popular
  </Typography>

  <Button
    variant="text"
    onClick={() => setIsExpanded(!isExpanded)} // Toggle isExpanded state on button click
    sx={{
      position: 'absolute',
      top: 10,
      right: 10,
      textTransform: 'none',
      color: '#8b5e5e',
      fontWeight: 600,
      fontSize: '0.9rem',
      '&:hover': {
        backgroundColor: 'transparent',
        color: '#5a3939',
      },
    }}
  >
    {isExpanded ? 'View Less' : 'View More'}
  </Button>

  <Grid container spacing={2} justifyContent="center">
    {/* Show 3 items initially, expand to show all when isExpanded is true */}
    {popularLinks.slice(0, isExpanded ? popularLinks.length : 3).map((link, index) => (
      <Grid item xs={4} sm={4} md={3} key={index} display="flex" justifyContent="center">
        <Box
          onClick={() => window.open(link.link_URL, '_blank')}
          sx={{
            cursor: 'pointer',
            textAlign: 'center',
            marginX: 1,
            '&:hover': {
              transform: 'scale(1.05)',
            },
            transition: 'transform 0.3s ease',
          }}
        >
          <CardMedia
            component="img"
            image={link.link_image ? link.link_image : 'https://via.placeholder.com/140'}
            alt={link.link_name}
            sx={{
              width: 60,
              height: 60,
              borderRadius: '50%',
              marginBottom: 1,
              border: '2px solid',
              borderColor: 'grey.300',
            }}
          />
          <Typography variant="body2" noWrap sx={{ maxWidth: 70 }}>
            {link.link_name.length > 10 ? `${link.link_name.substring(0, 10)}...` : link.link_name}
          </Typography>
        </Box>
      </Grid>
    ))}
  </Grid>
</Box>
)}


{searchQuery && (
  <Box sx={{ mt: 4, mb: 4 }}>
    {/* Popular Links Section with Error Handling */}
    {Array.isArray(searchResults?.popularLinks) && searchResults.popularLinks.length > 0 && (
      <Box
        sx={{
          backgroundColor: '#f5e8e8',
          borderRadius: '10px',
          padding: 2,
          position: 'relative',
          marginBottom: 4,
          marginTop: 4,
        }}
      >
        <Typography variant="h6" component="div" sx={{ fontWeight: 600, marginBottom: 1 }}>
          Popular
        </Typography>

        {/* View More/Less Button */}
        <Button
          variant="text"
          onClick={() => setIsExpanded(prev => !prev)}
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            textTransform: 'none',
            color: '#8b5e5e',
            fontWeight: 600,
            fontSize: '0.9rem',
            '&:hover': {
              backgroundColor: 'transparent',
              color: '#5a3939',
            },
          }}
        >
          {isExpanded ? 'View Less' : 'View More'}
        </Button>

        {/* Popular Links Grid */}
        <Grid container spacing={2} justifyContent="center">
          {searchResults.popularLinks
            .slice(0, isExpanded ? searchResults.popularLinks.length : 3)
            .map((link, index) => (
              <Grid 
                item 
                xs={4} 
                sm={4} 
                md={3} 
                key={link?.id || index} 
                display="flex" 
                justifyContent="center"
              >
                <Box
                  onClick={() => {
                    if (link?.link_URL) {
                      window.open(link.link_URL, '_blank');
                    }
                  }}
                  sx={{
                    cursor: link?.link_URL ? 'pointer' : 'default',
                    textAlign: 'center',
                    marginX: 1,
                    '&:hover': {
                      transform: link?.link_URL ? 'scale(1.05)' : 'none',
                    },
                    transition: 'transform 0.3s ease',
                  }}
                >
                  <CardMedia
                    component="img"
                    image={link?.link_image || 'https://via.placeholder.com/140'}
                    alt={link?.link_name || 'Link image'}
                    sx={{
                      width: 60,
                      height: 60,
                      borderRadius: '50%',
                      marginBottom: 1,
                      border: '2px solid',
                      borderColor: 'grey.300',
                    }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = 'https://via.placeholder.com/140';
                    }}
                  />
                  <Typography 
                    variant="body2" 
                    noWrap 
                    sx={{ 
                      maxWidth: 70,
                      color: link?.link_URL ? 'inherit' : 'text.disabled'
                    }}
                  >
                    {link?.link_name 
                      ? (link.link_name.length > 10 
                          ? `${link.link_name.substring(0, 10)}...` 
                          : link.link_name)
                      : 'Untitled'}
                  </Typography>
                </Box>
              </Grid>
            ))}
        </Grid>

        {/* Error Message for Invalid Links */}
        {searchResults.popularLinks.some(link => !link?.link_URL) && (
          <Typography 
            variant="caption" 
            color="error" 
            sx={{ 
              display: 'block', 
              textAlign: 'center',
              mt: 2 
            }}
          >
            Some links may be unavailable
          </Typography>
        )}
      </Box>
    )}
  </Box>
)}


{searchQuery && Array.isArray(searchResults.collections) && searchResults.collections.length > 0 && (
  <>
<Typography variant="h5" gutterBottom style={{ marginTop: '2rem' }}>
  Top Collections
</Typography>
<Grid container spacing={3}>
  {searchResults.collections.map((product, index) => (
    <Grid item xs={6} sm={6} md={4} key={index}>
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          boxShadow: 3,
          transition: 'box-shadow 0.3s ease, transform 0.3s ease',
          '&:hover': {
            boxShadow: 6,
            transform: 'scale(1.02)',
          },
          cursor: 'pointer',
        }}
          onClick={() => {
      // Collect all URLs from item1 to item10
      const urls = [
        product?.item1,
        product?.item2,
        product?.item3,
        product?.item4,
        product?.item5,
        product?.item6,
        product?.item7,
        product?.item8,
        product?.item9,
        product?.item10,
      ];

      // Filter out null or undefined URLs
      const validUrls = urls.filter((url) => url !== null && url !== undefined);

      // Concatenate the valid URLs with the delimiter '~'
      const concatenatedUrl = validUrls.join('~');

      if (concatenatedUrl) {
        window.open('collections:'+concatenatedUrl, '_blank');
      }
    }}
      >
        {/* User Information Section */}
        <Box display="flex" alignItems="center" padding="10px">
          <CardMedia
            component="img"
            image={product?.image ? product.image : 'https://via.placeholder.com/150'}
            alt={product?.name || 'User'}
            sx={{
              width: 40,
              height: 40,
              borderRadius: '50%',
              marginRight: '20px',
              border: '2px solid',
              borderColor: 'primary.main',
            }}
          />
          <Typography variant="subtitle1" component="div">
            {product?.name || 'User'}
          </Typography>
        </Box>

        {/* Featured Image */}
        <CardMedia
          component="img"
          image={product?.featured_image || 'https://via.placeholder.com/300x140'}
          alt={product?.title}
          sx={{
            height: { xs: 120, sm: 140 },
            transition: 'transform 0.3s ease',
            margin: 'auto',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}
        />

        {/* Card Content */}
        <CardContent
          sx={{
            padding: { xs: 1.5, sm: 2 },
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontSize: { xs: '1rem', sm: '1.1rem' },
              fontWeight: 600,
              lineHeight: 1.3,
              color: 'text.primary',
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              textOverflow: 'ellipsis',
            }}
          >
            {product?.title || 'Pinned Collection'}
          </Typography>

          {/* Action Buttons */}
          <Box display="flex" alignItems="center" justifyContent="space-between" mt="auto">
            {/* Like Button */}

			  <Tooltip title="Like">
	
			     <LikeButton collectionId={product.data_id} />
			</Tooltip>

           

            {/* Share Button */}
            <Tooltip title="Share">
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  handleShareClick(product?.data_id);
                }}
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    transform: 'scale(1.1)',
                  },
                  transition: 'all 0.3s ease-in-out',
                }}
              >
                <ShareIcon sx={{ color: 'action.main' }} />
              </IconButton>
            </Tooltip>

            {/* Pin Button */}
            <Tooltip title="Pin">
               <IconButton
				onClick={(event) => {
				  event.stopPropagation();  // Prevent Card click event
				  handlePinClick(product?.data_id);
				}}
				style={{
				  backgroundColor: isPinned(product?.data_id) ? 'rgba(255, 143, 0, 0.4)' : 'transparent',
				  transition: 'background-color 0.3s ease',
				}}
			  >
				{isPinned(product?.data_id) ? (
				  <PushPinIcon sx={{ color: '#ff9100' }} />
				) : (
				  <PushPinOutlinedIcon sx={{ color: 'secondary.main' }} />
				)}
			  </IconButton>
            </Tooltip>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  ))}
  <Button
    variant="contained"
    onClick={() => window.location.href = '/collections'}
    sx={{
	marginLeft: '125px',
	marginTop: '22px',
      backgroundColor: '#2196f3',
      color: 'white',
      borderRadius: '20px',
      padding: '8px 24px',
      textTransform: 'none',
      fontWeight: 600,
      boxShadow: '0 4px 12px rgba(33,150,243,0.2)',
      '&:hover': {
        backgroundColor: '#1976d2',
        transform: 'translateY(-2px)',
        boxShadow: '0 6px 16px rgba(33,150,243,0.3)',
      },
      transition: 'all 0.3s ease',
    }}
  >
    View More
  </Button>
</Grid>

  </>
)}



{!searchQuery && collectionProducts?.length > 0 && (
<>
<Typography variant="h5" gutterBottom style={{ marginTop: '2rem' }}>
  Top Collections
</Typography>
<Grid container spacing={3}>
  {collectionProducts.map((product, index) => (
    <Grid item xs={6} sm={6} md={4} key={index}>
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          boxShadow: 3,
          transition: 'box-shadow 0.3s ease, transform 0.3s ease',
          '&:hover': {
            boxShadow: 6,
            transform: 'scale(1.02)',
          },
          cursor: 'pointer',
        }}
          onClick={() => {
      // Collect all URLs from item1 to item10
      const urls = [
        product?.item1,
        product?.item2,
        product?.item3,
        product?.item4,
        product?.item5,
        product?.item6,
        product?.item7,
        product?.item8,
        product?.item9,
        product?.item10,
      ];

      // Filter out null or undefined URLs
      const validUrls = urls.filter((url) => url !== null && url !== undefined);

      // Concatenate the valid URLs with the delimiter '~'
      const concatenatedUrl = validUrls.join('~');

      if (concatenatedUrl) {
        window.open(`collections:${concatenatedUrl}`, '_blank');
      }
    }}
      >
        {/* User Information Section */}
        <Box display="flex" alignItems="center" padding="10px">
          <CardMedia
            component="img"
            image={product?.image ? product.image : 'https://via.placeholder.com/150'}
            alt={product?.name || 'User'}
            sx={{
              width: 40,
              height: 40,
              borderRadius: '50%',
              marginRight: '20px',
              border: '2px solid',
              borderColor: 'primary.main',
            }}
          />
          <Typography variant="subtitle1" component="div">
            {product?.name || 'User'}
          </Typography>
        </Box>

        {/* Featured Image */}
        <CardMedia
          component="img"
          image={product?.featured_image || 'https://via.placeholder.com/300x140'}
          alt={product?.title}
          sx={{
            height: { xs: 120, sm: 140 },
            transition: 'transform 0.3s ease',
            margin: 'auto',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}
        />

        {/* Card Content */}
        <CardContent
          sx={{
            padding: { xs: 1.5, sm: 2 },
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontSize: { xs: '1rem', sm: '1.1rem' },
              fontWeight: 600,
              lineHeight: 1.3,
              color: 'text.primary',
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              textOverflow: 'ellipsis',
            }}
          >
            {product?.title || 'Pinned Collection'}
          </Typography>

          {/* Action Buttons */}
          <Box display="flex" alignItems="center" justifyContent="space-between" mt="auto">
            {/* Like Button */}

			  <Tooltip title="Like">
	
			     <LikeButton collectionId={product.data_id} />
			</Tooltip>

           

            {/* Share Button */}
            <Tooltip title="Share">
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  handleShareClick(product?.data_id);
                }}
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    transform: 'scale(1.1)',
                  },
                  transition: 'all 0.3s ease-in-out',
                }}
              >
                <ShareIcon sx={{ color: 'action.main' }} />
              </IconButton>
            </Tooltip>

            {/* Pin Button */}
            <Tooltip title="Pin">
               <IconButton
				onClick={(event) => {
				  event.stopPropagation();  // Prevent Card click event
				  handlePinClick(product?.data_id);
				}}
				style={{
				  backgroundColor: isPinned(product?.data_id) ? 'rgba(255, 143, 0, 0.4)' : 'transparent',
				  transition: 'background-color 0.3s ease',
				}}
			  >
				{isPinned(product?.data_id) ? (
				  <PushPinIcon sx={{ color: '#ff9100' }} />
				) : (
				  <PushPinOutlinedIcon sx={{ color: 'secondary.main' }} />
				)}
			  </IconButton>
            </Tooltip>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  ))}
  <Button
    variant="contained"
    onClick={() => window.location.href = '/collections'}
    sx={{
	marginLeft: '125px',
	marginTop: '22px',
      backgroundColor: '#2196f3',
      color: 'white',
      borderRadius: '20px',
      padding: '8px 24px',
      textTransform: 'none',
      fontWeight: 600,
      boxShadow: '0 4px 12px rgba(33,150,243,0.2)',
      '&:hover': {
        backgroundColor: '#1976d2',
        transform: 'translateY(-2px)',
        boxShadow: '0 6px 16px rgba(33,150,243,0.3)',
      },
      transition: 'all 0.3s ease',
    }}
  >
    View More
  </Button>
</Grid>
</>
)}


{/* Similar modifications for the Pinned Businesses section */}
{!searchQuery && pinnedBusinesses?.length > 0 && (
        <>
          <Typography variant="h5" gutterBottom style={{ marginTop: '2rem' }}>
            Pinned Businesses
          </Typography>
          <Grid container spacing={3}>
            {pinnedBusinesses.map((business, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 2,
                    boxShadow: 3,
                    transition: 'box-shadow 0.3s ease, transform 0.3s ease',
                    '&:hover': {
                      boxShadow: 6,
                      transform: 'scale(1.02)',
                    },
                    cursor: 'pointer',
                    overflow: 'hidden',
                    padding: '16px',
                    position: 'relative',
                  }}
				  
				  onClick={() => {
					if (business.badgeinfo && business.badgeinfo.length > 0) {
					  window.location.href = `/profile/${business.listdata.listing_id}`;
					}
				  }}
                >
                  {/* Custom Pin Icon */}
                 <IconButton
				  sx={{
					position: 'absolute',
					top: '220px',
					right: 10,
					backgroundColor: 'transparent',
					'&:hover': { backgroundColor: '#f5f5f5' },
					zIndex: 10,
				  }}
				  onClick={(e) => {
					e.stopPropagation(); // Prevent card click event
					// Remove the alert
					handleBusinessPinClick(business.listdata.listing_id); 
				  }}
				>
				  {isBusinessPinned(business.listdata.listing_id) ? (
					<PushPinFilledIcon style={{ color: '#9c27b0' }} />
				  ) : (
					<PushPinOutlinedIcon style={{ color: '#9c27b0' }} />
				  )}
				</IconButton>


                  {/* Profile Image Slider */}
                  <Box sx={{ position: 'relative', height: '240px', overflow: 'hidden', paddingBottom: '8px' }}>
                    <Slider {...imageSliderSettings}>
                      {[business.listdata?.sub_list_image_1, business.listdata?.sub_list_image_2, business.listdata?.sub_list_image_3]
                        .filter(Boolean)
                        .map((imgSrc, idx) => (
                          <CardMedia
                            key={idx}
                            component="img"
                            image={imgSrc}
                            alt={business.listdata?.business_name}
                            sx={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              display: 'block',
                              borderRadius: '4px',
                              paddingRight: '10px',
                            }}
                          />
                        ))}
                    </Slider>
                  </Box>

                  {/* Business Name */}
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'bold',
                      color: '#b26500',
                      textAlign: 'center',
                      marginBottom: '8px',
                    }}
                  >
                    {business.listdata?.business_name}
                  </Typography>
				  {business.badgeinfo && business.badgeinfo.length > 0 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  sx={{
                    gap: 1,
                    marginBottom: 1,
                  }}
                >
                  {business.badgeinfo.map((badge, badgeIndex) => (
                    <img
                      key={badgeIndex}
                      src={badge.badge_url} // Assuming 'badge_url' is the URL of the badge image.
                      alt={badge.badge_name || "badge"} // Assuming 'badge_name' is a descriptive name for accessibility.
                      style={{ width: 80, height: 80, marginRight: 8 }}
                    />
                  ))}
                </Box>
              )}

                  {/* Ratings Section */}
                  <Box display="flex" alignItems="center" justifyContent="center" marginBottom={1}>
                    {[...Array(5)].map((_, starIndex) => {
                      const value = business.listdata?.sub_rating;
                      return (
                        <span key={starIndex}>
                          {value >= starIndex + 1 ? (
                            <StarIcon style={{ color: '#ffb400' }} />
                          ) : value >= starIndex + 0.5 ? (
                            <StarHalfIcon style={{ color: '#ffb400' }} />
                          ) : (
                            <StarBorderIcon style={{ color: '#ffb400' }} />
                          )}
                        </span>
                      );
                    })}
                    <Typography variant="body2" sx={{ marginLeft: 1, fontWeight: 'bold', color: '#ff7043' }}>
                      ({business.listdata?.rating_count} reviews)
                    </Typography>
                  </Box>

                  {/* Address Section */}
                  <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center', marginBottom: '16px' }}>
                    {business.listdata?.address_line_1 || 'No Address Available'}
                  </Typography>

                  {/* Contact Buttons */}
				 <Box display="flex" justifyContent="center" marginTop={2}>
				  <Button
					variant="outlined"
					sx={{
					  backgroundColor: '#f5f5f5',
					  color: '#000',
					  '&:hover': {
						backgroundColor: business.badgeinfo?.length > 0 ? '#e0e0e0' : '#f5f5f5',
					  },
					  borderRadius: '20px',
					  opacity: business.badgeinfo?.length > 0 ? 1 : 0.6,
					}}
					size="small"
					onClick={(e) => {
					  e.stopPropagation();
					  if (business.badgeinfo?.length > 0) {
						window.location.href = `/profile/${business.listdata.listing_id}`;
					  }
					}}
				  >
					View Profile
				  </Button>
				</Box>

                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}

{!searchQuery && featuredBusinesses?.length > 0 && (
  <>
    <Typography variant="h5" gutterBottom style={{ marginTop: '2rem' }}>
      Featured Businesses
    </Typography>
    <Grid container spacing={3}>
      {featuredBusinesses.map((business, index) => {
        if (!business || !business.listdata) {
          console.error("Missing data for featured business at index:", index);
          return null; // Skip rendering if essential data is missing
        }

        return (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 2,
                boxShadow: 3,
                transition: 'box-shadow 0.3s ease, transform 0.3s ease',
                '&:hover': {
                  boxShadow: 6,
                  transform: 'scale(1.02)',
                },
                cursor: 'pointer',
                overflow: 'hidden',
                padding: '8px',
              }}
              onClick={() => {
                if (business.badgeinfo?.length > 0) {
                  window.location.href = `/profile/${business.listdata.listing_id}`;
                }
              }}
            >
              {/* Profile Image Slider */}
              <Box sx={{ position: 'relative', height: '240px', overflow: 'hidden', paddingBottom: '8px' }}>
                {business.listdata && (
                  <Slider {...imageSliderSettings}>
                    {business.listdata.sub_list_image_1 && (
                      <CardMedia
                        component="img"
                        image={business.listdata.sub_list_image_1}
                        alt={business.listdata.business_name || 'Business Image'}
                        sx={{ width: '100%', height: '100%', objectFit: 'cover', display: 'block', borderRadius: '4px', paddingRight: '10px' }}
                      />
                    )}
                    {business.listdata.sub_list_image_2 && (
                      <CardMedia
                        component="img"
                        image={business.listdata.sub_list_image_2}
                        alt={business.listdata.business_name || 'Business Image'}
                        sx={{ width: '100%', height: '100%', objectFit: 'cover', display: 'block', borderRadius: '4px', paddingRight: '10px' }}
                      />
                    )}
                    {business.listdata.sub_list_image_3 && (
                      <CardMedia
                        component="img"
                        image={business.listdata.sub_list_image_3}
                        alt={business.listdata.business_name || 'Business Image'}
                        sx={{ width: '100%', height: '100%', objectFit: 'cover', display: 'block', borderRadius: '4px', paddingRight: '10px' }}
                      />
                    )}
                  </Slider>
                )}
              </Box>

              {/* Business Name (Plain Text) */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 'bold',
                  color: '#b26500', // Updated color to match the orange/red shade
                  textAlign: 'center',
                }}
              >
                {business.listdata.business_name
                  ? he.decode(business.listdata.business_name).replace(/<\/?[^>]+(>|$)/g, "")
                  : 'Business Name'}
              </Typography>

              {/* Badges Section using Images */}
              {business.badgeinfo?.length > 0 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  sx={{
                    gap: 1,
                    marginBottom: 1,
                  }}
                >
                  {business.badgeinfo.map((badge, badgeIndex) => (
                    <img
                      key={badgeIndex}
                      src={badge.badge_url} // Assuming 'badge_url' is the URL of the badge image.
                      alt={badge.badge_name || "badge"} // Assuming 'badge_name' is a descriptive name for accessibility.
                      style={{ width: 80, height: 80, marginRight: 8 }}
                    />
                  ))}
                </Box>
              )}

              {/* Ratings Section */}
              {business.listdata.sub_rating !== undefined && (
                <Box display="flex" alignItems="center" justifyContent="center" marginBottom={1}>
                  {[...Array(5)].map((_, starIndex) => {
                    const value = business.listdata.sub_rating;
                    return (
                      <span key={starIndex}>
                        {value >= starIndex + 1 ? (
                          <StarIcon style={{ color: '#ffb400' }} />
                        ) : value >= starIndex + 0.5 ? (
                          <StarHalfIcon style={{ color: '#ffb400' }} />
                        ) : (
                          <StarBorderIcon style={{ color: '#ffb400' }} />
                        )}
                      </span>
                    );
                  })}
                  <Typography
                    variant="body2"
                    sx={{ marginLeft: 1, fontWeight: 'bold', color: '#ff7043' }}
                  >
                    ({business.listdata.rating_count || 0} reviews)
                  </Typography>
                </Box>
              )}

              {/* Address Section */}
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ textAlign: 'center', marginBottom: '16px' }}
              >
                {business.listdata.address_line_1 || 'No Address Available'}
              </Typography>

              {/* Contact Buttons */}
				<Box display="flex" justifyContent="center" marginTop={2}>
			  <Button
				variant="outlined"
				sx={{
				  backgroundColor: '#f5f5f5',
				  color: '#000',
				  '&:hover': {
					backgroundColor: business.badgeinfo?.length > 0 ? '#e0e0e0' : '#f5f5f5',
				  },
				  borderRadius: '20px',
				  opacity: business.badgeinfo?.length > 0 ? 1 : 0.6,
				}}
				size="small"
				onClick={(e) => {
				  e.stopPropagation();
				  if (business.badgeinfo?.length > 0) {
					window.location.href = `/profile/${business.listdata.listing_id}`;
				  }
				}}
			  >
				View Profile
			  </Button>
			</Box>

            </Card>
          </Grid>
        );
      })}
    </Grid>
  </>
)}


{searchQuery && Array.isArray(searchResults?.businesses) && searchResults.businesses.length > 0 && (
  <>
    <Typography variant="h5" gutterBottom style={{ marginTop: '2rem' }}>
      Featured Businesses
    </Typography>
    <Grid container spacing={3}>
      {searchResults.businesses.map((business, index) => {
        // Skip rendering if essential data is missing
        if (!business || !business.listdata) {
          console.error("Missing data for business at index:", index);
          return null;
        }

        return (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 2,
                boxShadow: 3,
                transition: 'box-shadow 0.3s ease, transform 0.3s ease',
                '&:hover': {
                  boxShadow: 6,
                  transform: 'scale(1.02)',
                },
                cursor: 'pointer',
                overflow: 'hidden',
                padding: '8px',
              }}
              onClick={() => {
                if (business.badgeinfo?.length > 0) {
                  window.location.href = `/profile/${business.listdata.listing_id}`;
                }
              }}
            >
              {/* Profile Image Slider */}
              <Box sx={{ position: 'relative', height: '240px', overflow: 'hidden', paddingBottom: '8px' }}>
                {business.listdata && (
                  <Slider {...imageSliderSettings}>
                    {business.listdata.sub_list_image_1 && (
                      <CardMedia
                        component="img"
                        image={business.listdata.sub_list_image_1}
                        alt={business.listdata.business_name || 'Business Image'}
                        sx={{ width: '100%', height: '100%', objectFit: 'cover', display: 'block', borderRadius: '4px', paddingRight: '10px' }}
                      />
                    )}
                    {business.listdata.sub_list_image_2 && (
                      <CardMedia
                        component="img"
                        image={business.listdata.sub_list_image_2}
                        alt={business.listdata.business_name || 'Business Image'}
                        sx={{ width: '100%', height: '100%', objectFit: 'cover', display: 'block', borderRadius: '4px', paddingRight: '10px' }}
                      />
                    )}
                    {business.listdata.sub_list_image_3 && (
                      <CardMedia
                        component="img"
                        image={business.listdata.sub_list_image_3}
                        alt={business.listdata.business_name || 'Business Image'}
                        sx={{ width: '100%', height: '100%', objectFit: 'cover', display: 'block', borderRadius: '4px', paddingRight: '10px' }}
                      />
                    )}
                  </Slider>
                )}
              </Box>

              {/* Business Name (Plain Text) */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 'bold',
                  color: '#b26500',
                  textAlign: 'center',
                }}
              >
                {business.listdata.business_name
                  ? he.decode(business.listdata.business_name).replace(/<\/?[^>]+(>|$)/g, "")
                  : 'Business Name'}
              </Typography>

              {/* Badges Section using Images */}
              {business.badgeinfo?.length > 0 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  sx={{
                    gap: 1,
                    marginBottom: 1,
                  }}
                >
                  {business.badgeinfo.map((badge, badgeIndex) => (
                    <img
                      key={badgeIndex}
                      src={badge.badge_url} // Assuming 'badge_url' is the URL of the badge image.
                      alt={badge.badge_name || "badge"} // Assuming 'badge_name' is a descriptive name for accessibility.
                      style={{ width: 80, height: 80, marginRight: 8 }}
                    />
                  ))}
                </Box>
              )}

              {/* Ratings Section */}
              {business.listdata.sub_rating !== undefined && (
                <Box display="flex" alignItems="center" justifyContent="center" marginBottom={1}>
                  {[...Array(5)].map((_, starIndex) => {
                    const value = business.listdata.sub_rating;
                    return (
                      <span key={starIndex}>
                        {value >= starIndex + 1 ? (
                          <StarIcon style={{ color: '#ffb400' }} />
                        ) : value >= starIndex + 0.5 ? (
                          <StarHalfIcon style={{ color: '#ffb400' }} />
                        ) : (
                          <StarBorderIcon style={{ color: '#ffb400' }} />
                        )}
                      </span>
                    );
                  })}
                  <Typography
                    variant="body2"
                    sx={{ marginLeft: 1, fontWeight: 'bold', color: '#ff7043' }}
                  >
                    ({business.listdata.rating_count || 0} reviews)
                  </Typography>
                </Box>
              )}

              {/* Address Section */}
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ textAlign: 'center', marginBottom: '16px' }}
              >
                {business.listdata.address_line_1 || 'No Address Available'}
              </Typography>

              {/* Contact Buttons */}
				<Box display="flex" justifyContent="center" marginTop={2}>
				  <Button
					variant="outlined"
					sx={{
					  backgroundColor: '#f5f5f5',
					  color: '#000',
					  '&:hover': {
						backgroundColor: business.badgeinfo?.length > 0 ? '#e0e0e0' : '#f5f5f5',
					  },
					  borderRadius: '20px',
					  opacity: business.badgeinfo?.length > 0 ? 1 : 0.6,
					}}
					size="small"
					onClick={(e) => {
					  e.stopPropagation();
					  if (business.badgeinfo?.length > 0) {
						window.location.href = `/profile/${business.listdata.listing_id}`;
					  }
					}}
				  >
					View Profile
				  </Button>
				</Box>

            </Card>
          </Grid>
        );
      })}
    </Grid>
  </>
)}







{!searchQuery && Array.isArray(amazonProducts) && amazonProducts.length > 0 && (
  <>
    <Typography variant="h5" gutterBottom style={{ marginTop: '2rem' }}>
      Amazon Products
    </Typography>
    <Grid container spacing={2}>
      {amazonProducts.slice(0, 10).map((product, index) => {
        // Skip rendering if essential data is missing
        if (!product) {
          console.error("Missing product data at index:", index);
          return null;
        }

        return (
          <Grid item xs={6} sm={6} md={4} key={index}>
            <Card 
              onClick={() => {
                if (product?.product_url) {
                  window.open(product.product_url, '_blank');
                }
              }} 
              style={{ cursor: 'pointer', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
            >
              <CardMedia
                component="img"
                height="150"
                image={product?.product_image_url || 'https://via.placeholder.com/140'}
                alt={product?.product_name || 'Product Image'}
                style={{ objectFit: 'contain' }}
              />
              <CardContent style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '14px', color: '#333' }}>
                  {product?.product_name?.length > 30 
                    ? `${product.product_name.substring(0, 30)}...`
                    : product?.product_name || 'Product Name'}
                </Typography>
                {product?.product_price && product?.product_price !== product?.offer_price && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ textDecoration: 'line-through', marginTop: '4px' }}
                  >
                    ₹{product.product_price}
                  </Typography>
                )}
                <Typography
                  variant="h6"
                  style={{ fontWeight: 'bold', marginTop: '4px', color: '#ff7043' }}
                >
                  ₹{product?.offer_price || 'Price Unavailable'}
                </Typography>
                {product?.discount_percentage && (
                  <Typography
                    variant="body2"
                    sx={{
                      backgroundColor: '#ffeb3b',
                      padding: '4px 8px',
                      borderRadius: '12px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      display: 'inline-block',
                      marginTop: '4px',
                    }}
                  >
                    {Math.round(product.discount_percentage)}% off
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>

    <Box textAlign="center" marginTop={4}>
      <Button
        variant="contained"
        onClick={() => window.location.href = '/amazon'}
        sx={{
          backgroundColor: '#ff5722',
          color: 'white',
          borderRadius: '20px',
          padding: '8px 24px',
          textTransform: 'none',
          fontWeight: 600,
          boxShadow: '0 4px 12px rgba(255,87,34,0.2)',
          '&:hover': {
            backgroundColor: '#f4511e',
            transform: 'translateY(-2px)',
            boxShadow: '0 6px 16px rgba(255,87,34,0.3)',
          },
          transition: 'all 0.3s ease',
        }}
      >
        View More
      </Button>
    </Box>
  </>
)}



{searchQuery && Array.isArray(searchResults?.amazonProducts) && searchResults.amazonProducts.length > 0 && (
  <>
 <Typography variant="h5" gutterBottom style={{ marginTop: '2rem' }}>
  Amazon Products
</Typography>
<Grid container spacing={2}>
  {searchResults.amazonProducts.map((product, index) => (
    <Grid item xs={6} sm={6} md={4} key={index}>
      <Card onClick={() => window.open(product.product_url, '_blank')} 
        style={{ cursor: 'pointer', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
      >
        <CardMedia
          component="img"
          height="150"
          image={product.product_image_url ? product.product_image_url : 'https://via.placeholder.com/140'}
          alt={product.product_name}
          style={{ objectFit: 'contain' }}
        />
        <CardContent style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '14px', color: '#333' }}>
            {product.product_name.length > 30 ? product.product_name.substring(0, 30) + '...' : product.product_name}
          </Typography>
          {product.product_price && product.product_price !== product.offer_price && (
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ textDecoration: 'line-through', marginTop: '4px' }}
            >
              ₹{product.product_price}
            </Typography>
          )}
          <Typography
            variant="h6"
            style={{ fontWeight: 'bold', marginTop: '4px', color: '#ff7043' }}
          >
            ₹{product.offer_price}
          </Typography>
          {product.discount_percentage && (
            <DiscountBadge>
              {Math.round(product.discount_percentage)}% off
            </DiscountBadge>
          )}
        </CardContent>
      </Card>
    </Grid>
  ))}
   
  <Button
    variant="contained"
    onClick={() => window.location.href = '/amazon'}
    sx={{
		marginLeft: '125px',
		marginTop: '22px',
      backgroundColor: '#ff5722',
      color: 'white',
      borderRadius: '20px',
      padding: '8px 24px',
      textTransform: 'none',
      fontWeight: 600,
      boxShadow: '0 4px 12px rgba(255,87,34,0.2)',
      '&:hover': {
        backgroundColor: '#f4511e',
        transform: 'translateY(-2px)',
        boxShadow: '0 6px 16px rgba(255,87,34,0.3)',
      },
      transition: 'all 0.3s ease',
    }}
  >
    View More
  </Button>
</Grid> 
  
  
 </>
)}



{!searchQuery && topDealsToShow?.length > 0 && (
<>
<Typography variant="h5" gutterBottom style={{ marginTop: '2rem' }}>
  Top Deals
</Typography>
<Grid container spacing={2}>
  {topDealsToShow.map((product, index) => (
    <Grid item xs={6} sm={6} md={4} key={index}>
      <Card onClick={() => window.open(product.product_url, '_blank')} style={{ cursor: 'pointer', height: '100%' }}>
        <CardMedia
          component="img"
          height="180"
          image={product.product_image_url ? product.product_image_url : 'https://via.placeholder.com/140'}
          alt={product.product_name}
          style={{ objectFit: 'contain' }}
        />
        <CardContent>
          <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '14px', color: '#333' }}>
            {product.product_name.length > 30 ? product.product_name.substring(0, 30) + '...' : product.product_name}
          </Typography>
          {product.product_price && product.product_price !== product.offer_price && (
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ textDecoration: 'line-through', marginTop: '4px' }}
            >
              ₹{product.product_price}
            </Typography>
          )}
          <Typography variant="h6" style={{ fontWeight: 'bold', marginTop: '4px', color: '#ff7043' }}>
            ₹{product.offer_price}
          </Typography>
          {product.discount_percentage && (
            <Box
              sx={{
                backgroundColor: '#f8f8f8',
                color: '#ff7043',
                padding: '4px 8px',
                borderRadius: '24px',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '0.85rem',
                marginTop: '8px',
                alignSelf: 'center',
                display: 'inline-block',
              }}
            >
              {Math.round(product.discount_percentage)}% off
            </Box>
          )}
        </CardContent>
      </Card>
    </Grid>
  ))}
</Grid>
</>
)}

 {searchQuery && Array.isArray(searchResults?.categoryLinks) && searchResults.categoryLinks.length > 0 && (
  <>
        <Typography variant="h5" gutterBottom style={{ marginTop: '2rem' }}>
        Category Links
      </Typography>
      {searchResults.categoryLinks.map((category, index) => (
        <Box key={index} marginBottom={4} sx={{
          backgroundColor: '#f5e8e8',
          borderRadius: '10px',
          padding: 2,
          position: 'relative',
          marginBottom: 4,
        }}>
          <Typography variant="h6" gutterBottom>
		 
            {category.category_name}
          </Typography>
          <Button
            variant="text"
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              textTransform: 'none',
              color: '#8b5e5e',
              fontWeight: 600,
              fontSize: '0.9rem',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#5a3939',
              },
            }}
			
			 onClick={() => {
        // Assuming each category has a `category_id` property
				const categoryId = category.category_id;
				//alert(categoryId);
				if (categoryId) {
				  window.location.href=`/category/${categoryId}`;
				}
			  }}
          >
            View More
          </Button>
          <Grid container spacing={2} justifyContent="center">
            {category.links.slice(0, 4).map((link, linkIndex) => (
              <Grid item xs={3} sm={3} md={2} lg={1} key={linkIndex} display="flex" justifyContent="center">
                <Box
                  onClick={() => window.open(link.link_URL, '_blank')}
                  sx={{
                    cursor: 'pointer',
                    textAlign: 'center',
                    marginX: 1,
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                    transition: 'transform 0.3s ease',
                  }}
                >
                  <CardMedia
                    component="img"
                    image={link.link_image ? link.link_image : 'https://via.placeholder.com/140'}
                    alt={link.link_name}
                    sx={{
                      width: 60,
                      height: 60,
                      borderRadius: '50%',
                      marginBottom: 1,
                      border: '2px solid',
                      borderColor: 'grey.300',
                    }}
                  />
                  <Typography variant="body2" noWrap sx={{ maxWidth: 70 }}>
                    {link.link_name.length > 10 ? `${link.link_name.substring(0, 10)}...` : link.link_name}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
  
  </>
)}


      {!searchQuery && categoryLinks?.length > 0 && (
	   <>
      <Typography variant="h5" gutterBottom style={{ marginTop: '2rem' }}>
        Category Links
      </Typography>
      {categoryLinks.map((category, index) => (
        <Box key={index} marginBottom={4} sx={{
          backgroundColor: '#f5e8e8',
          borderRadius: '10px',
          padding: 2,
          position: 'relative',
          marginBottom: 4,
        }}>
          <Typography variant="h6" gutterBottom>
            {category.category_name}
          </Typography>
          <Button
            variant="text"
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              textTransform: 'none',
              color: '#8b5e5e',
              fontWeight: 600,
              fontSize: '0.9rem',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#5a3939',
              },
            }}
			
			 onClick={() => {
        // Assuming each category has a `category_id` property
				const categoryId = category.category_id;
				//alert(categoryId);
				if (categoryId) {
				  window.location.href=`/category/${categoryId}`;
				}
			  }}
          >
            View More
          </Button>
          <Grid container spacing={2} justifyContent="center">
            {category.links.slice(0, 4).map((link, linkIndex) => (
              <Grid item xs={3} sm={3} md={2} lg={1} key={linkIndex} display="flex" justifyContent="center">
                <Box
                  onClick={() => window.open(link.link_URL, '_blank')}
                  sx={{
                    cursor: 'pointer',
                    textAlign: 'center',
                    marginX: 1,
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                    transition: 'transform 0.3s ease',
                  }}
                >
                  <CardMedia
                    component="img"
                    image={link.link_image ? link.link_image : 'https://via.placeholder.com/140'}
                    alt={link.link_name}
                    sx={{
                      width: 60,
                      height: 60,
                      borderRadius: '50%',
                      marginBottom: 1,
                      border: '2px solid',
                      borderColor: 'grey.300',
                    }}
                  />
                  <Typography variant="body2" noWrap sx={{ maxWidth: 70 }}>
                    {link.link_name.length > 10 ? `${link.link_name.substring(0, 10)}...` : link.link_name}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
	  </>
	  )}
	  
{!searchQuery && footerBanner && footerBanner.length > 0 && (
 footerBanner.length === 1 ? (
   <div style={{ width: '100%' }}>
     <img
       src={footerBanner[0].gallery_image}
       alt={footerBanner[0].gallery_name}
       style={{ width: '100%', height: 'auto' }}
     />
   </div>
 ) : (
   <Slider {...bannerSettings}>
     {footerBanner.map((banner, index) => (
       <div key={index}>
         <img
           src={banner.gallery_image}
           alt={banner.gallery_name}
           style={{ width: '100%', height: 'auto' }}
         />
       </div>
     ))}
   </Slider>
 )
)}
	 <Snackbar
  open={snackbarOpen}
  autoHideDuration={3000}
  onClose={() => setSnackbarOpen(false)}
  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
>
  <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
    {snackbarMessage}
  </Alert>
</Snackbar>
	   <FooterComponent />
 
    </Container>
  );
};

export default HomePage;