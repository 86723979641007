const API_BASE_URL = "https://myapps.5bestincity.com/api/app-api-only/";
const IMAGE_BASE_URL = "https://apps.5bestincity.com/"; // Replace relative paths
const DEFAULT_IMAGE_BASE_URL = "https://developer.2il.org/Appdev/images/Web-Icon.png";
const COLORS = {
  primary: "#3f51b5",
  accent2: "#FF5722",
  default: "#ccc",
};

const getEngineImageUrl = (relativePath) => relativePath.replace("../", IMAGE_BASE_URL);

const ENDPOINTS = {
  WEB_APPS: "webappsapi.php",
   CATEGORY_PAGE: "webcatsapi.php",
     NEAR_ME: "nearmejson.php",
	  AMAZON_API: "amazonapi.php", // Add this line
	   AMAZON_FILTER : "amazonfilter.php",
	     COLLECTIONS: "collectionsapi.php",
			  COLLECTIONS_FILTER: "collectionsfilter.php",
			  UPDATE_COUNT: "update_count.php",
			  SEARCH_ENGINES: "collectionsapi.php", // If needed
			  GET_COUNTS : "get_counts.php",
};

const getApiUrl = (endpoint, params = "") => `${API_BASE_URL}${endpoint}?${params}`;

const getApiUrlfilter = (endpoint, params = "") => `${API_BASE_URL}${endpoint}${params}`;

const API_ENDPOINTS = {
  GET_COUNTS: `${API_BASE_URL}get_counts.php`,
  UPDATE_COUNT: `${API_BASE_URL}update_count.php`,
  COLLECTIONS: `${API_BASE_URL}collectionsapi.php`,
  COLLECTIONS_FILTER: `${API_BASE_URL}collectionsfilter.php`,
  AMAZON: `${API_BASE_URL}amazonapi.php`,
  WEB_APPS: `${API_BASE_URL}webappsapi.php`,
  BUSINESS_DETAILS: `${API_BASE_URL}5best/profile-details.php`
};


export {
  API_BASE_URL,
  IMAGE_BASE_URL,
  DEFAULT_IMAGE_BASE_URL,
  ENDPOINTS,
  API_ENDPOINTS,
  getApiUrl,getApiUrlfilter,
  COLORS, getEngineImageUrl
};
