import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import FooterComponent from './FooterComponent';
import {
  Container,
  Typography,
  TextField,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  InputAdornment,
  useTheme,
  alpha,
  Paper,
  IconButton,
  Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {
  ENDPOINTS,
  getApiUrl,
  SEARCH_ENGINE_IMAGE_BASE_URL,
  CATEGORY_IMAGE_BASE_URL, // Import the constant
} from './config/apiConfig';

function CategoryPage() {
  const { cityId } = useParams();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [cityInfo, setCityInfo] = useState(null);
  const [searchEngines, setSearchEngines] = useState([]);
  const [activeEngines, setActiveEngines] = useState([]);
  const [isSticky, setIsSticky] = useState(false);

  const theme = useTheme();
  const searchRef = useRef(null);
  const headingRef = useRef(null);

  useEffect(() => {
    fetchCategories();

    const handleScroll = () => {
      if (headingRef.current && searchRef.current) {
        const headingRect = headingRef.current.getBoundingClientRect();
        const searchRect = searchRef.current.getBoundingClientRect();
        
        if (headingRect.bottom <= 0 && !isSticky) {
          setIsSticky(true);
        } else if (headingRect.bottom > 0 && isSticky) {
          setIsSticky(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [cityId, isSticky]);

  const fetchCategories = async () => {
   // setIsLoading(true);
    try {
      const url = getApiUrl(ENDPOINTS.CATEGORY, `cityid=${cityId}`);
      const response = await fetch(url);
      const data = await response.json();
      setCategories(data.categories || []);
      setFilteredCategories(data.categories || []);
      setCityInfo({ 
        city_name: data.city_name, 
        state_name: data.state_name, 
        city_urlslug: data.city_urlslug
      });
      setSearchEngines(data.searchengine.searchenginedata || []);

      const preSelectedEngines = data.searchengine.searchenginedata
        .filter((engine) => engine.selected === 'true')
        .map((engine) => engine.searchenginename);
      setActiveEngines(preSelectedEngines);
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleCategoryClick = (category) => {
    navigate(`/${category.subcat_urlslug}/${cityInfo.city_urlslug}`);
  };

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = categories.filter(
      (category) =>
        category.sub_category_name.toLowerCase().includes(term) ||
        (category.related_keywords && category.related_keywords.toLowerCase().includes(term))
    );
    setFilteredCategories(filtered);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setFilteredCategories(categories);
    setActiveEngines(
      searchEngines.filter((engine) => engine.selected === 'true').map((engine) => engine.searchenginename)
    );
  };

  const handleExternalSearch = () => {
    if (!searchTerm || activeEngines.length === 0) return;
    const searchUrls = searchEngines
      .filter((engine) => activeEngines.includes(engine.searchenginename))
      .map((engine) => `${engine.searchengineurl}${encodeURIComponent(searchTerm)}`);
    const combinedUrl = `collections:${searchUrls.join('\\~\\')}`;
    window.open(combinedUrl, '_blank');
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleExternalSearch();
    }
  };

  const handleEngineClick = (engineName) => {
    setActiveEngines((prevActiveEngines) =>
      prevActiveEngines.includes(engineName)
        ? prevActiveEngines.filter((name) => name !== engineName)
        : [...prevActiveEngines, engineName]
    );
  };

  if (isLoading) {
    return (
      <Container maxWidth="sm">
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

return (
  <Container maxWidth="sm">
    <Box my={4} sx={{ marginTop: '15px' }}>
      <Typography 
        ref={headingRef}
        variant="h6" 
        gutterBottom 
        align="center" 
        sx={{
          color: '#000', 
          fontSize: '18px', 
          marginBottom: '15px', 
          marginTop: '10px !important',
          transition: 'opacity 0.3s ease-in-out',
          opacity: isSticky ? 0 : 1,
          height: isSticky ? 0 : 'auto',
          overflow: 'hidden'
        }}
      >
        {cityInfo ? `${cityInfo.city_name}, ${cityInfo.state_name}` : 'Categories'}
      </Typography>
<Box
  ref={searchRef}
  sx={{
    position: isSticky ? 'fixed' : 'static',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    width: '100%',
    mb: 2,
    transition: 'all 0.3s ease-in-out',
    backgroundColor: isSticky ? 'white' : 'transparent',
    boxShadow: isSticky ? '0 2px 4px rgba(0,0,0,0.1)' : 'none',
  }}
>
  <Container maxWidth="sm">
    <TextField
      fullWidth
      variant="standard"
      placeholder="Search your business"
      value={searchTerm}
      onChange={handleSearch}
      onKeyPress={handleKeyPress}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="action" />
          </InputAdornment>
        ),
        endAdornment: searchTerm && (
          <InputAdornment position="end">
            <IconButton onClick={handleClearSearch}>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
        disableUnderline: true,
      }}
      sx={{
        '& .MuiInputBase-root': {
          pl: 2,
          pr: 2,
          py: 1,
          backgroundColor: 'transparent',
          borderRadius: 0,
        },
        '& .MuiInputBase-input': {
          padding: '8px 0',
        },
      }}
    />
  </Container>
</Box>
      {filteredCategories.length > 0 ? (
        <List>
          {filteredCategories.map((category) => (
            <ListItem
              key={category.sub_category_name}
              sx={{
                backgroundColor: '#fff',
                borderRadius: '12px',
                mb: 1,
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                transition: 'all 0.3s',
                '&:hover': {
                  backgroundColor: '#f5f5f5',
                  transform: 'translateY(-3px)',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
                },
              }}
              onClick={() => handleCategoryClick(category)}
            >
              <ListItemIcon>
                <Box
                  sx={{
                    width: 40,
                    height: 40,
                    borderRadius: '8px',
                    backgroundColor: '#f0f0f0',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {category.sub_category_image ? (
                   <img
                     src={`${CATEGORY_IMAGE_BASE_URL}${category.sub_category_image}`} // Use dynamic category image URL
                      alt={category.sub_category_name}
                      style={{ width: 24, height: 24 }}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = `data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>${category.sub_category_name.charAt(0).toUpperCase()}</text></svg>`;
                      }}
                    />
                  ) : (
                    <Typography variant="subtitle1" color="textSecondary">
                      {category.sub_category_name.charAt(0).toUpperCase()}
                    </Typography>
                  )}
                </Box>
              </ListItemIcon>
              <ListItemText 
                primary={category.sub_category_name} 
                primaryTypographyProps={{
                  variant: 'subtitle1',
                  fontWeight: 'medium',
                }}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Box textAlign="center">
          <Typography variant="h6" gutterBottom>No categories to show</Typography>
          <Box display="flex" justifyContent="center" mb={2}>
            {searchEngines.map((engine) => (
              <IconButton
                key={engine.searchenginename}
                onClick={() => handleEngineClick(engine.searchenginename)}
                sx={{
                  width: 48,
                  height: 48,
                  mx: 1,
                  backgroundColor: activeEngines.includes(engine.searchenginename)
                    ? theme.palette.error.main
                    : theme.palette.grey[300],
                  '&:hover': {
                    backgroundColor: activeEngines.includes(engine.searchenginename)
                      ? theme.palette.error.dark
                      : theme.palette.grey[400],
                  },
                }}
              >
                <img
                  src={`${SEARCH_ENGINE_IMAGE_BASE_URL}${engine.searchengineimage.replace('../', '')}`}
                  alt={engine.searchenginename}
                  style={{ width: 24, height: 24 }}
                />
              </IconButton>
            ))}
          </Box>
          <Button variant="contained" color="primary" onClick={handleExternalSearch}>
            SEARCH
          </Button>
        </Box>
      )}
    </Box>
	<FooterComponent />
  </Container>
);
}

export default CategoryPage;