import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLogin } from './contexts/LoginContext';
import { API_BASE_URL } from './config';

function LoginCheck() {
  const { state, userId } = useParams();
  const { login } = useLogin();

  useEffect(() => {
    const checkLoginStatus = async () => {
      console.log("LoginCheck: Checking login status...");
     // const userId='1';
      const url = new URL(`${API_BASE_URL}loginbrowserstate.php`);
      url.searchParams.append('user_id', userId);
      url.searchParams.append('state', state);

      try {
        const response = await fetch(url.toString());
        const data = await response.json();
        
        if (data.result === 'success') {
			
          login(data.id,data.name,data.phone);
          console.log("Login successful for user:", userId);
        } else {
          console.log("Login failed or invalid response");
        }
      } catch (error) {
        console.error('LoginCheck: Errorchecking login status:', error);
      }
    };

    checkLoginStatus();
  }, [state, userId, login]);

  // This component doesn't render anything visible
  return null;
}

export default LoginCheck;