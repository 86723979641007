import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid, Typography, Modal, Box, Button } from '@mui/material';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { keyframes } from '@emotion/react';
import ListingCard from './components/5best/ListingCard';
import EnquiryForm from './components/5best/EnquiryForm';
import ContactPopupModal from './components/5best/ContactPopupModal';
import { styled } from '@mui/material/styles';
import { useLogin } from './contexts/LoginContext';
import FooterComponent from './FooterComponent';
import { getApiUrl, ENDPOINTS } from './config/apiConfig';
import { getImageUrl } from './config/apiConfig';

const userLoggedIn= localStorage.getItem('loginState')== 'logged_in';
  const userId = localStorage.getItem('user_id');

const FadeSlider = styled(Slider)(({ theme }) => ({
  '.slick-slide': {
    opacity: 0,
    transition: 'opacity 500ms ease-in-out',
  },
  '.slick-slide.slick-active': {
    opacity: 1,
  },
}));

const BannerImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: '250px', // Increased height for desktop
  objectFit: 'cover',
  objectPosition: 'center',
  borderRadius: '8px',
  [theme.breakpoints.down('sm')]: {
    height: '50px', // Increased height specifically for mobile screens
  },
}));

// BannerSlider component remains the sameee
const BannerSlider = ({ bannerData }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    cssEase: 'linear'
  };

  return (
    <Box sx={{ 
      '& .slick-dots': { 
        display: 'none' 
      }
    }}>
      <FadeSlider {...settings}>
        {bannerData.map((banner, index) => (
          <div key={index}>
            <a href={banner.url} target="_blank" rel="noopener noreferrer">
              <BannerImage
                src={`https://5bestincity.com/images/public/banner/${banner.imagesize1}`}
                alt={banner.business_name}
              />
            </a>
          </div>
        ))}
      </FadeSlider>
    </Box>
  );
};


const ListingPage = () => {
  const { subcategoryslug, cityslug } = useParams();
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openEnquiry, setOpenEnquiry] = useState(false);
  const [selectedListing, setSelectedListing] = useState(null);
  const [cityName, setCityName] = useState('');
  const [stateName, setStateName] = useState('');
  const [subcatName, setSubcatName] = useState('');
  const [vibrateIcon, setVibrateIcon] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [openContactPopup, setOpenContactPopup] = useState(false);
  const [selectedListingId, setSelectedListingId] = useState(null);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [contactPopupData, setContactPopupData] = useState('');
  const [bannerData, setBannerData] = useState([]);
  const [bannerPositions, setBannerPositions] = useState([]);
  
  
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setVibrateIcon(prev => !prev);
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const loginState = sessionStorage.getItem('loginState');
    setIsLoggedIn(userLoggedIn);  
	
	//alert(localStorage.getItem('phone'));
    
    const fetchListings = async () => {
      try {
		const listingUrl = getApiUrl(ENDPOINTS.LISTING_API, `listid=${subcategoryslug}-in-${cityslug}`);
        const response = await fetch(listingUrl);
        const data = await response.json();
        setListings(data.listalldata);
        setContactPopupData(data.contact_popup);

        if (data.listalldata.length > 0) {
          setCityName(data.listalldata[0].listdata.city_name);
          setStateName(data.listalldata[0].listdata.state_name);
          setSubcatName(data.listalldata[0].listdata.sub_category_name);
        }
		
		data.banner="6481620854999,7835723583677";

        // Fetch banner data
        if (data.banner) {
          const bannerResponse = await fetch('https://5bestincity.com/api/bannerfetchinfo.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `id=${data.banner}`,
          });
          const bannerData = await bannerResponse.json();
          setBannerData(bannerData.data);
          setBannerPositions(bannerData.position.split(',').map(Number));
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchListings();
  }, [subcategoryslug, cityslug]);

const handleClick = (listing, type) => {
  const hasBadge = listing.badgeinfo && listing.badgeinfo.length > 0;

  if (hasBadge) {
    // Direct actions for listings with badges
    switch(type) {
      case 'call':
        window.location.href = `tel:${listing.listdata.business_phone_1}`;
        break;
      case 'chat':
        window.location.href = `https://wa.me/${listing.listdata.business_whatsapp}`;
        break;
      case 'direction':
        window.open(listing.listdata.direction_URL, '_blank');
        break;
      default:
        break;
    }
  } else if (!userLoggedIn) {
    setOpenLoginModal(true);
  } else if (contactPopupData) {
	//  alert(contactPopupData);
    // Instead of using the listing's ID, use the contactPopupData value
    setSelectedListingId(contactPopupData);  // Use contact_popup ID here
    setOpenContactPopup(true);
  } else {
    handleOpenEnquiry(listing);
  }
};

  const handleOpenEnquiry = (listing) => {
    setSelectedListing(listing);
    setOpenEnquiry(true);
  };

  const handleCloseEnquiry = () => {
    setOpenEnquiry(false);
  };

  const handleCloseContactPopup = () => {
    setOpenContactPopup(false);
  };

  const handleCloseLoginModal = () => {
    setOpenLoginModal(false);
  };

  const handleLogin = () => {
    // Implement your login logic here
    setIsLoggedIn(true);
    setOpenLoginModal(false);
  };
   
  if (loading) {
    return <Typography component="div" sx={{ textAlign: 'center', marginTop: 4 }}>Loading...</Typography>;
  }

  if (!listings.length) {
    return <Typography component="div" sx={{ textAlign: 'center', marginTop: 4 }}>No Listings Found</Typography>;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    centerMode: true,  
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 0.4,
          centerPadding: '100px',
          margin: '0px 50px'
        }
      }
    ]
  };
  
  const iconAnimation = keyframes`
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
  `;

  const vibrateAnimation = keyframes`
    0% { transform: rotate(0deg); }
    25% { transform: rotate(-10deg); }
    50% { transform: rotate(10deg); }
    75% { transform: rotate(-5deg); }
    100% { transform: rotate(0deg); }
  `;

  const badgeIconStyle = {
  width: '80px', // Reduced from 100px
  height: 'auto',
  filter: 'brightness(1.1) contrast(1.1)',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.1)',
  },
};
  
  const buttonStyle = {
    fontSize: '0.75rem',
    padding: '6px 12px',
    minWidth: '80px',
    borderRadius: '20px',
    textTransform: 'none',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    '&:hover .MuiSvgIcon-root': {
      animation: `${iconAnimation} 0.5s ease-in-out`,
    },
  };
  
  const callButtonStyle = {
    ...buttonStyle,
    '& .MuiSvgIcon-root': {
      animation: vibrateIcon ? `${vibrateAnimation} 0.5s ease-in-out` : 'none',
    },
  };

  return (
    <Container maxWidth="lg" sx={{ marginTop: 4 }}>
      <Typography variant="h5" sx={{ textAlign: 'center', marginBottom: 2, fontWeight: 'bold', fontSize: '18px' }}>
        {`5 Best ${subcatName} in ${cityName}, ${stateName}`}
      </Typography>

      <Grid container spacing={4}>
 {listings.map((listingData, index) => {
  // Define hasBadge here based on the listingData
  const hasBadge = listingData.badgeinfo && listingData.badgeinfo.length > 0;

  return (
    <React.Fragment key={listingData.listdata.listing_id}>
      {bannerPositions.includes(index + 1) && (
        <Grid item xs={12}>
          <BannerSlider bannerData={bannerData} />
        </Grid>
      )}
      <ListingCard 
        listingData={listingData}
        handleClick={handleClick}
        settings={settings}
        badgeIconStyle={badgeIconStyle}
        buttonStyle={buttonStyle}
        callButtonStyle={callButtonStyle}
        isLoggedIn={isLoggedIn}
        onOpenEnquiry={handleOpenEnquiry}
        allowRedirect={hasBadge ? "yes" : "no"}  // Now hasBadge is defined
      />
    </React.Fragment>
  );
})}
        {bannerPositions.includes(6) && (
          <Grid item xs={12}>
            <BannerSlider bannerData={bannerData} />
          </Grid>
        )}
      </Grid>

      {selectedListing && (
        <EnquiryForm
          open={openEnquiry}
          onClose={handleCloseEnquiry}
          businessName={selectedListing.listdata.business_name}
          businessImage={selectedListing.listdata.sub_list_image_1}
          cityname={selectedListing.listdata.city_name}
          subcatname={selectedListing.listdata.sub_category_name}
		  cityurlslug={cityslug}
		  subcaturlslug={subcategoryslug}
		  listingId={selectedListing.listdata.listing_id}
        />
      )}

      <ContactPopupModal
        open={openContactPopup}
        onClose={handleCloseContactPopup}
        listingId={selectedListingId}
        contactPopupData={contactPopupData}
      />

      <Modal
        open={openLoginModal}
        onClose={handleCloseLoginModal}
        aria-labelledby="login-modal-title"
        aria-describedby="login-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="login-modal-title" variant="h6" component="h2">
            Login Required
          </Typography>
          <Typography id="login-modal-description" sx={{ mt: 2 }}>
            Please log in to view contact details.
          </Typography>
          <Button onClick={handleLogin} sx={{ mt: 2 }}>
            Log In
          </Button>
        </Box>
      </Modal>
	  <FooterComponent />
    </Container>
	
  );
};

export default ListingPage;