import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';


const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
  const loginState = sessionStorage.getItem('loginState');
  const storedUserId = localStorage.getItem('user_id');
  
  setUserLoggedIn(loginState === 'logged_in');
  setUserId(storedUserId);
}, []);


const login = (newUserId,name,phone) => {
  
  sessionStorage.setItem('loginState', 'logged_in');
  localStorage.setItem('loginState', 'logged_in');
  localStorage.setItem('user_id', newUserId);
  localStorage.setItem('name', name);
  localStorage.setItem('phone', phone);
  setUserLoggedIn(true);
  setUserId(newUserId);
 
};

  const logout = useCallback(() => {
    // Clear login data
    setUserId(null);
    setUserLoggedIn(false);
    sessionStorage.removeItem('loginState');
  }, []);

  return (
    <LoginContext.Provider value={{ userLoggedIn, userId, login, logout }}>
      {children}
    </LoginContext.Provider>
  );
};

export const useLogin = () => {
  const context = useContext(LoginContext);
  if (context === undefined) {
    throw new Error('useLogin must be used within a LoginProvider');
  }
  return context;
};