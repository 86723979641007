import React, { useState, useEffect } from 'react';
import { Typography, Rating, Button, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton, Snackbar, Alert } from '@mui/material';
import { Phone, Email, Directions, WhatsApp } from '@mui/icons-material';
import { ContentWrapper, colors, typography } from '../../../theme-styles';
import { PushPin, PushPinOutlined } from '@mui/icons-material'; // Pin icons
import { openDB } from 'idb'; // For IndexedDB handling

const userLoggedIn = localStorage.getItem('loginState') === 'logged_in';
const userId = localStorage.getItem('user_id');

const LoginRequiredModal = ({ open, onClose, onLogin }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="login-required-dialog-title"
      aria-describedby="login-required-dialog-description"
    >
      <DialogTitle id="login-required-dialog-title">Login Required</DialogTitle>
      <DialogContent>
        <DialogContentText id="login-required-dialog-description">
          Please log in to access this feature.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onLogin} color="primary" autoFocus>
          LOG IN
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const BasicInfo = ({
  name,
  listingId,
  rating,
  ratingCount,
  address,
  phone,
  email,
  badgeinfo,
  contactinfo,
  contactPopupData,
  onOpenEnquiry,
  onOpenContactModal,
  directionurl
}) => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const isbadge = !badgeinfo || badgeinfo.length === 0;
  const showContactModal = contactPopupData;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  
  // CSS Keyframes for icon vibration
const vibrationAnimation = {
    '@keyframes vibrate': {
      '0%': { transform: 'translate(0)' },
      '20%': { transform: 'translate(-1px, 1px)' },
      '40%': { transform: 'translate(1px, -1px)' },
      '60%': { transform: 'translate(-1px, -1px)' },
      '80%': { transform: 'translate(1px, 1px)' },
      '100%': { transform: 'translate(0)' }
    }
  };


  const handleButtonClick = (action) => {
    if (isbadge) {
      if (userLoggedIn) {
        if (showContactModal) {
          onOpenContactModal(action, { contactPopupData });
        } else {
          onOpenEnquiry();
        }
      } else {
        setIsLoginModalOpen(true);
      }
    } else {
      switch (action) {
        case 'call':
          window.location.href = `tel:${phone}`;
          break;
        case 'whatsapp':
          window.location.href = `https://wa.me/${phone}`;
          break;
        case 'direction':
		 window.location.href = `${directionurl}`;
          //console.log('Implement direction showing logic');
          break;
        default:
          console.log('Unhandled action:', action);
      }
    }
  };

  const handleLogin = () => {
    console.log('Login button clicked');
    setIsLoginModalOpen(false);
  };

  // Scroll event listener to make buttons sticky
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      // Set sticky state when scroll position crosses 200px
      if (scrollPosition > 800) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  
useEffect(() => {
    const checkPinnedStatus = async () => {
      const db = await openDB('PinnedBusinesses', 3, {
        upgrade(db) {
          db.createObjectStore('pins', { keyPath: 'id' });
        },
      });
      const pin = await db.get('pins', listingId);
      setIsPinned(!!pin);
    };
    checkPinnedStatus();
  }, [listingId]);

 const handlePinClick = async () => {
    const db = await openDB('PinnedBusinesses', 3);
    if (isPinned) {
      await db.delete('pins', listingId);
      setSnackbarMessage('Your business has been unpinned');
    } else {
      await db.put('pins', { id: listingId });
      setSnackbarMessage('Your business has been pinned');
    }
    setIsPinned(!isPinned);
    setSnackbarOpen(true);
  };
  
   const handleCloseSnackbar = () => setSnackbarOpen(false);

  return (
    <ContentWrapper sx={{ padding: '0px', boxShadow: 'none', marginBottom: '30px' }}>
      <Typography variant="h4" component="h2" gutterBottom sx={{ ...typography.h4, color: colors.primary, fontSize: '25px' }}>{name}</Typography>
       <IconButton onClick={handlePinClick} color={isPinned ? 'secondary' : 'default'}>
        {isPinned ? <PushPin /> : <PushPinOutlined />}
      </IconButton>
	   <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
	  <Box display="flex" alignItems="center" mb={1}>
        <Rating value={parseFloat(rating)} readOnly precision={0.1} sx={{ color: colors.primary }} />
        <Typography variant="body2" ml={1} sx={{ ...typography.body2, color: colors.secondaryText }}>({ratingCount} votes)</Typography>
      </Box>
      <Typography variant="body1" gutterBottom sx={{ ...typography.body1, color: colors.primaryText }}>{address}</Typography>
	  <Typography variant="body1" gutterBottom sx={{ ...typography.body1, color: colors.primaryText }}>
		  
		  {contactinfo.is_direction === "true" && (
			<Directions
			  onClick={() => handleButtonClick('direction')}
			  sx={{
				fontSize: '20px',        // Set the size of the icon
				marginLeft: '8px',       // Add some space between address and icon
				cursor: 'pointer',       // Show pointer to indicate it's clickable
				color: colors.primary,   // Set color to match the theme
				'&:hover': {
				  color: colors.secondary,  // Change color on hover
				},
			  }}
			/>
		  )}
		</Typography>
      <Typography variant="body1" gutterBottom sx={{ ...typography.body1, color: colors.primaryText }}>
        <Phone fontSize="small" sx={{ mr: 1, color: colors.commonbtntextcolor }} /> <Typography sx={{ marginTop: '-28px', marginLeft: '34px' }}>{phone}</Typography>
      </Typography>
	  {email && (
      <Typography variant="body1" gutterBottom sx={{ ...typography.body1, color: colors.primaryText }}>
        <Email fontSize="small" sx={{ mr: 1, color: colors.commonbtntextcolor }} /> <Typography sx={{ marginTop: '-28px', marginLeft: '34px' }}>{email}</Typography>
      </Typography>
	  )}
      {/* Conditional Rendering of Horizontal or Vertical Buttons */}
     {/* Buttons for Call & WhatsApp */}
      {/* Buttons for Call & WhatsApp */}
     {!isSticky ? (
        // Non-sticky buttons (normal)
        <Box
          mt={4}
          display="flex"
          justifyContent="flex-start"
          sx={{
            flexDirection: 'row',
            gap: 2,
            marginLeft: contactinfo.is_whatsapp === 'true' ? '46px' : '120px'
          }}
        >
          {contactinfo.is_call === 'true' && (
            <Button
              variant="contained"
              startIcon={<Phone />}
              onClick={() => handleButtonClick('call')}
              sx={{
                backgroundColor: colors.callbuttonbg,
                borderRadius: '25px',
                padding: '8px 16px',
                '& .MuiButton-startIcon': {
                  marginRight: '8px',
                  fontSize: '16px', // Default icon size for non-sticky state
                },
                '&:hover': {
                  backgroundColor: colors.secondary,
                },
              }}
            >
              Call
            </Button>
          )}

          {contactinfo.is_whatsapp === 'true' && (
            <Button
              variant="contained"
              startIcon={<WhatsApp />}
              onClick={() => handleButtonClick('whatsapp')}
              sx={{
                backgroundColor: colors.whatsappbuttonbg,
                borderRadius: '25px',
                padding: '8px 16px',
                marginLeft: '8px',
                '& .MuiButton-startIcon': {
                  marginRight: '8px',
                  fontSize: '16px',
                },
                '&:hover': {
                  backgroundColor: colors.secondary,
                },
              }}
            >
              WhatsApp
            </Button>
          )}
        </Box>
      ) : (
        // Sticky buttons (circular with icon vibration)
		// Sticky buttons (circular with icon vibration and glowing effect)
// Sticky buttons (circular with a larger icon and smaller circle)
<Box
  mt={4}
  display="flex"
  flexDirection="column"
  sx={{
    gap: 3, // Spacing between buttons
    position: isSticky ? 'fixed' : 'relative',
    left: isSticky ? '10px' : 'unset',
    top: isSticky ? '50%' : 'unset',
    transform: isSticky ? 'translateY(-50%)' : 'none',
    zIndex: 1000,
    transition: 'all 0.5s ease',
  }}
>
  {contactinfo.is_call === 'true' && (
    <Button
      variant="contained"
      startIcon={<Phone />}
      onClick={() => handleButtonClick('call')}
      sx={{
        backgroundColor: '#007BFF', // Blue background for call button
        borderRadius: '50%', // Circular button
        padding: '10px', // Reduced padding to decrease circle size
        width: '55px',
        height: '55px',
        minWidth: 0, // Ensure button remains circular
        boxShadow: '0 0 10px #007BFF, 0 0 15px #007BFF, 0 0 20px #007BFF', // Subtle glowing effect
        '& .MuiButton-startIcon': {
          marginRight: '0',
          fontSize: '36px', // Larger icon for better visibility
          animation: isSticky ? 'vibrate 1s infinite' : 'none',
        },
        '@keyframes vibrate': {
          '0%': { transform: 'translate(0)' },
          '20%': { transform: 'translate(-2px, 2px)' },
          '40%': { transform: 'translate(2px, -2px)' },
          '60%': { transform: 'translate(-2px, -2px)' },
          '80%': { transform: 'translate(2px, 2px)' },
          '100%': { transform: 'translate(0)' },
        },
        '&:hover': {
          backgroundColor: '#0056b3', // Darker blue on hover
          boxShadow: '0 0 20px #0056b3, 0 0 25px #0056b3, 0 0 30px #0056b3', // Enhanced glow on hover
        },
      }}
    />
  )}

  {contactinfo.is_whatsapp === 'true' && (
    <Button
      variant="contained"
      startIcon={<WhatsApp />}
      onClick={() => handleButtonClick('whatsapp')}
      sx={{
        backgroundColor: '#25D366', // WhatsApp green
        borderRadius: '50%',
        padding: '10px',
        width: '55px',
        height: '55px',
        minWidth: 0,
        boxShadow: '0 0 10px #25D366, 0 0 15px #25D366, 0 0 20px #25D366', // Subtle glowing effect
        '& .MuiButton-startIcon': {
          marginRight: '0',
          fontSize: '36px', // Larger icon for better visibility
        },
        '&:hover': {
          backgroundColor: '#128C7E', // Darker green on hover
          boxShadow: '0 0 20px #128C7E, 0 0 25px #128C7E, 0 0 30px #128C7E', // Enhanced glow on hover
        },
      }}
    />
  )}
</Box>


      )}

      <LoginRequiredModal
        open={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
        onLogin={handleLogin}
      />
    </ContentWrapper>
  );
};

export default BasicInfo;