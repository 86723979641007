// SearchEngineSection.js

import React from 'react';
import { Box, TextField, InputAdornment, IconButton, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import {
  colors
} from '../../theme-styles';
import { IMAGE_BASE_URL } from '../../config/apiConfigext';

const getEngineImage = (engineImage) => {
  return engineImage.replace("../", IMAGE_BASE_URL);
};

const SearchEngineSection = ({ 
  onSearch, 
  onClick, 
  isSticky, 
  onKeyDown, 
  searchEngines, 
  onEngineSelect, 
  showEngines, // Prop that indicates whether the search button should show
  searchValue,
  onClear
}) => {
  const handleSearch = (event) => {
    const newValue = event.target.value;
    onSearch(newValue);
  };

  const handleClear = () => {
    onClear();
  };

  const handleEngineClick = (engine) => {
    onEngineSelect(engine);
  };
  
  const handleInputFocus = () => {
  onClick(); // This will call handleSearchClick in the parent component
};

  const handleSearchButtonClick = () => {
    let query = searchValue.trim();
    if (query) {
      let searchUrls = searchEngines
        .filter(engine => engine.selected)
        .map(engine => `${engine.searchengineurl}${query}`)
        .join('/~');
      if (searchUrls) {
        window.open(searchUrls, '_blank');
      }
    }
  };

  return (
    <Box
      onClick={onClick} className='categoty-search-box'
      sx={{
        display: 'flex',
        marginLeft: isSticky ? '-16px' : '0px',
        flexDirection: 'column',
        alignItems: 'center',
        padding: isSticky ? '12px 16px 8px' : 2,
        mb: isSticky ? 0 : 4,
        position: isSticky ? 'fixed' : 'relative',
        top: isSticky ? 0 : 'auto',
        zIndex: isSticky ? 1100 : 'auto',
        width: '100%', 
        backgroundColor: isSticky ? 'rgba(255, 255, 255, 0.98)' : 'white',
        boxShadow: isSticky ? '0 2px 4px rgba(0, 0, 0, 0.1)' : 'none',
        transition: 'all 0.3s ease-in-out',
      }}
    >
      <TextField
        fullWidth
        placeholder="Search..."
        variant="outlined"
        value={searchValue}
        onChange={handleSearch}
        onKeyDown={onKeyDown}
		 onFocus={handleInputFocus} 
        sx={{
          backgroundColor: '#e9d9d9',
          borderRadius: '50px',
          maxWidth: isSticky ? '95%' : 500,
          '& .MuiOutlinedInput-root': {
            borderRadius: '50px',
            padding: '5px 15px',
            transition: 'all 0.3s ease-in-out',
            height: '40px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#ccc',
            borderWidth: '1px',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#888',
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3f51b5',
            boxShadow: '0 0 0 2px rgba(63, 81, 181, 0.2)',
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: searchValue && (
            <InputAdornment position="end">
              <IconButton onClick={handleClear} edge="end" size="small">
                <CloseIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      
      {showEngines && (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '16px', flexWrap: 'wrap' }}>
          {searchEngines.map((engine) => (
            <Button
              key={engine.searchenginename}
              onClick={() => handleEngineClick(engine)}
              sx={{
                margin: '4px',
                borderRadius: '50%',
                backgroundColor: engine.selected ? colors.accent2 : '#ccc',
                color: 'white',
                minWidth: '50px',
                minHeight: '50px',
                width: '50px',
                height: '50px',
                padding: '0',
                '&:hover': {
                  backgroundColor: engine.selected ? colors.accent2 : '#ccc',
                },
              }}
            >
              <img 
                src={getEngineImage(engine.searchengineimage)} 
                alt={engine.searchenginename} 
                style={{ width: '60%', height: '60%' }} 
              />
            </Button>
          ))}
        </Box>
      )}

      {/* Conditionally show the Search button */}
      {showEngines && (
  
		<Button
		  variant="contained"
		  onClick={handleSearchButtonClick}
		  sx={{
			  mt:3,
			backgroundColor: colors.accent2,       // Set the button background color
			color: '#FFFFFF',                      // Ensure the text color contrasts well with accent2
			borderRadius: '20px',
			'&:hover': {
			  backgroundColor: colors.accent2,     // Maintain accent2 color on hover
			  opacity: 0.9                         // Slightly decrease opacity for hover effect
			},
		  }}
		>
		  Search
		</Button>
      )}
    </Box>
  );
};

export default SearchEngineSection;
